import {
  Card,
  Row,
  Col,
  Empty,
  Table,
  Badge,
  TextField,
  ImageField,
  Typography,
  Icons,
  Button,
  Switch,
  Modal,
  useModalForm,
  Form,
  Input,
  Tooltip,
  Popconfirm,
} from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { TOKEN_KEY } from "authProvider";
import axios from "axios";
import {
  AddProductToCart,
  ProductExpandDetails,
  WishlistButton,
} from "components/catalog";
import { capitalizeFirstLetter, inStock } from "components/helper";
import { useGetTokenData, useWishlists, useTour } from "hooks";
import { FC, useRef } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import {
  removeGroup,
  changeWishlistGroup as changeWishlistGroupStore,
} from "store/slices/wishlistSlice";
import { IProduct, IWishlistGroup } from "types";

const { Column } = Table;
const { DeleteOutlined } = Icons;
const { Paragraph } = Typography;

export const WishlistsPage: FC = () => {
  const refAboutThisPage = useRef(null);
  const ref2 = useRef(null);
  const { tourElement, setOpen } = useTour({
    steps: [
      {
        target: () => refAboutThisPage.current!,
        placement: "bottom",
        cover: <img alt="tour.png" src={`/images/tour/wishlists_main.jpeg`} />,
        title: <div>Списки обраних товарів</div>,
        description: (
          <div>
            <p>
              На порталі є можливість створювати декілька списків обраних
              товарів.
              <br />
              Наприклад:
              <ul>
                <li>Товари для офісу</li>
                <li>Побутова хімія</li>
                <li>Для складу</li>
              </ul>
            </p>
            <p>
              <ExclamationCircleOutlined style={{ color: "#006400" }} /> Ви
              можете додавати товар в корзину прямо з цих списків
            </p>
            <p>
              <ExclamationCircleOutlined style={{ color: "#006400" }} /> Для
              того, щоб завершити роботу помічника достроково натисніть клавішу
              ESC
            </p>
          </div>
        ),
      },
      {
        target: () => ref2.current!,
        title: <div>Створіть ще один список товарів</div>,
      },
    ],
    initOpen: false,
  });
  const token = localStorage.getItem(TOKEN_KEY);
  const userData = useGetTokenData();
  const dispatch = useDispatch();
  const { show } = useNavigation();
  const { wishlistGroups, getProductsByGroup, refetchGroups } = useWishlists();
  const {
    modalProps,
    formProps,
    show: openModal,
  } = useModalForm<IWishlistGroup>({
    resource: "partner/utils/wishlist-group",
    action: "create",
    redirect: false,
    successNotification(data, values, resource) {
      return {
        message: `Успішто створено ${(values as IWishlistGroup).name}`,
        type: "success",
      };
    },
    onMutationSuccess(data, variables, context) {
      refetchGroups();
    },
  });

  const changeWishlistGroup = async (
    groupId: number,
    groupData: {
      name: string;
      isPublic: boolean;
    }
  ) => {
    try {
      const { data, status } = await axios.put<IWishlistGroup>(
        `${process.env.REACT_APP_API_URL}/partner/utils/wishlist-group/${groupId}`,
        groupData,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        dispatch(changeWishlistGroupStore(data));
      }
    } catch (e) {}
  };
  const deleteWishlistGroup = async (groupId: number) => {
    try {
      const { data, status } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/partner/utils/wishlist-group/${groupId}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        dispatch(removeGroup({ groupId: groupId }));
      }
    } catch (e) {}
  };

  return (
    <>
      <Helmet>
        <title>Обрані товари | Офіс Центр</title>
      </Helmet>
      <Card
        title={
          <div>
            Обрані товари{" "}
            <QuestionCircleOutlined
              style={{ fontSize: "20px", color: "#08c" }}
              onClick={() => setOpen(true)}
              alt="Помічник"
              title="Запустити помічника на цій сторінці"
            />
          </div>
        }
        extra={[
          <Button ref={ref2} onClick={() => openModal()}>
            Створити список
          </Button>,
        ]}
      >
        <Row gutter={[12, 12]}>
          {wishlistGroups.length > 0 &&
            wishlistGroups.map((e) => {
              const products = getProductsByGroup(e.id);

              return (
                <Col key={e.id} span={24}>
                  <Card
                    title={
                      <Row align={"middle"}>
                        {e.ownerId === userData?.id ? (
                          <Col span={24}>
                            <Paragraph
                              editable={{
                                onChange(value) {
                                  changeWishlistGroup(e.id, {
                                    isPublic: e.isPublic,
                                    name: value,
                                  });
                                },
                              }}
                            >
                              {e.name}
                            </Paragraph>
                          </Col>
                        ) : (
                          e.name
                        )}
                      </Row>
                    }
                    extra={[
                      <Row align={"middle"}>
                        <span>Для всіх контрагентів</span>
                        <Switch
                          style={{
                            marginLeft: "4px",
                          }}
                          defaultChecked={e.isPublic}
                          disabled={e.isDefault || e.ownerId !== userData?.id}
                          onClick={() =>
                            changeWishlistGroup(e.id, {
                              isPublic: !e.isPublic,
                              name: e.name,
                            })
                          }
                        />{" "}
                        {!e.isDefault && e.ownerId === userData?.id && (
                          <Tooltip title={"Видалити список"}>
                            <Popconfirm
                              title={`Видалення списку "${e.name}"`}
                              description={`Ви впевнені що хочете видалити список "${e.name}" ?`}
                              onConfirm={() => deleteWishlistGroup(e.id)}
                              cancelText="Відміна"
                              okText="Видалити"
                            >
                              <Button
                                style={{
                                  marginLeft: "8px",
                                }}
                                danger
                                icon={<DeleteOutlined />}
                              />
                            </Popconfirm>
                          </Tooltip>
                        )}
                      </Row>,
                    ]}
                  >
                    {products.length === 0 ? (
                      <Empty />
                    ) : (
                      <Table
                        dataSource={products}
                        size={"small"}
                        rowKey="id"
                        className="product-table"
                        rowClassName="product-row"
                        onRow={(data) => {
                          return {
                            style: {
                              filter:
                                data.prices.length === 0
                                  ? "grayscale(100%)"
                                  : "none",
                              backgroundColor:
                                data.prices.length === 0 ? "#fafafa" : "white",
                            },
                          };
                        }}
                        expandable={{
                          //expandRowByClick: true,
                          expandedRowClassName: () => "product-tr",
                          expandedRowRender: (record: IProduct) => {
                            return <ProductExpandDetails record={record} />;
                          },
                        }}
                      >
                        <Column<IProduct>
                          dataIndex="vendor"
                          title="Код"
                          render={(value, record) => {
                            return (
                              <Row align={"middle"}>
                                <span
                                  style={{
                                    marginRight: "8px",
                                  }}
                                >
                                  {record.vendor}
                                </span>
                                <WishlistButton
                                  product={record}
                                  groupId={
                                    userData?.id === e.ownerId
                                      ? e.id
                                      : undefined
                                  }
                                />
                              </Row>
                            );
                          }}
                        />
                        <Column<IProduct>
                          dataIndex="media"
                          width={160}
                          align={"center"}
                          title="Зображення"
                          render={(value, record) => {
                            let media = record.media.find(
                              (e) => e.collectionName === "main"
                            );
                            if (!media && record.media.length > 0) {
                              media = record.media[0];
                            }
                            if (!media) {
                              return <></>;
                            }
                            return (
                              <ImageField
                                value={`${process.env.REACT_APP_IMAGE_PATH}${media.large}`}
                                height={64}
                              />
                            );
                          }}
                        />
                        <Column<IProduct>
                          dataIndex="name"
                          title="Назва"
                          render={(value, record) => (
                            <Typography.Text
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => show("products", record.id)}
                            >
                              {capitalizeFirstLetter(value)}
                            </Typography.Text>
                          )}
                        />{" "}
                        {/* <Column
                          dataIndex="balance"
                          width={60}
                          align={"center"}
                          title="Доступна кількість"
                        /> */}
                        <Column<IProduct>
                          dataIndex="prices"
                          width={140}
                          align={"center"}
                          title="Ціна"
                          render={(value, record) => {
                            if (record.discountPrice !== 0) {
                              return (
                                <Row>
                                  <Col span={24}>
                                    <del className="old-price">
                                      {record.prices[0].price} <span>грн.</span>
                                    </del>
                                  </Col>
                                  <Col span={24}>
                                    <div className="price-discount">
                                      {record.discountPrice} <span>грн.</span>
                                    </div>
                                  </Col>
                                </Row>
                              );
                            }
                            return (
                              <TextField
                                className="price"
                                value={`${record.prices[0].price} грн.`}
                              />
                            );
                          }}
                        />
                        <Column<IProduct>
                          dataIndex="action"
                          key={"balance"}
                          align={"center"}
                          title="Дії"
                          width={220}
                          render={(value, record) => {
                            if (inStock(record)) {
                              return (
                                <TextField
                                  style={{
                                    color: "#fd1100",
                                  }}
                                  value={"Нема в наявності"}
                                />
                              );
                            }
                            // if (record.balance <= 2) {
                            //   return (
                            //     <Badge.Ribbon
                            //       text={"Закінчується"}
                            //       color="yellow"
                            //       className={"discount-ribbon"}
                            //     >
                            //       <AddProductToCart
                            //         key={`${record.id}-${record.balance}`}
                            //         productId={record.id}
                            //         max={record.balance}
                            //         productName={record.name}
                            //       />
                            //     </Badge.Ribbon>
                            //   );
                            // }
                            if (record.discountPrice) {
                              return (
                                <Badge.Ribbon
                                  text={"Знижка"}
                                  color="red"
                                  className={"discount-ribbon"}
                                >
                                  <AddProductToCart
                                    key={`${record.id}`}
                                    productId={record.id}
                                    productName={record.name}
                                  />
                                </Badge.Ribbon>
                              );
                            }
                            return (
                              <AddProductToCart
                                key={`${record.id}`}
                                productId={record.id}
                                productName={record.name}
                              />
                            );
                          }}
                        />
                      </Table>
                    )}
                  </Card>
                </Col>
              );
            })}
        </Row>
      </Card>
      <Modal {...modalProps} title={"Створити список товарів"}>
        <Form {...formProps}>
          <Form.Item
            id={"name"}
            name={"name"}
            label={"Назва"}
            rules={[
              {
                required: true,
                message: "Назва обов`язкова",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            id={"isPublic"}
            name={"isPublic"}
            label={" Для всіх контрагентів"}
            valuePropName="checked"
          >
            <Switch defaultChecked={false} />
          </Form.Item>
        </Form>
      </Modal>
      {tourElement}
    </>
  );
};
