import { Form, Row, Select, useSelect } from "@pankod/refine-antd";

import { FC, useState } from "react";
import { INovaPoshtaDelivery, INovaPoshtaWarehouse } from "types";

export interface INovaPoshtaDeliverysProps {
  onSelect?: (warehouse?: INovaPoshtaWarehouse) => void;
  onSelectDelivery?: (delivery?: INovaPoshtaDelivery) => void;
}

export const NovaPoshtaDeliverys: FC<INovaPoshtaDeliverysProps> = ({
  onSelect,
  onSelectDelivery,
}) => {
  const [selectedDelivery, setSelectedDelivery] =
    useState<INovaPoshtaDelivery>();
  const [selectedWarehouse, setSelectedWarehouse] =
    useState<INovaPoshtaWarehouse>();
  const [searchValue, setSearchValue] = useState<string>("");
  const { selectProps, queryResult } = useSelect<INovaPoshtaDelivery>({
    resource: "delivery/novaposhta",
    optionLabel: "address",
    filters: [
      {
        field: "q",
        operator: "eq",
        value: searchValue,
      },
    ],
    queryOptions: {
      enabled: searchValue !== "",
    },
    metaData: {
      all: true,
      withoutCount: true,
    },
  });

  return (
    <>
      <Form.Item label="Місто" style={{ width: "100%" }}>
        <Select
          {...selectProps}
          value={
            selectedDelivery && {
              value: `${selectedDelivery.id}`,
              label: selectedDelivery.address,
            }
          }
          onSearch={(val) => {
            setSearchValue(val);
          }}
          onSelect={(val) => {
            const delivery = queryResult.data?.data.find(
              (e) => e.id == (val as any)
            );
            setSelectedDelivery(delivery);
            setSelectedWarehouse(undefined);

            onSelect && onSelect(undefined);
            onSelectDelivery && onSelectDelivery(delivery);
          }}
          placeholder="Введіть назву міста, району, області, тощо..."
        />
      </Form.Item>
      {selectedDelivery && selectedDelivery.warehouses.length > 0 && (
        <Form.Item label="Відділення" style={{ width: "100%" }}>
          <Select
            value={
              selectedWarehouse && {
                value: selectedWarehouse.id,
                label: selectedWarehouse.name,
              }
            }
            onSelect={(val) => {
              const warehouse = selectedDelivery.warehouses.find(
                (e) => e.id == (val as any)
              );
              setSelectedWarehouse(warehouse);
              onSelect && onSelect(warehouse);
            }}
            placeholder={"Введіть номер відділення, вулицю, тощо..."}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={selectedDelivery.warehouses.map((e) => {
              return {
                label: e.name,
                value: e.id,
              };
            })}
          />
        </Form.Item>
      )}
    </>
  );
};
