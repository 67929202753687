import {
  AntdLayout,
  Button,
  Card,
  Form,
  Typography,
  Input,
  Row,
  Col,
} from "@pankod/refine-antd";
import {
  useLogin,
  useNavigation,
  useNotification,
  useTranslate,
} from "@pankod/refine-core";
import "./styles.css";
import { useClearStore } from "hooks";
import axios, { isAxiosError } from "axios";
import { IForgotPasswordModalForm } from "types";

export interface IResetPasswordProps {
  password: string;
  confirmPassword: string;
}
const { Title, Text } = Typography;
export const ResetPasswordPage = () => {
  const t = useTranslate();
  const [form] = Form.useForm<IResetPasswordProps>();
  const { mutate: mutateClearStore } = useClearStore();
  const { mutate: login } = useLogin<IResetPasswordProps>();
  const { open } = useNotification();
  const { push } = useNavigation();

  const sendNewPassword = async (props: IResetPasswordProps) => {
    try {
      if (props.password !== props.confirmPassword) {
        open?.({
          type: "error",
          message: "Паролі не співпадають",
          description: "Виникла помилка",
        });
        return;
      }
      const link = window.location.pathname.split("/");
      const { status, data } = await axios.post(
        `${process.env.REACT_APP_API_URL}partner/reset_password/${
          link[link.length - 1]
        }`,
        {
          password: props.password,
        }
      );
      if (status === 200) {
        open?.({
          type: "success",
          message: "Пароль було успішно змінено",
          description: "Успіх",
        });
        push("/login");
      } else {
        open?.({
          type: "error",
          message: "Спробуйте ще раз",
          description: "Виникла помилка",
        });
      }
    } catch (e) {
      if (isAxiosError(e)) {
        open?.({
          type: "error",
          message: e.response!.data.message,
          description: "Виникла помилка",
        });
      }
    }
  };
  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <Card
              title={
                <Title
                  style={{
                    marginBottom: 0,
                  }}
                  level={3}
                >
                  {t("pages.updatePassword.title")}
                </Title>
              }
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={(values) => {
                  sendNewPassword(values);
                }}
              >
                <Form.Item
                  name="password"
                  label={t("pages.updatePassword.fields.password")}
                  rules={[
                    {
                      required: true,
                      message: t("pages.login.errors.validPassword"),
                    },
                    {
                      min: 8,
                      max: 32,

                      message: t("pages.login.errors.minMaxPassword"),
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  label={t("pages.updatePassword.fields.confirmPassword")}
                  rules={[
                    {
                      required: true,
                      message: t("pages.login.errors.validPassword"),
                    },
                    {
                      min: 8,
                      max: 32,

                      message: t("pages.login.errors.minMaxPassword"),
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Button type="primary" size="large" htmlType="submit" block>
                  {t("pages.updatePassword.buttons.submit")}
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
