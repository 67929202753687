import { Line } from "@ant-design/plots";
import {
  QuestionCircleOutlined,
  WarningOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { AntdLayout, Button, Card, Col, Row, Show } from "@pankod/refine-antd";
import { TOKEN_KEY } from "authProvider";
import axios from "axios";
import { CartsWidget } from "components/catalog/CartsWidget/CartsWidget";
import { OrdersList } from "components/personal";
import { useCanAccessByRole, useTour } from "hooks";
import { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getCounterpartyId, getOutletId } from "store/slices/appSlice";
import { closeOutletModal, openOutletModal } from "store/slices/modalsSlice";
import { IDashboardStats } from "types";

export const DashboardPage = () => {
  const refAboutThisPage = useRef(null);
  const refCartList = useRef(null);
  const refOrderList = useRef(null);
  const { tourElement, setOpen } = useTour({
    steps: [
      {
        target: () => refAboutThisPage.current!,
        placement: "bottom",
        cover: <img alt="tour.png" src={`/images/tour/dashboard_main.jpg`} />,
        title: <div>Головна сторінка B2B-порталу</div>,
        description: (
          <div>
            <p>Ця сторінка містить панель інструментів Вашого акаунту</p>
            <p>
              <QuestionCircleOutlined style={{ color: "#08c" }} /> Тут Ви можете
              бачити:
              <ul>
                <li>Активні кошики Ваших точок доставки</li>
                <li>Історію замовлень Ваших точок доставки</li>
                <li>
                  Різні віджети з інформацією про замовлення, товар і інше
                </li>
              </ul>
            </p>
            <p>
              <ExclamationCircleOutlined style={{ color: "#006400" }} /> Для
              того, щоб завершити роботу помічника достроково натисніть клавішу
              ESC
            </p>
          </div>
        ),
      },
      {
        target: () => refCartList.current!,
        placement: "bottom",
        cover: (
          <img alt="tour.png" src={`/images/tour/dashboard_cart_list.png`} />
        ),
        title: <div>Кошики</div>,
        description: (
          <div>
            <p>
              В блоку знаходятся список активних кошиків Ваших точок доставки, в
              яких є товар
            </p>
            <p>
              <QuestionCircleOutlined style={{ color: "#08c" }} /> Ви можете
              редагувати товар в кошиках:
              <ul>
                <li>Змінювати кількість товару</li>
                <li>Видалити товар з кошика</li>
                <li>Додати товар до кошика</li>
              </ul>
            </p>
          </div>
        ),
      },
      {
        target: () => refOrderList.current!,
        placement: "top",
        cover: (
          <img alt="tour.png" src={`/images/tour/dashboard_order_list.png`} />
        ),
        title: <div>Замовлення</div>,
        description: (
          <div>
            <p>
              В блоку знаходяться створені замовлення Ваших точок доставки.
              Натиснувши на замовлення, Ви можете побачити повну інформацію про
              створене замовлення
            </p>
            <p>
              <WarningOutlined style={{ color: "#8B0000" }} /> Створене
              замовлення не редагується
            </p>
          </div>
        ),
      },
    ],
    initOpen: false,
  });
  const token = localStorage.getItem(TOKEN_KEY);
  const counterpartyId = useSelector(getCounterpartyId);
  const outletId = useSelector(getOutletId);
  const dispatch = useDispatch();
  const canAccess = useCanAccessByRole(["buyer"]);
  const [dashboardData, setDashboardData] = useState<IDashboardStats>();

  const fetchData = async () => {
    if (!token || !counterpartyId || !outletId) return;
    try {
      const { data, status } = await axios.get(
        `${process.env.REACT_APP_API_URL}/partner/utils/dashboard?c=${counterpartyId}&o=${outletId}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        setDashboardData(data);
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (counterpartyId && outletId) {
      fetchData();
    }
  }, [counterpartyId, outletId]);

  return (
    <>
      <AntdLayout>
        <Helmet>
          <title>Головна | Офіс Центр</title>
        </Helmet>
        <Show
          title={
            <div>
              Головна{" "}
              <QuestionCircleOutlined
                style={{ fontSize: "20px", color: "#08c" }}
                onClick={() => setOpen(true)}
                alt="Помічник"
                title="Запустити помічника на цій сторінці"
              />
            </div>
          }
          breadcrumb
          headerButtons={[]}
        >
          <Row gutter={[10, 10]}>
            <Col lg={12} sm={24} xl={8}></Col>
            <Col span={24}>
              <CartsWidget />
            </Col>
            <Col span={24}>
              <Card title="Замовлення" className="custom-body">
                <OrdersList all />
              </Card>
            </Col>
          </Row>
        </Show>
      </AntdLayout>
      {tourElement}
    </>
  );
};
