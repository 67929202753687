import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { remove } from "lodash";
import { AppState } from "store/store";
import { IProduct, IWishlistGroup, IWishlistGroupItems } from "types";

export interface IWishlistState {
  groups: IWishlistGroup[];
  groupsProducts: IWishlistGroupItems[];
}
const initialState: IWishlistState = {
  groups: [],
  groupsProducts: [],
};

const wishlistSlice = createSlice({
  initialState,
  name: "wishlist",
  reducers: {
    setWishlistGroup: (state, { payload }: PayloadAction<IWishlistGroup[]>) => {
      state.groups = payload;
    },
    addWishlistGroup: (state, { payload }: PayloadAction<IWishlistGroup>) => {
      state.groups.push(payload);
    },
    changeWishlistGroup: (
      state,
      { payload }: PayloadAction<IWishlistGroup>
    ) => {
      let g = state.groups.find((e) => payload.id === e.id);
      if (!g) return;
      g.name = payload.name;
      g.isPublic = payload.isPublic;
    },
    setWishlistGroupItems: (
      state,
      { payload }: PayloadAction<IWishlistGroupItems[]>
    ) => {
      state.groupsProducts = payload;
    },
    addWishlistGroupItems: (
      state,
      { payload }: PayloadAction<IWishlistGroupItems>
    ) => {
      remove(state.groupsProducts, {
        groupId: payload.groupId,
      });
      state.groupsProducts.push(payload);
    },
    addProductToWishlistGroupItems: (
      state,
      {
        payload,
      }: PayloadAction<{
        product: IProduct;
        groupId: number;
      }>
    ) => {
      state.groupsProducts
        .find((e) => e.groupId === payload.groupId)
        ?.products.push(payload.product);
    },
    removeProductFromWishlistGroupItems: (
      state,
      {
        payload,
      }: PayloadAction<{
        product: IProduct;
        groupId: number;
      }>
    ) => {
      const a = state.groupsProducts.find((e) => e.groupId === payload.groupId);
      if (!a) return;
      remove(a.products, {
        id: payload.product.id,
      });
    },
    removeGroup: (
      state,
      {
        payload,
      }: PayloadAction<{
        groupId: number;
      }>
    ) => {
      remove(state.groups, {
        id: payload.groupId,
      });
      remove(state.groupsProducts, {
        groupId: payload.groupId,
      });
    },
  },
});

export default wishlistSlice.reducer;

export const {
  setWishlistGroup,
  addWishlistGroup,
  setWishlistGroupItems,
  addWishlistGroupItems,
  changeWishlistGroup,
  addProductToWishlistGroupItems,
  removeProductFromWishlistGroupItems,
  removeGroup,
} = wishlistSlice.actions;

export const getWishlistGroups = (state: AppState) => state.wishlist.groups;
export const getWishlistGroupsItems = (state: AppState) =>
  state.wishlist.groupsProducts;
export const getCountProductsInGroups = (state: AppState) =>
  state.wishlist.groups.reduce((prev, e) => prev + e.products, 0);
