import {
  Button,
  Card,
  Col,
  ImageField,
  Radio,
  Row,
  Icons,
  Space,
  Table,
  TextField,
  notification,
  Typography,
  Input,
  Badge,
  Empty,
} from "@pankod/refine-antd";
import { useList, useNavigation, useNotification } from "@pankod/refine-core";
import { TOKEN_KEY } from "authProvider";
import axios, { AxiosError, isAxiosError } from "axios";
import {
  NovaPoshtaDeliverys,
  PickupDeliverys,
  ProductsTable,
} from "components/catalog";
import { capitalizeFirstLetter, getCartInfo } from "components/helper";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getCounterpartyData, getOutletInfo } from "store/slices/appSlice";
import {
  getCartItems,
  getProducts,
  setCartItems,
} from "store/slices/cartSlice";
import {
  IDelivery,
  INovaPoshtaDelivery,
  INovaPoshtaWarehouse,
  IOrderCreateResponse,
  IPickupDelivery,
  IProduct,
  IResponseError,
} from "types";
import "./CheckoutPage.css";
const { PrinterOutlined } = Icons;
const { Text } = Typography;
const { Column } = Table;

export const CheckoutPage: FC = () => {
  const dispacth = useDispatch();
  const { show } = useNavigation();
  const counterpartyData = useSelector(getCounterpartyData);
  const outletData = useSelector(getOutletInfo);
  const cartItems = useSelector(getCartItems);
  const products = useSelector(getProducts);
  const { open } = useNotification();
  const [delivery, setDelivery] = useState<string>("target");
  const [comment, setComment] = useState<string>("");
  const [deliveries, setDeliveries] = useState<IDelivery[]>([]);
  const [selectedDelivery, setSelectedDelivery] = useState<
    INovaPoshtaWarehouse | IPickupDelivery
  >();
  const [selectedNovaPoshtaAddres, setSelectedNovaPoshtaAddres] =
    useState<INovaPoshtaDelivery>();

  const { data, isSuccess } = useList<IDelivery>({
    resource: "delivery",
    metaData: {
      all: true,
      withoutCount: true,
    },
  });

  useEffect(() => {
    if (data?.data && isSuccess) {
      setDeliveries(data.data);
    }
  }, [data, isSuccess]);

  const getAddresComponent = () => {
    return (
      <Row>
        <Col>
          <Space>
            <Text>Адреса: </Text>
            <Text strong>{outletData?.address}</Text>
          </Space>
        </Col>
      </Row>
    );
  };

  const getDelivery = () => {
    switch (delivery) {
      case "target":
        return getAddresComponent();
      case "novaposhta":
        return (
          <NovaPoshtaDeliverys
            onSelectDelivery={(delivery) =>
              setSelectedNovaPoshtaAddres(delivery)
            }
            onSelect={(warehouse) => setSelectedDelivery(warehouse)}
          />
        );
      case "pickup":
        return (
          <PickupDeliverys
            onSelect={(delivery) => setSelectedDelivery(delivery)}
          />
        );
    }
  };

  const getDeliveryStr = () => {
    if (delivery === "target") return outletData?.address;

    if (delivery === "novaposhta" && selectedDelivery)
      return selectedDelivery.name;
    if (delivery === "pickup" && selectedDelivery)
      return `${(selectedDelivery as IPickupDelivery).name}, ${
        (selectedDelivery as IPickupDelivery).pickupSettlements.name
      }`;

    return "Не вказано";
  };
  const { sum, sumDiscount, sumWithoutDiscount, totalQuantity, totalSku } =
    getCartInfo(cartItems, products);

  const confirmOrder = async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (delivery !== "target" && !selectedDelivery) {
      if (delivery === "novaposhta") {
        open &&
          open({
            message: "Не вибрано відділеня",
            type: "error",
          });
      } else if (delivery === "pickup") {
        open &&
          open({
            message: "Не вибраний магазин для самовивозу",
            type: "error",
          });
      }
      return;
    }

    const getDeliveryObj = () => {
      if (delivery === "novaposhta") {
        return {
          ...(selectedNovaPoshtaAddres as INovaPoshtaDelivery),
          warehouseId: selectedDelivery?.id,
        };
      } else if (delivery === "target") {
        return outletData?.address;
      } else if (delivery === "pickup") {
        return selectedDelivery;
      }
    };

    try {
      const deliveryId = deliveries.find((e) => e.guid === delivery)?.id;
      const { data, status } = await axios.post<IOrderCreateResponse>(
        `${process.env.REACT_APP_API_URL}/b2b/order?c=${counterpartyData?.id}&o=${outletData?.id}`,
        {
          paymentTypeId: 3, //TODO: selector for type
          deliveryTypeId: deliveryId,
          delivery: getDeliveryObj(),
          comment: comment,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200 || status === 201) {
        dispacth(setCartItems([]));
        notification.open({
          message: "Замовлення створено",
          description: (
            <span>
              Номер замовлення <b>{data.id}</b>
            </span>
          ),
          type: "success",
        });
        show("orders", data.id);
      }
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IResponseError>;
        open &&
          open({
            message: error.response?.data.message ?? "",
            type: "error",
            description:
              error.response?.data.errors.length !== 0
                ? error.response?.data.errors.map((e) => e.msg).join(";\n")
                : "Помилка оформлення замовлення",
          });
      } else {
        open &&
          open({
            message: "Помилка оформлення замовлення",
            type: "error",
          });
      }
    }
  };

  if (!counterpartyData || !outletData) {
    return <></>;
  }

  if (cartItems.length === 0) {
    return <Empty />; //TODO: change to empty order
  }

  return (
    <>
      <Helmet>
        <title>Оформлення замовлення | Офіс Центр</title>
      </Helmet>
      <Row wrap gutter={[8, 8]}>
        <Col lg={18} sm={24}>
          <Card
            title="Оформлення замовлення"
            extra={[
              <Button type="primary" icon={<PrinterOutlined />}>
                Друк
              </Button>,
            ]}
          >
            <Card title="Клієнт">
              <Row>
                <Col span={8}>
                  <Space>
                    <Text>Контрагент: </Text>
                    <Text strong>{counterpartyData.name}</Text>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space>
                    <Text>ІІН/ЄДРПОУ: </Text>
                    <Text strong>{counterpartyData.innEdrpou}</Text>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space>
                    <Text>Юр/Фіз особа: </Text>
                    <Text strong>
                      {counterpartyData.isLegalEntity
                        ? "Юридична особа"
                        : "Фізична особа"}
                    </Text>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space>
                    <Text>Точка доставки: </Text>
                    <Text strong>{outletData.name}</Text>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space>
                    <Text>Адреса: </Text>
                    <Text strong>{outletData.address}</Text>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space>
                    <Text>Контактна особа: </Text>
                    <Text strong>{outletData.contactPerson}</Text>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space>
                    <Text>Контактний телефон: </Text>
                    <Text strong>{outletData.phone}</Text>
                  </Space>
                </Col>
              </Row>
            </Card>
            <Card
              style={{
                marginTop: "12px",
              }}
              title="Доставка"
            >
              <Row>
                <Radio.Group
                  value={delivery}
                  buttonStyle="solid"
                  onChange={(e) => {
                    setDelivery(e.target.value);
                    setSelectedDelivery(undefined);
                  }}
                >
                  {deliveries.length > 0 &&
                    deliveries.map((e) => {
                      return (
                        <Radio.Button key={e.guid} value={e.guid}>
                          {e.name}
                        </Radio.Button>
                      );
                    })}
                </Radio.Group>
              </Row>
              <Row
                style={{
                  margin: "16px 8px",
                  width: "100%",
                }}
              >
                {getDelivery()}
              </Row>
            </Card>
            <Card
              style={{
                marginTop: "12px",
              }}
              title="Товари"
            >
              <ProductsTable
                cartItems={cartItems}
                products={products}
                sum={sum}
                totalSku={totalSku}
              />
            </Card>
          </Card>
        </Col>

        <Col lg={6} sm={24} className="right-side">
          <Card
            className="sticky"
            title="Загальна інформація"
            actions={[
              <Row align={"middle"}>
                <Col span={12}>
                  <Space>
                    <Text strong>До сплати: </Text>
                    <Text strong type="danger">
                      {sum} грн.
                    </Text>
                  </Space>
                </Col>
                <Col span={12}>
                  <Button danger onClick={confirmOrder}>
                    Підтвердити замовлення
                  </Button>
                </Col>
              </Row>,
            ]}
          >
            <Col>
              <Space>
                <Text>Контрагент: </Text>
                <Text strong>{counterpartyData.name}</Text>
              </Space>
            </Col>
            <Col>
              <Space>
                <Text>Точка доставки: </Text>
                <Text strong>{outletData.name}</Text>
              </Space>
            </Col>
            <Col>
              <Space>
                <Text>Тип доставки: </Text>
                <Text strong>
                  {deliveries.find((e) => e.guid === delivery)?.name ?? ""}
                </Text>
              </Space>
            </Col>
            <Col>
              <Space>
                <Text
                  style={{
                    minWidth: "100px",
                  }}
                >
                  Адреса:
                </Text>
                <Text strong>{getDeliveryStr()}</Text>
              </Space>
            </Col>
            <Col>
              <Space>
                <Text>Всього найменувань: </Text>
                <Text strong>{totalSku}</Text>
              </Space>
            </Col>
            <Col>
              <Space>
                <Text>Кількість товарів: </Text>
                <Text strong>{totalQuantity}</Text>
              </Space>
            </Col>
            <Col>
              <Space>
                <Text>Сума без знижки: </Text>
                <Text strong>{sumWithoutDiscount} грн.</Text>
              </Space>
            </Col>
            <Col>
              <Space>
                <Text>Сума знижки: </Text>
                <Text strong>{sumDiscount} грн.</Text>
              </Space>
            </Col>
            <Col
              style={{
                marginTop: "8px",
              }}
            >
              <Input.TextArea
                placeholder="Коментар"
                value={comment}
                onChange={async (e) => setComment(e.target.value)}
              />
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
};
