import {
  ICartInfo,
  ICartItem,
  ICategory,
  IFilterProps,
  IModedCartProduct,
  IPricesFilter,
  IProduct,
} from "types";
import _, { isNaN } from "lodash";
import { ICatalogState } from "store/slices/catalogSlice";

export const emailPattern = new RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);
export const phonePattern = new RegExp(
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i
);

export const loadState = (key: string) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

export const saveState = async (state: any, key: string) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (e) {
    // Ignore
  }
};

export function treeCatalog(data: ICategory[]) {
  const parents = _.filter(data, (item: ICategory) => item.parentId == null);

  const getChild = (parent: ICategory, childs: ICategory[]) => {
    if (childs.length === 0) return [];

    const values: ICategory[] = _.filter(
      childs,
      (child: ICategory) => child.parentId === parent.id
    );

    if (values.length !== 0) {
      for (const value of values) {
        value.categories = getChild(value, childs);
      }
    }

    return values.sort((a, b) => a.sort - b.sort);
  };
  for (const parent of parents) {
    parent.categories = getChild(parent, data);
  }
  return parents.sort((a, b) => a.sort - b.sort);
}
export const parseCatalog = (): ICatalogState => {
  // filters[0][field]=catalogId&filters[0][operator]=eq&filters[0][value]=1&filters[1][field]=filters&filters[1][operator]=eq&filters[1][value]=torgova-marka-1:96,108|vik-3:2191,2192
  const params = window.location.search.slice(1).split("&");

  const input = params.filter((e) => e.includes("filters")).join("&");
  let categoryFilter = null,
    propertyFilter: IFilterProps[] = [],
    pricesFilter: IPricesFilter = {
      minPrice: 0,
      maxPrice: 0,
    },
    sortBy: string = "rating";

  var filters = input.split("&");
  for (var i = 0; i < filters.length; i++) {
    const filter = filters.filter((el) => el.includes(`filters[${i}]`));
    if (!filter || filter.length !== 3) continue;

    const field = filter[0].split("=")[1];
    const value = filter[2].split("=")[1];

    switch (field) {
      case "catalogId":
        try {
          categoryFilter = parseInt(value);
          if (isNaN(categoryFilter)) {
            categoryFilter = null;
          }
        } catch (e) {}
        break;
      case "filters":
        const params = value.split("|");
        params.forEach((param) => {
          const a = param.split(":");
          try {
            propertyFilter.push({
              code: a[0],
              name: "",
              values: a[1].split(",").map((e) => {
                return {
                  id: parseInt(e),
                  value: "",
                  createdAt: "",
                  updatedAt: "",
                  productPropertyId: 0,
                };
              }),
            });
          } catch (e) {}
        });
        break;
      case "minPrice":
        pricesFilter.minPrice = parseFloat(value);
        break;
      case "maxPrice":
        pricesFilter.maxPrice = parseFloat(value);
        break;
      case "order":
        sortBy = value;
        break;
    }
  }
  return {
    categories: [],
    propertyFilter: propertyFilter,
    categoryFilter: categoryFilter,
    pricesFilter: pricesFilter,
    sortBy: sortBy,
  };
};

export const inStock = (product: IProduct) => {
  return (
    product.prices.length === 0 ||
    product.prices.reduce((a, b) => a + b.price, 0) === 0
  );
};

export const getCartInfo = (
  cartItems: ICartItem[],
  products: IProduct[],
  returnNumbers = false
): ICartInfo => {
  let sum = 0,
    sumWithoutDiscount = 0,
    sumDiscount = 0,
    totalQuantity = 0,
    totalSku = 0,
    modBalace = false,
    modedProducts: IModedCartProduct[] = [];

  cartItems.forEach((item) => {
    const product = products.find((e) => e.id === item.productId);
    if (!product) return;

    let price = 0,
      clearPrice = 0;

    if (product.discountPrice !== 0) {
      price = product.discountPrice;
    }
    const quantity = item.quantity;
    if (item.quantity !== quantity) {
      modBalace = true;
      modedProducts.push({
        prev: item.quantity,
        new: quantity,
        name: product.name,
        id: product.id,
      });
    }
    if (quantity > 0) {
      totalSku += 1;
    }
    totalQuantity += quantity;
    product.prices.forEach((s) => {
      if (product.discountPrice === 0) {
        if (price > s.price) {
          price = s.price;
        } else if (price === 0) {
          price = s.price;
        }
      }

      if (clearPrice === 0 || clearPrice > s.price) {
        clearPrice = s.price;
      }
    });

    const discountValue =
      clearPrice -
      (product.discountPrice !== 0 ? product.discountPrice : clearPrice);

    sum += price * quantity;
    sumWithoutDiscount += clearPrice * quantity;
    sumDiscount += discountValue * quantity;
  });
  return {
    sum: returnNumbers ? sum : sum.toFixed(2),
    sumWithoutDiscount: returnNumbers
      ? sumWithoutDiscount
      : sumWithoutDiscount.toFixed(2),
    sumDiscount: returnNumbers ? sumDiscount : sumDiscount.toFixed(2),
    totalQuantity: totalQuantity,
    totalSku: totalSku,
    modBalace: modBalace,
    modedProducts: modedProducts,
  };
};

export const capitalizeFirstLetter = (string: string) => {
  const val = string.trim();
  return val.charAt(0).toUpperCase() + val.slice(1);
};
