import { Input, Tree, Icons } from "@pankod/refine-antd";
import { FC, Key, useEffect, useMemo, useState, MutableRefObject } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoriesState,
  getCategoryFilter,
  setCategoriesState,
  setCategoryFilter,
} from "store/slices/catalogSlice";
import type { DataNode } from "antd/es/tree";
import { ICategory } from "types";
import { treeCatalog } from "components/helper";
import { useList } from "@pankod/refine-core";
import { cloneDeep } from "lodash";

const { Search } = Input;
const { DownOutlined } = Icons;

export interface ICategoriesFilterProps {
  ref1?: MutableRefObject<any>;
  onSelect?: (values: Key[]) => void;
}

export const CategoriesFilter: FC<ICategoriesFilterProps> = ({
  ref1,
  onSelect,
}) => {
  const dispatch = useDispatch();
  const stateCategories = useSelector(getCategoriesState);
  const categoryFilter = useSelector(getCategoryFilter);

  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);

  useEffect(() => {
    if (categoryFilter) {
      setSelectedKeys([categoryFilter]);
    } else {
      setSelectedKeys([]);
    }
  }, [categoryFilter]);

  const { data, isSuccess } = useList<ICategory>({
    resource: "catalog_b2b",
    queryOptions: {
      enabled: categories.length === 0,
    },
    metaData: {
      all: true,
    },
  });

  useEffect(() => {
    const saveCatalogToState = async (dataArr: ICategory[]) => {
      const treeCatalogArray = treeCatalog(dataArr);
      setCategories(treeCatalogArray);
    };

    if (isSuccess && data.data && data.data.length > 0) {
      dispatch(setCategoriesState(data.data));
      const newCatalog = cloneDeep(data.data);
      saveCatalogToState(newCatalog);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess]);

  const onExpand = (newExpandedKeys: Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const treeCatalogData = useMemo(() => {
    const loop = (data: ICategory[]): DataNode[] =>
      data.map((item) => {
        const strTitle = item.name as string;
        const index = strTitle.toLowerCase().indexOf(searchValue.toLowerCase());
        const beforeStr = strTitle.substring(0, index);
        const searchStr = strTitle.substring(index, searchValue.length + index);
        const afterStr = strTitle.slice(index + searchValue.length);

        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span
                style={{
                  color: "#f50",
                }}
              >
                {searchStr}
              </span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );

        const image = item.media.length !== 0 ? item.media[0].small : false;
        if (item.categories) {
          return {
            title,
            key: item.id,
            icon: image ? (
              <img
                src={`${process.env.REACT_APP_IMAGE_PATH}${image}`}
                alt={`${title}`}
                height={18}
                width={18}
              />
            ) : null,
            children: loop(item.categories),
          };
        }

        return {
          title,
          key: item.id,
          icon: image ? (
            <img
              src={`${process.env.REACT_APP_IMAGE_PATH}${image}`}
              height={18}
              alt={`${title}`}
              width={18}
            />
          ) : null,
        };
      });
    return loop(categories);
  }, [categories, searchValue]);

  const onSearchCategory = async (value: string) => {
    const search = async () => {
      const data = stateCategories
        .map((e) => {
          if (e.name.toLowerCase().includes(value.toLowerCase())) {
            return e.id;
          } else {
            return null;
          }
        })
        .filter((e) => e !== null);
      setExpandedKeys(data as Key[]);
    };
    if (value.trim() !== "" && value !== "undefined") {
      search();
      setSearchValue(value);
      setAutoExpandParent(true);
    } else {
      setExpandedKeys([]);
      setSearchValue("");
      setAutoExpandParent(false);
    }
  };

  return (
    <>
      <div ref={ref1}>
        <Search
          style={{ marginBottom: 8 }}
          placeholder="Пошук категорії"
          onSearch={onSearchCategory}
          onChange={(e) => {
            if (e.target.value === "" || e.target.value === "undefined") {
              setExpandedKeys([]);
              setSearchValue("");
              setAutoExpandParent(false);
            }
          }}
        />
      </div>

      <Tree
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        treeData={treeCatalogData}
        showIcon
        switcherIcon={<DownOutlined />}
        selectedKeys={selectedKeys}
        onSelect={(values) => {
          const value = values[0];
          dispatch(setCategoryFilter(value));

          onSelect && onSelect(values);
        }}
      />
    </>
  );
};
