import { Tour } from "@pankod/refine-antd";

import { ReactNode, useEffect, useState } from "react";

interface InterfaceTourStep {
  title: ReactNode;
  description?: ReactNode;
  cover?: ReactNode;
  target?: HTMLElement | (() => HTMLElement) | (() => null) | null | undefined;
  onFinish?: (() => void) | undefined;
  onClose?: (() => void) | undefined;
  onNext?: (() => void) | undefined;
  onPrev?: (() => void) | undefined;
  type?: "default" | "primary";
  placement?:
    | "left"
    | "leftTop"
    | "leftBottom"
    | "right"
    | "rightTop"
    | "rightBottom"
    | "top"
    | "topLeft"
    | "topRight"
    | "bottom"
    | "bottomLeft"
    | "bottomRight"
    | undefined;
}

type ITourStep = InterfaceTourStep | null;

interface ITourProps {
  steps: ITourStep[];
  indicatorsRender?:
    | ((current: number, total: number) => ReactNode)
    | undefined;

  initOpen?: boolean;
  storageHasOpen?: string;
  key?: string | number;
}

export const useTour = ({
  steps,
  indicatorsRender,
  key,
  storageHasOpen,
  initOpen = false,
}: ITourProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const tourSteps = steps.filter((e) => e !== null) as InterfaceTourStep[];

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        setOpen(false);
      }
    };
    window.document.addEventListener("keyup", onKeyDown);
    return () => {
      window.document.removeEventListener("keyup", onKeyDown);
    };
  }, []);
  const tourElement = (
    <Tour
      open={open}
      onClose={() => setOpen(false)}
      steps={tourSteps.map((e, i) => {
        return {
          ...e,
          nextButtonProps: {
            children: tourSteps.length === i + 1 ? "Завершити" : "Далі",
          },
          prevButtonProps: { children: "Назад" },
        };
      })}
      key={key}
      indicatorsRender={indicatorsRender}
    />
  );

  useEffect(() => {
    if (initOpen && !open) {
      if (storageHasOpen) {
        const hasOpenKey = localStorage.getItem(`tour-${storageHasOpen}`);
        if (!hasOpenKey || hasOpenKey !== "true") {
          setOpen(true);
          localStorage.setItem(`tour-${storageHasOpen}`, "true");
        }
      } else {
        setOpen(true);
      }
    }
  }, [initOpen]);

  return { tourElement, open, setOpen };
};
