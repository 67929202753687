import { TOKEN_KEY } from "authProvider";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { IJWTData } from "types";

export const useGetTokenData = (): IJWTData | undefined => {
  const [tokenData, setTokenData] = useState<IJWTData | undefined>();

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) return;
    const userData = jwtDecode<IJWTData>(token);
    setTokenData(userData);
  }, []);

  return tokenData;
};
