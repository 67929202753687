/* eslint-disable react-hooks/exhaustive-deps */
import { Col, InputNumber, Row, Slider } from "@pankod/refine-antd";
import { debounce } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPricesFilter, setPricesFilter } from "store/slices/catalogSlice";
import { IPricesFilter } from "types";

export interface IPricesFilterProps {
  minMaxPrices: IPricesFilter;
  onChangeFilter?: () => void;
}

export const PricesFilter: FC<IPricesFilterProps> = ({
  minMaxPrices: initialMinMaxPrices,

  onChangeFilter,
}) => {
  const dispatch = useDispatch();

  const pricesFilter = useSelector(getPricesFilter);
  const [minMaxPrices, setMinMaxPrices] = useState<IPricesFilter>({
    maxPrice: 0,
    minPrice: 0,
  });
  useEffect(() => {
    if (
      (pricesFilter.maxPrice !== minMaxPrices.maxPrice &&
        pricesFilter.maxPrice !== 0) ||
      (pricesFilter.minPrice !== minMaxPrices.minPrice &&
        pricesFilter.minPrice !== 0)
    ) {
      setMinMaxPrices(pricesFilter);
    }
  }, [pricesFilter]);

  useEffect(() => {
    if (
      (pricesFilter.maxPrice !== minMaxPrices.maxPrice &&
        minMaxPrices.maxPrice !== 0) ||
      (pricesFilter.minPrice !== minMaxPrices.minPrice &&
        minMaxPrices.minPrice !== 0)
    ) {
      dispatchMinMaxPrices(minMaxPrices);
    }
  }, [minMaxPrices]);

  const dispatchMinMaxPrices = useCallback(
    debounce((prices) => {
      onChangeFilter && onChangeFilter();
      dispatch(setPricesFilter(prices));
    }, 200),
    []
  );
  const onChange = (minMaxPrice: [number, number]) => {
    setMinMaxPrices({ minPrice: minMaxPrice[0], maxPrice: minMaxPrice[1] });
  };

  return (
    <Row>
      <Col span={12}>
        <InputNumber
          min={initialMinMaxPrices.minPrice}
          max={initialMinMaxPrices.maxPrice}
          style={{ margin: "0 16px" }}
          value={minMaxPrices.minPrice}
          step={0.1}
          onChange={(val) => {
            setMinMaxPrices({
              maxPrice: minMaxPrices.maxPrice,
              minPrice: val as number,
            });
          }}
        />
      </Col>{" "}
      <Col span={12}>
        <InputNumber
          min={initialMinMaxPrices.minPrice}
          max={initialMinMaxPrices.maxPrice}
          value={minMaxPrices.maxPrice}
          style={{ margin: "0 16px" }}
          step={0.1}
          onChange={(val) => {
            setMinMaxPrices({
              minPrice: minMaxPrices.minPrice,
              maxPrice: val as number,
            });
          }}
        />
      </Col>
      <Col span={24}>
        <Slider
          min={initialMinMaxPrices.minPrice}
          max={initialMinMaxPrices.maxPrice}
          range
          value={[minMaxPrices.minPrice, minMaxPrices.maxPrice]}
          onChange={onChange}
          step={0.01}
        />
      </Col>
    </Row>
  );
};
