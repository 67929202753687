/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Drawer,
  ImageField,
  notification,
  Row,
  Space,
  TextField,
} from "@pankod/refine-antd";
import { useMany, useNavigation } from "@pankod/refine-core";
import { useLocation } from "@pankod/refine-react-router-v6";
import { TOKEN_KEY } from "authProvider";
import axios from "axios";
import { AddProductToCart } from "components/catalog";
import { capitalizeFirstLetter, getCartInfo } from "components/helper";
import { useCanAccessByRole, useFetchCart } from "hooks";
import { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCounterpartyId, getOutletId } from "store/slices/appSlice";
import {
  getCartItems,
  getProducts,
  setCartItems,
  setProducts,
} from "store/slices/cartSlice";
import { closeCartDrawer, hasOpenCartDrawer } from "store/slices/modalsSlice";
import { AppState } from "store/store";
import { IProduct } from "types";
import "./CartDrawer.css";

export interface ICartDrawerProps {}

export const CartDrawer: FC = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  const location = useLocation();
  const dispatch = useDispatch();
  const { push, show } = useNavigation();
  const cartDrawerOpen = useSelector<AppState, boolean>(hasOpenCartDrawer);
  const counterpartyId = useSelector(getCounterpartyId);
  const outletId = useSelector(getOutletId);
  const cartItems = useSelector(getCartItems);
  const products = useSelector(getProducts);
  const canAccess = useCanAccessByRole(["buyer"]);
  const { fetchCart, canFetch } = useFetchCart();

  useEffect(() => {
    if (counterpartyId && outletId && canFetch && canAccess) {
      fetchCart();
    }
  }, [outletId, canFetch, canAccess, counterpartyId]);

  const { data, refetch, isSuccess } = useMany<IProduct>({
    resource: "product",
    ids: cartItems.map((e) => e.productId),
    metaData: {
      query: [
        { field: "c", operator: "eq", value: counterpartyId },
        { field: "o", operator: "eq", value: outletId },
        { field: "isCart", operator: "eq", value: true },
      ],
    },
    queryOptions: {
      enabled:
        cartItems.length > 0 &&
        counterpartyId !== undefined &&
        outletId !== undefined &&
        canAccess,
    },
  });

  useEffect(() => {
    if (cartItems) {
      refetch();
    }
  }, [cartItems]);

  useEffect(() => {
    if (isSuccess && data.data && data.data.length > 0) {
      dispatch(setProducts(data.data));
    } else if (isSuccess && data.data && data.data.length === 0) {
      dispatch(setProducts([]));
    }
  }, [isSuccess, data?.data]);

  const removeCartItem = async (
    productName: string,
    productId: number,
    quantity: number
  ) => {
    if (!canAccess) return;
    try {
      const { data, status } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/b2b/cart?c=${counterpartyId}&o=${outletId}`,

        {
          data: [
            {
              productId: productId,
              quantity: quantity,
            },
          ],
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        notification.open({
          message: `Видалено з кошика`,
          description: `${productName}`,
          type: "success",
        });

        dispatch(setCartItems(data));
        notification.destroy(productId);
      }
    } catch (e) {
      //TODO: show error
    }
  };

  const changeCartItemQuantity = async (
    productName: string,
    productId: number,
    newQuantity: number
  ) => {
    if (!token || !outletId || !counterpartyId || !canAccess) return;

    try {
      const { data, status } = await axios.put(
        `${process.env.REACT_APP_API_URL}/b2b/cart?c=${counterpartyId}&o=${outletId}`,
        [
          {
            productId: productId,
            quantity: newQuantity,
          },
        ],
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        notification.open({
          description: (
            <span>
              <b>{productName}</b> - нова кількість <b>{newQuantity}</b>
            </span>
          ),
          message: `Товар в кошику змінено`,

          type: "success",
        });

        dispatch(setCartItems(data));
        notification.destroy(productId);
      }
    } catch (e) {
      //TODO: show error
    }
  };
  const { sum, modBalace, totalSku, modedProducts } = useMemo(() => {
    return getCartInfo(cartItems, products);
  }, [cartItems, products]);

  useEffect(() => {
    if (modBalace && modedProducts.length > 0) {
      modedProducts.forEach((product) => {
        notification.error({
          message: "УВАГА! Кількість товару в кошику змінилась",
          type: "error",
          key: product.id,
          duration: 0,

          description: (
            <Row>
              <Col>
                <b>{capitalizeFirstLetter(product.name)}</b> <br></br>з{" "}
                <b>{product.prev}</b> на <b>{product.new}</b>
              </Col>
              <Col
                style={{
                  marginTop: "8px",
                }}
              >
                <Space align="end">
                  <Button
                    danger
                    onClick={() => {
                      removeCartItem(product.name, product.id, product.prev);
                    }}
                  >
                    Видалити
                  </Button>
                  {product.new !== 0 && (
                    <Button
                      onClick={() =>
                        changeCartItemQuantity(
                          product.name,
                          product.id,
                          product.new
                        )
                      }
                    >
                      Залишити {product.new} шт.
                    </Button>
                  )}
                </Space>
              </Col>
            </Row>
          ),
        });
      });
    }
  }, [modBalace, modedProducts, location.pathname]);

  return (
    <Drawer
      key={"cartDrawer"}
      title={"Кошик"}
      placement="right"
      footer={
        <Row className="summary">
          <Col
            style={{
              marginRight: "8px",
            }}
          >
            <span>Всього найменувань </span>
            <span>
              <b>{totalSku}</b>,
            </span>
            <span>на сумму </span>
            <span>
              <b>{sum} грн.</b>
            </span>
          </Col>

          <Col
            style={{
              marginRight: "8px",
            }}
          >
            <Button
              danger
              onClick={() => {
                dispatch(closeCartDrawer());
                push("checkout");
              }}
            >
              Оформити замовлення
            </Button>
          </Col>
        </Row>
      }
      size={"large"}
      style={{
        height: "100%",
      }}
      open={cartDrawerOpen}
      onClose={() => dispatch(closeCartDrawer())}
    >
      {cartItems.length > 0 &&
        products.length > 0 &&
        products.map((product) => {
          const cartItem = cartItems.find((s) => s.productId === product.id);
          if (!cartItem) return null;

          let media = product.media.find((e) => e.collectionName === "main");
          if (!media && product.media.length > 0) {
            media = product.media[0];
          }

          const priceField = () => {
            if (product.discountPrice !== 0) {
              return (
                <Row>
                  <Col span={24}>
                    <del className="old-price">
                      {product.prices[0].price} <span>грн.</span>
                    </del>
                  </Col>
                  <Col span={24}>
                    <div className="price-discount">
                      {product.discountPrice} <span>грн.</span>
                    </div>
                  </Col>
                </Row>
              );
            }
            return (
              <TextField
                className="price"
                value={`${product.prices[0].price} грн.`}
              />
            );
          };

          return (
            <Row
              key={`${product.code}`}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "8px",
                paddingTop: "8px",
                borderBottom: "solid 1px #efefef",
              }}
            >
              <Col
                span={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ImageField
                  preview={false}
                  value={`${process.env.REACT_APP_IMAGE_PATH}${media?.small}`}
                  height={64}
                />
              </Col>
              <Col span={8}>
                {" "}
                {
                  <TextField
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => show("products", product.id)}
                    value={capitalizeFirstLetter(product.name)}
                  />
                }
              </Col>
              <Col span={4}>{priceField()}</Col>
              <Col span={8}>
                <AddProductToCart
                  key={`${product.id}-${cartItem.quantity}`}
                  productId={product.id}
                  cartQuantity={cartItem.quantity}
                  initialValue={cartItem.quantity}
                  productName={product.name}
                  onChangeBalance={(productId: number) => {
                    notification.destroy(productId);
                  }}
                  liveUpdate
                  canDelete
                />
              </Col>
            </Row>
          );
        })}
    </Drawer>
  );
};
