import {
  Col,
  Skeleton,
  Card,
  Checkbox,
  Row,
  Space,
  Icons,
  Button,
  useModalForm,
  EditButton,
  DeleteButton,
} from "@pankod/refine-antd";
import { List } from "antd";
import { TOKEN_KEY } from "authProvider";
import axios from "axios";
import { CounterpartyCreateModal, OutletCreateModal } from "components/modals";
import { useClearStore, useGetCOParams } from "hooks";
import { FC, MutableRefObject, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ICounterparty, IListResponse, IOutlet } from "types";
const { EditOutlined } = Icons;
export interface ICounterpartyInfoProps {
  ref1?: MutableRefObject<any>;
  ref2?: MutableRefObject<any>;
  ref3?: MutableRefObject<any>;
  ref4?: MutableRefObject<any>;
}
export const CounterpartyList: FC<ICounterpartyInfoProps> = ({
  ref1,
  ref2,
  ref3,
  ref4,
}) => {
  const token = localStorage.getItem(TOKEN_KEY);
  const { hasCO, counterpartyId, outletId } = useGetCOParams();
  const [loading, setLoading] = useState(false);
  const [isEditOutlet, setIsEditOutlet] = useState(false);
  const [isEditCounterparty, setIsEditCounterparty] = useState(false);
  const [counterparties, setCounterparties] = useState<ICounterparty[]>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedCounerparty, setSelectedCounerparty] = useState<number>(0);
  const { clearOutlet, clearCounterparty } = useClearStore();
  const {
    modalProps: createCounterpartyModalProps,
    formProps: createCounterpartyFormProps,
    show: createCounterpartyModalShow,
  } = useModalForm<ICounterparty>({
    action: isEditCounterparty ? "edit" : "create",
    resource: "client",
    redirect: false,
    metaData: {
      query: [
        {
          field: "c",
          operator: "eq",
          value: counterpartyId,
        },
        {
          field: "o",
          operator: "eq",
          value: outletId,
        },
      ],
    },
    onMutationSuccess(data, variables, context) {
      setCounterparties([]);
      loadCounterpartiesData();
    },
  });

  const {
    modalProps: createOutletModalProps,
    formProps: createOutletFormProps,

    show: createOutletModalShow,
  } = useModalForm<IOutlet>({
    action: isEditOutlet ? "edit" : "create",
    resource: "outlet",
    redirect: false,
    metaData: {
      query: [
        {
          field: "c",
          operator: "eq",
          value: counterpartyId,
        },
        {
          field: "o",
          operator: "eq",
          value: outletId,
        },
      ],
    },
    onMutationSuccess(data, variables, context) {
      loadOutlets(true);
    },
  });

  const loadCounterpartiesData = async () => {
    if (!counterpartyId || !outletId || !token) return;
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { data, status } = await axios.get<IListResponse<ICounterparty>>(
        `${process.env.REACT_APP_API_URL}/client?c=${counterpartyId}&o=${outletId}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        setCounterparties(data.rows);

        setLoading(false);
        setCount(data.count);
        if (selectedCounerparty === 0 && data.rows.length > 0) {
          setSelectedCounerparty(data.rows[0].id);
        }
      }
    } catch (e) {}
  };

  const loadOutlets = async (refetch: boolean = false) => {
    if (!counterpartyId || !outletId || !token) return;
    let counterparty = counterparties.find((e) => e.id === selectedCounerparty);

    if (
      !refetch &&
      counterparty &&
      counterparty.outlets &&
      counterparty.outlets.length > 0
    )
      return;
    try {
      const { data, status } = await axios.get<IListResponse<IOutlet>>(
        `${process.env.REACT_APP_API_URL}/outlet?c=${counterpartyId}&o=${outletId}&ownerId=${selectedCounerparty}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        if (selectedCounerparty !== 0 && data.rows.length > 0) {
          const index = counterparties.findIndex(
            (e) => e.id === selectedCounerparty
          );

          if (counterparty && index !== -1) {
            counterparty.outlets = data.rows;
            counterparty.outletsCount = data.count;

            let newArrr = [
              ...counterparties.filter((e) => e.id !== selectedCounerparty),
            ];

            newArrr.splice(index, 0, counterparty);
            setCounterparties(newArrr);
          }
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    loadCounterpartiesData();
  }, [hasCO]);

  useEffect(() => {
    if (selectedCounerparty !== 0) {
      loadOutlets();
    }
  }, [selectedCounerparty]);
  return (
    <>
      <Col lg={12} sm={24} xl={8}>
        <Card
          title="Контрагенти"
          ref={ref1}
          bodyStyle={{ height: "350px", overflowY: "auto" }}
          actions={[
            <Row
              justify={"end"}
              style={{
                margin: "0px 12px",
              }}
            >
              <Button
                ref={ref2}
                onClick={() => {
                  createCounterpartyModalShow();
                  setIsEditCounterparty(false);
                }}
                disabled={!hasCO}
              >
                Створити контрагента
              </Button>
            </Row>,
          ]}
        >
          <InfiniteScroll
            dataLength={count}
            next={loadCounterpartiesData}
            hasMore={counterparties.length < count}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          >
            <List
              dataSource={counterparties}
              renderItem={(item) => {
                return (
                  <List.Item
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSelectedCounerparty(item.id);
                    }}
                  >
                    <List.Item.Meta
                      title={
                        <Checkbox
                          value={item.id}
                          checked={selectedCounerparty === item.id}
                          onChange={(e) => {
                            setSelectedCounerparty(item.id);
                          }}
                        >
                          {item.name}
                        </Checkbox>
                      }
                      description={`ІНН/ЄДРПОУ: ${item.innEdrpou}`}
                    />
                    <List.Item>
                      <Space>
                        Точок {item.outletsCount}
                        <Button
                          icon={<EditOutlined />}
                          disabled={!hasCO}
                          onClick={(e) => {
                            e.preventDefault();
                            setIsEditCounterparty(true);
                            createCounterpartyFormProps.form?.setFieldsValue({
                              innEdrpou: "",
                              name: "",
                            });
                            createCounterpartyModalShow(item.id);
                          }}
                        />
                        <DeleteButton
                          disabled={!hasCO}
                          hideText
                          resourceNameOrRouteName="client"
                          onSuccess={() => {
                            loadCounterpartiesData();
                            if (item.id === counterpartyId) {
                              clearCounterparty();
                            }
                          }}
                          metaData={{
                            query: [
                              {
                                field: "c",
                                operator: "eq",
                                value: counterpartyId,
                              },
                              {
                                field: "o",
                                operator: "eq",
                                value: outletId,
                              },
                            ],
                          }}
                          recordItemId={item.id}
                        />
                      </Space>
                    </List.Item>
                  </List.Item>
                );
              }}
            />
          </InfiniteScroll>
        </Card>
      </Col>
      <Col lg={12} sm={24} xl={8}>
        <Card
          title={"Точки доставки"}
          ref={ref3}
          bodyStyle={{ height: "350px", overflowY: "auto" }}
          actions={[
            <Row
              justify={"end"}
              style={{
                margin: "0px 12px",
              }}
            >
              <Button
                ref={ref4}
                onClick={() => {
                  setIsEditOutlet(false);
                  createOutletFormProps.form?.setFieldsValue({
                    address: "",
                    contactPerson: "",
                    description: "",
                    email: "",
                    name: "",
                    phone: "",
                  });
                  createOutletModalShow();
                }}
              >
                Створити точку
              </Button>
            </Row>,
          ]}
        >
          <List
            dataSource={
              counterparties.find((e) => e.id === selectedCounerparty)
                ?.outlets ?? []
            }
            renderItem={(item) => {
              return (
                <List.Item
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <List.Item.Meta
                    title={item.name}
                    description={`Контактне лице: ${item.contactPerson}`}
                  />
                  <List.Item>
                    <Space>
                      <EditButton
                        hideText
                        recordItemId={`${item.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsEditOutlet(true);
                          createOutletModalShow(item.id);
                        }}
                      />
                      <DeleteButton
                        hideText
                        onSuccess={() => {
                          loadOutlets(true);
                          if (item.id === outletId) {
                            clearOutlet();
                          }
                        }}
                        metaData={{
                          query: [
                            {
                              field: "c",
                              operator: "eq",
                              value: counterpartyId,
                            },
                            {
                              field: "o",
                              operator: "eq",
                              value: outletId,
                            },
                          ],
                        }}
                        resourceNameOrRouteName="outlet"
                        recordItemId={item.id}
                      />
                    </Space>
                  </List.Item>
                </List.Item>
              );
            }}
          />
        </Card>
      </Col>
      <CounterpartyCreateModal
        createModalProps={createCounterpartyModalProps}
        createFormProps={createCounterpartyFormProps}
        action={isEditCounterparty ? "edit" : "create"}
      />
      <OutletCreateModal
        createModalProps={createOutletModalProps}
        createFormProps={createOutletFormProps}
        ownerId={selectedCounerparty}
        action={isEditOutlet ? "edit" : "create"}
      />
    </>
  );
};
