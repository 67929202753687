import { Button, Col, Icons, Popover, Row, Tooltip } from "@pankod/refine-antd";

import { useGetTokenData, useWishlists } from "hooks";
import React, { FC, MutableRefObject } from "react";
import { IProduct } from "types";

const { HeartOutlined, HeartFilled } = Icons;
export interface IWishlistButtom {
  product: IProduct;
  groupId?: number;
  ref1?: MutableRefObject<any>;
}
export const WishlistButton: FC<IWishlistButtom> = ({
  product,
  groupId,
  ref1,
}) => {
  const {
    changeProductToGroup,
    hasProductInWishlist,
    getProductsByGroup,
    totalGroups,
    wishlistGroups,
  } = useWishlists();

  const userData = useGetTokenData();
  const hasWishlisted = hasProductInWishlist(product.id, groupId);

  const icon = () => {
    return hasWishlisted ? <HeartFilled /> : <HeartOutlined />;
  };

  if (totalGroups > 1 && !groupId) {
    return (
      <Popover
        content={
          <Row>
            {wishlistGroups.map((e) => {
              return (
                <Col key={e.id} span={24}>
                  <Row align={"middle"}>
                    <Tooltip
                      title={
                        userData?.id !== e.ownerId
                          ? `Тільки на перегляд`
                          : !hasProductInWishlist(product.id, e.id)
                          ? `Додати в список "${e.name}"`
                          : `Видалити з списку "${e.name}"`
                      }
                    >
                      <Button
                        icon={
                          getProductsByGroup(e.id).find(
                            (s) => s.id === product.id
                          ) ? (
                            <HeartFilled />
                          ) : (
                            <HeartOutlined />
                          )
                        }
                        disabled={userData?.id !== e.ownerId}
                        type={"ghost"}
                        style={{
                          color: "#fadb14",
                        }}
                        onClick={() => {
                          changeProductToGroup(product, e.id);
                        }}
                      />
                    </Tooltip>
                    <span
                      style={{
                        color: userData?.id !== e.ownerId ? "red" : "black",
                      }}
                    >
                      {e.name}
                    </span>
                  </Row>
                </Col>
              );
            })}
          </Row>
        }
        title="Виберіть список"
        trigger="click"
      >
        <Button
          icon={icon()}
          type={"ghost"}
          style={{
            color: "#fadb14",
          }}
          ref={ref1}
        />
      </Popover>
    );
  }
  return (
    <Tooltip title={!hasWishlisted ? "Додати в обрані" : "Видалити з обраного"}>
      <Button
        icon={icon()}
        type={"ghost"}
        style={{
          color: "#fadb14",
        }}
        onClick={() => {
          if (!groupId) {
            changeProductToGroup(product);
          } else {
            changeProductToGroup(product, groupId);
          }
        }}
      />
    </Tooltip>
  );
};
