import {
  AutoComplete,
  Input,
  Icons,
  Typography,
  Row,
  ImageField,
  Space,
  Col,
  TextField,
} from "@pankod/refine-antd";
import { CrudFilters, useList } from "@pankod/refine-core";
import { Link, useNavigate } from "@pankod/refine-react-router-v6";
import { useGetCOParams } from "hooks";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCategoryFilter } from "store/slices/catalogSlice";
import { ICategory, IProduct } from "types";

const { SearchOutlined } = Icons;
const { Text } = Typography;

export const FullSearch = () => {
  const { counterpartyId, outletId, hasCO } = useGetCOParams();
  const navitage = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<any[]>([]);
  const [permanentFilter, setPermanentFilter] = useState<CrudFilters>([
    { field: "c", operator: "eq", value: counterpartyId },
    { field: "o", operator: "eq", value: outletId },
  ]);
  useEffect(() => {
    if (hasCO) {
      setPermanentFilter([
        { field: "c", operator: "eq", value: counterpartyId },
        { field: "o", operator: "eq", value: outletId },
      ]);
    }
  }, [counterpartyId, outletId]);
  const renderTitle = (title: string) => {
    return (
      <Text strong style={{ fontSize: "16px" }}>
        {title}
      </Text>
    );
  };

  const renderCategory = (title: string, id: number) => {
    return {
      value: title,
      label: (
        <Text
          onClick={() => {
            dispatch(setCategoryFilter(id));
            navitage(`products`, {
              replace: true,
            });
          }}
        >
          {title}
        </Text>
      ),
    };
  };
  const renderProduct = (product: IProduct) => {
    let media = product.media.find((e) => e.collectionName === "main");
    if (!media && product.media.length > 0) {
      media = product.media[0];
    }
    let price = <></>;
    if (product.discountPrice !== 0) {
      price = (
        <Row>
          {" "}
          <Text type={"danger"}>{` - ${product.discountPrice} грн.`}</Text>{" "}
          <Text delete>{` ${product.prices[0].price} грн.`}</Text>
        </Row>
      );
    } else {
      price = <Text>{` - ${product.prices[0].price} грн.`}</Text>;
    }

    return {
      value: product.name,
      label: (
        <Link to={`/products/show/${product.id}`}>
          <Space size={8}>
            <Text strong>{product.vendor}</Text>
            <Col
              style={{
                width: 32,
              }}
            >
              {media && (
                <ImageField
                  preview={false}
                  value={`${process.env.REACT_APP_IMAGE_PATH}${media.small}`}
                  height={32}
                />
              )}
            </Col>
            <Text>{product.name}</Text>
            {price}
          </Space>
        </Link>
      ),
    };
  };
  const { refetch: refetchProducts } = useList<IProduct>({
    resource: "product/search",
    config: {
      filters: [...permanentFilter, { field: "q", operator: "eq", value }],
    },

    queryOptions: {
      enabled: false,

      onSuccess: (data) => {
        const postOptionGroup = data.data.map((item) => renderProduct(item));
        if (postOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            {
              label: renderTitle("Товари"),
              options: postOptionGroup,
            },
            ...prevOptions,
          ]);
        }
      },
    },
  });
  const { refetch: refetchCatalog } = useList<ICategory>({
    resource: "catalog_b2b/search",
    config: {
      filters: [{ field: "q", operator: "eq", value }],
    },

    queryOptions: {
      enabled: false,

      onSuccess: (data) => {
        const postOptionGroup = data.data.map((item) =>
          renderCategory(item.name, item.id)
        );
        if (postOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle("Категорії"),
              options: postOptionGroup,
            },
          ]);
        }
      },
    },
  });

  useEffect(() => {
    if (hasCO) {
      setOptions([]);
      refetchCatalog();
      refetchProducts();
    }
  }, [value]);

  if (!hasCO) {
    return <></>;
  }

  return (
    <AutoComplete
      style={{ width: "100%" }}
      options={options}
      filterOption={false}
      onSearch={debounce((value: string) => setValue(value), 500)}
    >
      <Input
        size="middle"
        placeholder="Пошук товарів, категорій"
        suffix={<SearchOutlined />}
      />
    </AutoComplete>
  );
};
