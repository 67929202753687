import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICartItem, IProduct } from "types";
import { AppState } from "../store";

export interface ICartState {
  cartItems: ICartItem[];
  products: IProduct[];
}
const initialState: ICartState = {
  cartItems: [],
  products: [],
};

const cartSlice = createSlice({
  initialState,
  name: "cart",
  reducers: {
    setCartItems: (state, { payload }: PayloadAction<ICartItem[]>) => {
      state.cartItems = payload;
    },
    setProducts: (state, { payload }: PayloadAction<IProduct[]>) => {
      state.products = payload;
    },
  },
});

export default cartSlice.reducer;

export const { setCartItems, setProducts } = cartSlice.actions;

export const getCartItems = (state: AppState) => state.cart.cartItems;
export const getProducts = (state: AppState) => state.cart.products;
export const getCartItemsCount = (state: AppState) =>
  state.cart.cartItems.length;
