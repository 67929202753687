import { useMany } from "@pankod/refine-core";
import {
  Card,
  Empty,
  Row,
  Select,
  Table,
  TextField,
} from "@pankod/refine-antd";
import { TOKEN_KEY } from "authProvider";
import axios from "axios";
import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getCounterpartyId, getOutletId } from "store/slices/appSlice";
import { ICartInfo, ICartItem, IPartnerCart, IProduct } from "types";
import "./CartsWidget.css";
import { getCartInfo } from "components/helper";
import { ProductsTable } from "../ProductsTable";
import { ProductCartSelector } from "../ProductCartSelector";
import { useCanAccessByRole, useFetchCart } from "hooks";
const { Column } = Table;

export interface ICartsWidgetProps {}

export const CartsWidget: FC<ICartsWidgetProps> = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  const canAccess = useCanAccessByRole(["buyer"]);

  const counterpartyId = useSelector(getCounterpartyId);
  const outletId = useSelector(getOutletId);
  const { fetchCart, canFetch } = useFetchCart();

  const [carts, setCarts] = useState<IPartnerCart[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [cartsInfo, setCartsInfo] = useState<ICartInfo[]>([]);

  const { productIds } = useMemo(() => {
    let productIds: number[] = [];

    if (carts.length === 0) return { productIds };

    for (const cart of carts) {
      if (cart.carts.length === 0) continue;

      for (const e of cart.carts) {
        if (e.cartItems.length === 0) continue;

        for (const s of e.cartItems) {
          if (!productIds.includes(s.productId)) {
            productIds.push(s.productId);
          }
        }
      }
    }

    return { productIds };
  }, [carts]);

  const { data, isSuccess } = useMany<IProduct>({
    resource: "product",
    ids: productIds,
    metaData: {
      query: [
        { field: "c", operator: "eq", value: counterpartyId },
        { field: "o", operator: "eq", value: outletId },
        { field: "isCart", operator: "eq", value: true },
      ],
    },
    queryOptions: {
      enabled:
        productIds.length > 0 && counterpartyId != null && outletId != null,
    },
  });

  const fetchCarts = async () => {
    if (!canAccess) return;
    try {
      const { data, status } = await axios.get<IPartnerCart[]>(
        `${process.env.REACT_APP_API_URL}/b2b/cart/all?c=${counterpartyId}&o=${outletId}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        setCarts(data);
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (outletId && counterpartyId && canAccess) {
      fetchCarts();
    }
  }, [counterpartyId, outletId, canAccess]);

  useEffect(() => {
    if (products.length > 0 && carts.length > 0) {
      let s = [];
      for (const cs of carts) {
        if (cs.carts.length === 0) continue;
        for (const cart of cs.carts) {
          if (cart.cartItems.length === 0) continue;
          const data = getCartInfo(cart.cartItems, products, true);
          s.push({ ...data, id: cart.id });
        }
      }
      setCartsInfo(s);
    }
  }, [products, carts]);

  useEffect(() => {
    if (data && isSuccess && data.data.length > 0) {
      setProducts(data.data);
    } else if (isSuccess && data && data.data.length === 0) {
      setProducts([]);
    }
  }, [data, isSuccess]);

  if (carts.length === 0 || !canAccess) {
    return (
      <Card title="Кошики">
        <Empty />
      </Card>
    );
  }
  return (
    <Card title="Кошики" className="custom-body">
      <Table
        dataSource={carts}
        expandable={{
          expandedRowRender: (record, index) => {
            const cartInfo = cartsInfo.find((e) => e.id === record.cartId);
            const cartItems = record.carts.reduce((prev: ICartItem[], cur) => {
              return [...prev, ...cur.cartItems];
            }, []);
            const prodIds = cartItems.map((e) => e.productId);
            const produ = products.filter((e) => prodIds.includes(e.id));

            return (
              <ProductsTable
                key={record.cartId}
                cartItems={cartItems}
                products={produ}
                sum={((cartInfo?.sum ?? 0) as number).toFixed(2)}
                totalSku={cartInfo?.totalSku}
                pagination={{
                  pageSize: 5,
                }}
                footer={
                  <ProductCartSelector
                    c={record.counterpartyId}
                    o={record.outletId}
                    onProductAdded={() => {
                      fetchCarts();
                      if (
                        record.counterpartyId === counterpartyId &&
                        record.outletId === outletId &&
                        canFetch
                      ) {
                        fetchCart();
                      }
                    }}
                  />
                }
                canRead
                cantUpdate={
                  !(
                    record.counterpartyId === counterpartyId &&
                    record.outletId === outletId
                  )
                }
                ovverideCO={{
                  c: record.counterpartyId,
                  o: record.outletId,
                }}
                onChangeCart={() => {
                  fetchCarts();
                }}
              />
            );
          },
        }}
        rowKey={"cartId"}
        pagination={{
          pageSize: 5,
        }}
      >
        <Column<IPartnerCart>
          title={"Контрагент"}
          key="counterparty"
          dataIndex={"counterparty"}
          render={(value, record) => {
            return <TextField value={value.name} />;
          }}
        />
        <Column<IPartnerCart>
          title={"Точка доставки"}
          key="outlet"
          dataIndex={"outlet"}
          render={(value, record) => {
            return <TextField value={value.name} />;
          }}
        />{" "}
        <Column<IPartnerCart>
          title={"Автор"}
          key="partner"
          dataIndex={"partner"}
          render={(value, record) => {
            const str = `${value.name} ${value.firstName}`;
            return <TextField value={str} />;
          }}
        />
        <Column<IPartnerCart>
          title={"Товарів"}
          key="cartItemsLength"
          dataIndex={"cartItemsLength"}
          render={(value, record) => {
            let quantity = 0;
            for (const cart of record.carts) {
              quantity += cart.cartItems.length;
            }

            return <TextField value={quantity} />;
          }}
        />
        <Column<IPartnerCart>
          title={"Кількісь"}
          key="cartItemsCount"
          dataIndex={"cartItemsCount"}
          render={(value, record) => {
            let quantity = 0;
            for (const cart of record.carts) {
              quantity += cart.cartItems.reduce((a, b) => a + b.quantity, 0);
            }

            return <TextField value={quantity} />;
          }}
        />
        <Column<IPartnerCart>
          title={"Сума"}
          key="sum"
          dataIndex={"sum"}
          render={(value, record) => {
            let totalSum = 0;
            for (const cart of record.carts) {
              const info = cartsInfo.find((e) => e.id && e.id === cart.id);
              totalSum += (info?.sum as number) ?? 0;
            }

            return <TextField value={`${totalSum.toFixed(2)} грн.`} />;
          }}
        />
      </Table>
    </Card>
  );
};
