import {
  Card,
  Col,
  Row,
  Icons,
  Typography,
  Button,
  Space,
  Table,
  TextField,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useOne } from "@pankod/refine-core";
import { useLocation } from "@pankod/refine-react-router-v6";
import { capitalizeFirstLetter } from "components/helper";
import { useCanAccessByRole, useGetCOParams } from "hooks";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { IOrder, IOrderItem } from "types";
const { PrinterOutlined } = Icons;
const { Text } = Typography;
const { Column } = Table;
export const OrderDetailsPage: FC<IResourceComponentsProps> = (options) => {
  const { pathname } = useLocation();
  const canAccess = useCanAccessByRole(["buyer"]);
  const id = pathname.slice(pathname.lastIndexOf("/") + 1);
  const { hasCO, counterpartyId, outletId } = useGetCOParams();
  const [order, setOrder] = useState<IOrder>();

  const { data, isSuccess } = useOne<IOrder>({
    resource: "b2b/order",
    id: id,
    metaData: {
      query: [
        { field: "c", operator: "eq", value: counterpartyId },
        { field: "o", operator: "eq", value: outletId },
      ],
    },
    queryOptions: {
      enabled: hasCO && canAccess,
    },
  });

  useEffect(() => {
    if (data && data.data && isSuccess && canAccess) {
      setOrder(data.data);
    }
  }, [data, isSuccess]);
  return (
    <>
      <Helmet>
        <title>Замовлення №{id}| Офіс Центр</title>
      </Helmet>
      <Row wrap gutter={[8, 8]}>
        <Col lg={18} sm={24}>
          <Card
            title={`Замовлення № ${order?.id}`}
            extra={[
              <Button type="primary" icon={<PrinterOutlined />}>
                Друк
              </Button>,
            ]}
          >
            <Card title="Клієнт">
              <Row>
                <Col span={8}>
                  <Space>
                    <Text>Контрагент: </Text>
                    <Text strong>{order?.counterparty.name}</Text>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space>
                    <Text>ІІН/ЄДРПОУ: </Text>
                    <Text strong>{order?.counterparty.innEdrpou}</Text>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space>
                    <Text>Юр/Фіз особа: </Text>
                    <Text strong>
                      {order?.counterparty.isLegalEntity
                        ? "Юридична особа"
                        : "Фізична особа"}
                    </Text>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space>
                    <Text>Точка дсотавки: </Text>
                    <Text strong>{order?.outlet.name}</Text>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space>
                    <Text>Адреса: </Text>
                    <Text strong>{order?.outlet.address}</Text>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space>
                    <Text>Контактна особа: </Text>
                    <Text strong>{order?.outlet.contactPerson}</Text>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space>
                    <Text>Контактний телефон: </Text>
                    <Text strong>{order?.outlet.phone}</Text>
                  </Space>
                </Col>
              </Row>
            </Card>
            <Card
              style={{
                marginTop: "12px",
              }}
              title="Доставка"
            >
              <Row>
                <Col span={12}>
                  <Space>
                    <Text>Тип: </Text>
                    <Text strong>{order?.delivery.type}</Text>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space>
                    <Text>Адреса: </Text>
                    <Text strong>{order?.delivery.address}</Text>
                  </Space>
                </Col>
              </Row>
            </Card>
            <Card
              style={{
                marginTop: "12px",
              }}
              title="Товари"
            >
              {order && order.orderItems.length > 0 && (
                <Table
                  dataSource={order?.orderItems}
                  rowKey={"name"}
                  footer={(s) => {
                    return (
                      <Row>
                        <Col span={24}>
                          <span>Всього найменувань </span>
                          <span>
                            <b>{order?.orderItems.length}</b>,
                          </span>
                          <span> на сумму </span>
                          <span>
                            <b>{order?.sum} грн.</b>
                          </span>
                        </Col>
                      </Row>
                    );
                  }}
                >
                  {/* <Column<ICartItem>
                title={"Зображення"}
                key="image"
                dataIndex={"image"}
                render={(value, record) => {
                  let media = record.media.find(
                    (e) => e.collectionName === "main"
                  );
                  if (!media && record.media.length > 0) {
                    media = record.media[0];
                  }
                  if (!media) {
                    return <></>;
                  }
                  return (
                    <ImageField
                      preview={false}
                      value={`${process.env.REACT_APP_IMAGE_PATH}${media?.small}`}
                      height={64}
                    />
                  );
                }}
              /> */}
                  <Column<IOrderItem>
                    title={"Назва"}
                    key="productString"
                    dataIndex={"productString"}
                    render={(value, record) => {
                      return <TextField value={capitalizeFirstLetter(value)} />;
                    }}
                  />{" "}
                  <Column<IOrderItem>
                    title={"Ціна"}
                    key="price"
                    dataIndex={"price"}
                    render={(value, record) => {
                      const priceField = () => {
                        if (record.discount !== 0) {
                          return (
                            <Row>
                              <Col span={24}>
                                <del className="old-price">
                                  {record.price} <span>грн.</span>
                                </del>
                              </Col>
                              <Col span={24}>
                                <div className="price-discount">
                                  {(
                                    record.price -
                                    record.discount / record.quantity
                                  ).toFixed(2)}{" "}
                                  <span>грн.</span>
                                </div>
                              </Col>
                            </Row>
                          );
                        }
                        return (
                          <TextField
                            className="price"
                            value={`${record.price} грн.`}
                          />
                        );
                      };
                      return priceField();
                    }}
                  />{" "}
                  <Column<IOrderItem>
                    title={"Кількість"}
                    key="quantity"
                    dataIndex={"quantity"}
                    render={(value, record) => {
                      return <TextField value={record.quantity} />;
                    }}
                  />
                  <Column<IOrderItem>
                    title={"Сума"}
                    key="sum"
                    dataIndex={"sum"}
                    render={(value, record) => {
                      return (
                        <TextField
                          value={`${record.totalSum} грн.`}
                          className="price"
                        />
                      );
                    }}
                  />
                </Table>
              )}
            </Card>
          </Card>
        </Col>

        <Col lg={6} sm={24} className="right-side">
          <Card
            className="sticky"
            title="Загальна інформація"
            actions={[
              <Row align={"middle"} justify={"start"}>
                <Col span={24}>
                  <Space>
                    <Text strong>Загальна сума: </Text>
                    <Text strong type="danger">
                      {order?.totalSum} грн.
                    </Text>
                  </Space>
                </Col>
              </Row>,
            ]}
          >
            <Col>
              <Space>
                <Text>Контрагент: </Text>
                <Text strong>{order?.counterparty.name}</Text>
              </Space>
            </Col>
            <Col>
              <Space>
                <Text>Точка дсотавки: </Text>
                <Text strong>{order?.outlet.name}</Text>
              </Space>
            </Col>
            <Col>
              <Space>
                <Text>Тип доставки: </Text>
                <Text strong>{order?.delivery.type}</Text>
              </Space>
            </Col>
            <Col>
              <Space>
                <Text
                  style={{
                    minWidth: "100px",
                  }}
                >
                  Адреса:
                </Text>
                <Text strong>{order?.delivery.address}</Text>
              </Space>
            </Col>
            <Col>
              <Space>
                <Text>Всього найменувань: </Text>
                <Text strong>{order?.orderItems.length}</Text>
              </Space>
            </Col>
            <Col>
              <Space>
                <Text>Кількість товарів: </Text>
                <Text strong>
                  {order?.orderItems &&
                    order?.orderItems.reduce((s, a) => s + a.quantity, 0)}
                </Text>
              </Space>
            </Col>
            <Col>
              <Space>
                <Text>Сума без знижки: </Text>
                <Text strong>{order?.sum} грн.</Text>
              </Space>
            </Col>
            <Col>
              <Space>
                <Text>Сума знижки: </Text>
                <Text strong>{order?.totalDiscount} грн.</Text>
              </Space>
            </Col>

            <Col
              style={{
                marginTop: "8px",
              }}
            >
              <Space>
                <Text>Коментар: </Text>
                <Text strong>{order?.comment}</Text>
              </Space>
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
};
