import {
  DateField,
  getDefaultSortOrder,
  List,
  Table,
  Tag,
  TextField,
  useTable,
} from "@pankod/refine-antd";
import { CrudFilters, HttpError, useNavigation } from "@pankod/refine-core";
import { useCanAccessByRole, useGetCOParams } from "hooks";
import { FC, useEffect, useState } from "react";
import { IOrder } from "types";

interface IOrdersListProps {
  all?: boolean;
}

export const OrdersList: FC<IOrdersListProps> = ({ all }) => {
  const { hasCO, counterpartyId, outletId } = useGetCOParams();
  const { show } = useNavigation();
  const canAccess = useCanAccessByRole(["buyer"]);

  const [permanentFilter, setPermanentFilter] = useState<CrudFilters>(
    all
      ? [
          {
            field: "c",
            operator: "eq",
            value: counterpartyId,
          },
          {
            field: "o",
            operator: "eq",
            value: outletId,
          },
          {
            field: "all",
            operator: "eq",
            value: true,
          },
        ]
      : [
          {
            field: "c",
            operator: "eq",
            value: counterpartyId,
          },
          {
            field: "o",
            operator: "eq",
            value: outletId,
          },
        ]
  );

  useEffect(() => {
    if (hasCO) {
      setPermanentFilter(
        all
          ? [
              {
                field: "c",
                operator: "eq",
                value: counterpartyId,
              },
              {
                field: "o",
                operator: "eq",
                value: outletId,
              },
              {
                field: "all",
                operator: "eq",
                value: true,
              },
            ]
          : [
              {
                field: "c",
                operator: "eq",
                value: counterpartyId,
              },
              {
                field: "o",
                operator: "eq",
                value: outletId,
              },
            ]
      );
    }
  }, [counterpartyId, outletId, all]);
  const { tableProps, searchFormProps, sorter } = useTable<
    IOrder,
    HttpError,
    IOrder
  >({
    resource: "b2b/order",
    queryOptions: {
      enabled: hasCO && canAccess,
    },

    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],

    permanentFilter: permanentFilter,
    onSearch: (params) => {
      const filters: CrudFilters = [];

      return filters;
    },
  });

  return (
    <Table
      {...tableProps}
      rowKey="id"
      onRow={(data) => {
        return {
          style: {
            cursor: "pointer ",
          },
          onClick: () => show(`orders`, data.id),
        };
      }}
    >
      <Table.Column
        dataIndex="id"
        title="ID"
        defaultSortOrder={getDefaultSortOrder("id", sorter)}
        sorter
      />
      <Table.Column<IOrder>
        dataIndex="partner"
        filters={[]} //TODO: add filter by user
        onFilter={(val, rec) => {
          return (
            (rec.partner?.name.includes(`${val}`) ||
              rec.partner?.firstName.includes(`${val}`)) ??
            false
          );
        }}
        filterMode="menu"
        filterSearch={true}
        title="Користувач"
        render={(value, record) => {
          return <TextField value={`${value.name} ${value.firstName}`} />;
        }}
      />
      <Table.Column
        dataIndex="sum"
        title="Сума"
        render={(value) => <TextField value={`${value.toFixed(2)} грн.`} />}
      />
      <Table.Column
        dataIndex="totalDiscount"
        title="Знижка"
        defaultSortOrder={getDefaultSortOrder("totalDiscount", sorter)}
        render={(value) => <TextField value={`${value.toFixed(2)} грн.`} />}
        sorter
      />
      <Table.Column
        dataIndex="totalSum"
        title="Загальна сума"
        defaultSortOrder={getDefaultSortOrder("totalSum", sorter)}
        sorter
        render={(value) => <TextField value={`${value.toFixed(2)} грн.`} />}
      />
      <Table.Column<IOrder>
        dataIndex="status"
        defaultSortOrder={getDefaultSortOrder("status", sorter)}
        title="Статус"
        render={(value, record) => {
          let color;
          switch (record.status.guid) {
            case "new":
              color = "blue";
              break;
            case "inProgress":
              color = "yellow";
              break;
            case "completed":
              color = "blue";
              break;
            case "canceled":
              color = "red";
              break;
            default:
              color = "";
              break;
          }
          return <Tag color={color}>{value.name}</Tag>;
        }}
      />
      <Table.Column<IOrder>
        dataIndex="payment"
        title="Оплата"
        render={(value, record) => {
          let color;
          switch (record.payment.guid) {
            case "bank":
              color = "cyan";
              break;
            case "COD":
              color = "yellow";
              break;
            case "online":
              color = "blue";
              break;
            default:
              color = "";
              break;
          }
          return <Tag color={color}>{value.name}</Tag>;
        }}
      />
      <Table.Column<IOrder>
        dataIndex="isPaid"
        title="Оплачено"
        render={(value, record) => {
          return (
            <Tag color={value ? "green" : "red"}>{value ? "Так" : "Ні"}</Tag>
          );
        }}
      />{" "}
      <Table.Column<IOrder>
        dataIndex="delivery"
        title="Доставка"
        render={(value, record) => {
          let color;
          switch (record.delivery.guid) {
            case "novaposhta":
              color = "cyan";
              break;
            case "justin":
              color = "yellow";
              break;
            case "pickup":
              color = "blue";
              break;
            case "target":
              color = "pink";
              break;
            default:
              color = "";
              break;
          }
          return <Tag color={color}>{value.type}</Tag>;
        }}
      />{" "}
      <Table.Column<IOrder>
        dataIndex="counterparty"
        title="Контрагент"
        render={(value, record) => {
          return <TextField value={`${value.name}`} />;
        }}
      />
      <Table.Column<IOrder>
        dataIndex="outlet"
        title="Точка доставки"
        render={(value, record) => {
          return <TextField value={`${value.name}`} />;
        }}
      />
      <Table.Column
        dataIndex="createdAt"
        title="Створений"
        render={(value) => (
          <DateField format="DD-MM-YYYY HH:mm:ss" value={value} />
        )}
        defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
        sorter
      />
    </Table>
  );
};
