import { useModalForm, useSelect } from "@pankod/refine-antd";
import { useLocation } from "@pankod/refine-react-router-v6";
import {
  CartDrawer,
  CounterpartyCreateModal,
  CounterpartySelectModal,
  OutletCreateModal,
  OutletSelectModal,
} from "components/modals";
import { useGetCOParams, useGetCounterparty } from "hooks";
import { debounce } from "lodash";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCounterpartyId, getOutletId } from "store/slices/appSlice";
import {
  closeCounterpartyModal,
  closeOutletModal,
  hasOpenCounterparty,
  hasOpenOutlet,
  openCounterpartyModal,
  openOutletModal,
} from "store/slices/modalsSlice";
import { AppState } from "store/store";
import { ICounterparty, IOutlet } from "types";
export const Footer: React.FC = () => {
  const location = useLocation();
  const selectCounterpartyModal = useSelector<AppState, boolean>(
    hasOpenCounterparty
  );
  const selectOutletModal = useSelector<AppState, boolean>(hasOpenOutlet);
  const dispatch = useDispatch();

  const { counterpartyId, outletId } = useGetCOParams();

  const { refetch } = useGetCounterparty();

  const {
    selectProps: counterpartySelectProps,
    queryResult: counterpartyQueryResult,
  } = useSelect({
    optionValue: "id",
    optionLabel: "name",
    resource: "client",

    defaultValue: counterpartyId,
  });

  const { selectProps: outletSelectProps, queryResult: outletQueryResult } =
    useSelect({
      optionValue: "id",
      optionLabel: "name",
      resource: "outlet",

      queryOptions: {
        enabled: counterpartyId !== undefined,
      },
      filters: [{ field: "ownerId", operator: "eq", value: counterpartyId }],
    });

  const {
    modalProps: createCounterpartyModalProps,
    formProps: createCounterpartyFormProps,
    show: createCounterpartyModalShow,
    open: counterpartyModalOpen,
  } = useModalForm<ICounterparty>({
    action: "create",
    resource: "client",
    redirect: false,
  });

  const {
    modalProps: createOutletModalProps,
    formProps: createOutletFormProps,
    show: createOutletModalShow,
  } = useModalForm<IOutlet>({
    action: "create",
    resource: "outlet",
    redirect: false,
    onMutationSuccess(data, variables, context) {
      refetch();
    },
  });

  const checkCounterparty = useCallback(
    debounce((cId, queryRes) => {
      if (
        !cId &&
        queryRes.isSuccess &&
        queryRes.data &&
        queryRes.data.total === 0
      ) {
        createCounterpartyModalShow();
      } else if (!cId && queryRes.isSuccess) {
        dispatch(openCounterpartyModal());
      }
    }, 300),
    []
  );
  const checkOutlets = async () => {
    if (
      !outletId &&
      counterpartyId &&
      outletQueryResult.isSuccess &&
      outletSelectProps.options &&
      outletSelectProps.options.length === 0
    ) {
      createOutletModalShow();
    } else if (
      !outletId &&
      counterpartyId !== undefined &&
      outletQueryResult.isSuccess &&
      outletSelectProps.options?.length !== 0
    ) {
      dispatch(openOutletModal());
    }
  };

  useEffect(() => {
    checkCounterparty(counterpartyId, counterpartyQueryResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterpartyId, counterpartyQueryResult.data]);

  useEffect(() => {
    checkOutlets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outletId, outletSelectProps.options]);

  useEffect(() => {
    checkCounterparty(counterpartyId, counterpartyQueryResult);
    checkOutlets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <CounterpartyCreateModal
        createModalProps={createCounterpartyModalProps}
        createFormProps={createCounterpartyFormProps}
      />
      <OutletCreateModal
        createModalProps={createOutletModalProps}
        createFormProps={createOutletFormProps}
      />
      <CounterpartySelectModal
        open={selectCounterpartyModal}
        onOk={() => dispatch(closeCounterpartyModal())}
        onCancel={() => dispatch(closeCounterpartyModal())}
        counterpartySelectProps={counterpartySelectProps}
      />
      <OutletSelectModal
        selectKey={`${counterpartyId ?? "emptyOutlets"}`}
        open={selectOutletModal}
        outletSelectProps={outletSelectProps}
        onOk={() => dispatch(closeOutletModal())}
        onCancel={() => dispatch(closeOutletModal())}
      />
      <CartDrawer />
    </>
  );
};
