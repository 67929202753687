import { useList } from "@pankod/refine-core";
import { TOKEN_KEY } from "authProvider";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductToWishlistGroupItems,
  addWishlistGroupItems,
  getCountProductsInGroups,
  getWishlistGroups,
  getWishlistGroupsItems,
  removeProductFromWishlistGroupItems,
  setWishlistGroup,
} from "store/slices/wishlistSlice";
import { IListResponse, IProduct, IWishlistGroup } from "types";
import { useGetCOParams } from "./useGetCOParams";

export const useWishlists = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem(TOKEN_KEY);
  const { hasCO, counterpartyId, outletId } = useGetCOParams();
  const wishlistGroups = useSelector(getWishlistGroups);
  const wishlistGroupsItems = useSelector(getWishlistGroupsItems);
  const countProducts = useSelector(getCountProductsInGroups);
  const [totalGroups, setTotalGroups] = useState<number>(0);
  const [canUpdate, setCanUpdate] = useState<boolean>(false);

  const getProductsByGroup = (groupId: number) => {
    return (
      wishlistGroupsItems.find((e) => e.groupId === groupId)?.products ?? []
    );
  };

  const hasProductInWishlist = (productId: number, groupId?: number) => {
    if (!groupId) {
      let hasItem = false;
      for (const wishlistGroup of wishlistGroups) {
        if (
          getProductsByGroup(wishlistGroup.id).find((e) => e.id === productId)
        ) {
          hasItem = true;
          break;
        }
      }

      return hasItem;
    } else {
      return (
        wishlistGroupsItems
          .find((e) => e.groupId === groupId)
          ?.products.find((e) => e.id === productId) !== undefined ?? false
      );
    }
  };
  const mutateAddProductToGroup = async (
    groupId: number,
    product: IProduct
  ) => {
    try {
      const { data, status } = await axios.post(
        `${process.env.REACT_APP_API_URL}/partner/utils/wishlist/${groupId}`,

        {
          productId: product.id,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        dispatch(
          addProductToWishlistGroupItems({
            product: product,
            groupId: groupId,
          })
        );
      }
    } catch (e) {}
  };
  const mutateDeleteProductFromGroup = async (
    groupId: number,
    product: IProduct
  ) => {
    try {
      const { data, status } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/partner/utils/wishlist/${groupId}`,

        {
          data: {
            productId: product.id,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        dispatch(
          removeProductFromWishlistGroupItems({
            product: product,
            groupId: groupId,
          })
        );
      }
    } catch (e) {}
  };

  const changeProductToGroup = (product: IProduct, groupId?: number) => {
    if (groupId) {
      const wishlistGroup = wishlistGroups.find((e) => e.id === groupId);
      if (wishlistGroup) {
        if (hasProductInWishlist(product.id, wishlistGroup.id)) {
          mutateDeleteProductFromGroup(wishlistGroup.id, product);
        } else {
          mutateAddProductToGroup(wishlistGroup.id, product);
        }
      }
      return;
    }

    const group = wishlistGroups.find((e) => e.isDefault);
    if (!group) return;
    if (hasProductInWishlist(product.id, group.id)) {
      mutateDeleteProductFromGroup(group.id, product);
    } else {
      mutateAddProductToGroup(group.id, product);
    }
  };

  const { data: groupsData, refetch } = useList<IWishlistGroup>({
    resource: "partner/utils/wishlist-groups",
    metaData: {
      all: true,
    },
  });

  const fetchGroupItems = async (groupId: number) => {
    try {
      const { data, status } = await axios.get<IListResponse<IProduct>>(
        `${process.env.REACT_APP_API_URL}/partner/utils/wishlist/${groupId}?c=${counterpartyId}&o=${outletId}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        return {
          groupId: groupId,
          products: data.rows,
        };
      }
    } catch (e) {}
    return {
      groupId: groupId,
      products: [],
    };
  };
  const updateGroupsItems = async (wishlistGroups: IWishlistGroup[]) => {
    for (const wishlistGroup of wishlistGroups) {
      if (
        wishlistGroupsItems.find((e) => e.groupId === wishlistGroup.id) !==
        undefined
      )
        continue;
      if (wishlistGroup.products !== 0) {
        fetchGroupItems(wishlistGroup.id).then((e) => {
          dispatch(addWishlistGroupItems(e));
        });
      } else {
        dispatch(
          addWishlistGroupItems({ groupId: wishlistGroup.id, products: [] })
        );
      }
    }
  };
  useEffect(() => {
    if (canUpdate) {
      updateGroupsItems(wishlistGroups);
    }
  }, [canUpdate]);

  useEffect(() => {
    if (hasCO && wishlistGroups.length > 0 && !canUpdate) {
      setCanUpdate(true);
    }
  }, [wishlistGroups, hasCO]);

  useEffect(() => {
    if (groupsData) {
      dispatch(setWishlistGroup(groupsData.data));
      setTotalGroups(groupsData.total);
    }
  }, [groupsData]);

  return {
    wishlistGroups: wishlistGroups,
    totalCountProducts: countProducts,
    totalGroups: totalGroups,
    refetchGroups: refetch,
    changeProductToGroup: changeProductToGroup,
    mutateAddProductToGroup: mutateAddProductToGroup,
    mutateDeleteProductFromGroup: mutateDeleteProductFromGroup,
    hasProductInWishlist: hasProductInWishlist,
    getProductsByGroup: getProductsByGroup,
  };
};
