import { Checkbox, Col, Input } from "@pankod/refine-antd";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addFilterProperty,
  removeFilterProperty,
} from "store/slices/catalogSlice";
import { IFilterProps, IPropertyValue } from "types";

const { Search } = Input;

export interface ISmartCheckboxFilterProps {
  ownerCode: string;
  ownerName: string;
  propsValues: IPropertyValue[];
  currentFilter: IFilterProps[];
  onChangeFilter?: () => void;
}
export const SmartCheckboxFilter: FC<ISmartCheckboxFilterProps> = ({
  ownerCode,
  ownerName,
  propsValues,
  currentFilter,
  onChangeFilter,
}) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState<IPropertyValue[]>(propsValues);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    if (searchValue.trim() !== "") {
      setValues(
        propsValues.filter((e) =>
          e.value.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      setValues(propsValues);
    }
  }, [searchValue]);

  const onCheckboxClick = (checked: boolean, value: IPropertyValue) => {
    if (checked) {
      dispatch(
        addFilterProperty({
          code: ownerCode,
          name: ownerName,
          value: value,
        })
      );
      onChangeFilter && onChangeFilter();
    } else {
      dispatch(
        removeFilterProperty({
          code: ownerCode,
          value: value,
        })
      );
      onChangeFilter && onChangeFilter();
    }
  };

  const getTitle = (strTitle: string) => {
    const index = strTitle.toLowerCase().indexOf(searchValue.toLowerCase());
    const beforeStr = strTitle.substring(0, index);
    const searchStr = strTitle.substring(index, searchValue.length + index);
    const afterStr = strTitle.slice(index + searchValue.length);
    if (index > -1) {
      return (
        <span>
          {beforeStr}
          <span
            style={{
              color: "#f50",
            }}
          >
            {searchStr}
          </span>
          {afterStr}
        </span>
      );
    } else {
      return <span>{strTitle}</span>;
    }
  };

  return (
    <>
      {propsValues.length > 8 ? (
        <Col span={24} key={`search-${ownerCode}`}>
          <Search
            style={{ marginBottom: 8 }}
            placeholder={`Пошук в ${ownerName}`}
            onChange={(e) => {
              const val = e.target.value;
              setSearchValue(val);
            }}
          />
        </Col>
      ) : null}

      {values.map((propValue) => {
        const s = currentFilter.find((e) => e.code === ownerCode);
        return (
          <Col span={24} key={propValue.id}>
            <Checkbox
              checked={
                s && s.values.find((e) => e.id === propValue.id) !== undefined
              }
              onChange={async (e) => {
                onCheckboxClick(e.target.checked, propValue);
              }}
            >
              {getTitle(propValue.value)}
            </Checkbox>
          </Col>
        );
      })}
    </>
  );
};
