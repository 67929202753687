import { AuthProvider } from "@pankod/refine-core";
import { IRegisterProps } from "components/auth";
import axios, { AxiosError, isAxiosError } from "axios";
import { IRegisterResponse, IResponseError, IJWTData } from "types";
import jwtDecode from "jwt-decode";

export const TOKEN_KEY = "auth_token";
const API_URL = process.env.REACT_APP_API_URL;

export const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    try {
      const { data, status } = await axios.post<IRegisterResponse>(
        `${API_URL}/partner/login`,
        {
          email,
          password,
        }
      );

      if (status === 200 && data.accessToken) {
        const token = data.accessToken;
        localStorage.setItem(TOKEN_KEY, token);
        return Promise.resolve();
      }

      return Promise.reject({
        message: "Register ops!",
        name: "Помилка авторизації",
      });
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IResponseError>;
        return Promise.reject({
          message: error.response?.data.message,
          name:
            error.response?.data.errors.length != 0
              ? error.response?.data.errors.map((e) => e.msg).join(";\n")
              : "Помилка авторизації",
        });
      }

      return Promise.reject({
        name: "Помилка авторизації",
        message: e,
      });
    }
  },
  register: async (params: IRegisterProps) => {
    try {
      const { data, status } = await axios.post<IRegisterResponse>(
        `${API_URL}/partner/registration`,
        params
      );

      if (status === 200 && data.accessToken) {
        const token = data.accessToken;
        localStorage.setItem(TOKEN_KEY, token);
        return Promise.resolve();
      }

      return Promise.reject({
        message: "Register ops!",
        name: "Помилка реєстрації",
      });
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IResponseError>;
        return Promise.reject({
          message: error.response?.data.message,
          name: error.response?.data.errors.map((e) => e.msg).join(";\n"),
        });
      }

      return Promise.reject({
        name: "Помилка реєстрації",
        message: e,
      });
    }
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);

    if (token) {
      const userData = jwtDecode<IJWTData>(token);

      if (userData.exp) {
        const expired = new Date(userData.exp * 1000) <= new Date();
        if (expired) {
          return Promise.reject({
            name: "Auth expired",
          });
        }
        return Promise.resolve();
      }
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      const userData = jwtDecode<IJWTData>(token);

      if (userData.exp) {
        const expired = new Date(userData.exp * 1000) <= new Date();
        if (expired) {
          return Promise.reject({
            name: "Auth expired",
          });
        }
        return Promise.resolve({
          id: userData.id,
        });
      }
    }
    return Promise.reject({
      name: "Auth expired",
    });
  },
};
