import {
  Card,
  Col,
  Empty,
  List,
  Row,
  Table,
  TextField,
  Collapse,
  Select,
  TagField,
  ImageField,
  useTable,
  Badge,
  Typography,
} from "@pankod/refine-antd";
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  CrudFilters,
  IResourceComponentsProps,
  useList,
  useNavigation,
} from "@pankod/refine-core";
import { useTour } from "hooks";
import { FC, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCounterpartyId, getOutletId } from "store/slices/appSlice";
import { IPricesFilter, IProduct, IProperty } from "types";
import _ from "lodash";
import {
  AddProductToCart,
  CategoriesFilter,
  PricesFilter,
  ProductExpandDetails,
  PropertyFilter,
  WishlistButton,
} from "components/catalog";
import {
  getCategoryFilter,
  getCategoryEntity,
  getPricesFilter,
  getPropertyFilter,
  setPricesFilter,
  setCategoryFilter,
  removeFilterProperty,
  getSortFilter,
  setSorter,
} from "store/slices/catalogSlice";
import "./ProductPage.css";
import { capitalizeFirstLetter, inStock } from "components/helper";
import { Helmet } from "react-helmet";

const { Column } = Table;
const { Panel } = Collapse;

export const ProductsPage: FC<IResourceComponentsProps> = () => {
  const refAboutThisPage = useRef(null);
  const refLeftColumnFilter = useRef(null);
  const refLeftColumnSearch = useRef(null);
  const refHeaderFiltered = useRef(null);
  const refHeaderSort = useRef(null);
  const refProductAddToWishlist = useRef(null);
  const refProductAddToCart = useRef(null);
  const { tourElement, setOpen } = useTour({
    steps: [
      {
        target: () => refAboutThisPage.current!,
        placement: "bottom",
        cover: (
          <img alt="tour.png" src={`/images/tour/product_list_main.jpeg`} />
        ),
        title: <div>Список товарів</div>,
        description: (
          <div>
            <p>
              Головний приорітет порталу - швидкість та зручність при оформлені
              замовлень
            </p>
            <p>
              <QuestionCircleOutlined style={{ color: "#08c" }} /> На цій
              сторінці Ви можете:
              <ul>
                <li>Ознайомитись з асортиментом</li>
                <li>Подивитись детальну інформацію про товар</li>
                <li>Додати його в списки обраних товарів</li>
                <li>Додати товар в кошик вибраної торгової точки</li>
              </ul>
            </p>
            <p>
              <ExclamationCircleOutlined style={{ color: "#006400" }} /> Для
              того, щоб завершити роботу помічника достроково натисніть клавішу
              ESC
            </p>
          </div>
        ),
      },
      {
        target: () => refLeftColumnFilter.current!,
        placement: "right",
        title: <div>Швидкі фільтри товарів і категорій</div>,
        description: (
          <div>
            <p>
              Головна панель всіх доступних фільтрів для вибраної категорії
              товарів
            </p>
            <p>
              <QuestionCircleOutlined style={{ color: "#08c" }} /> Доступні
              фільтри:
              <ul>
                <li>Категорії товарів</li>
                <li>Діапазон цін</li>
                <li>Всі властивості товарів</li>
              </ul>
            </p>
            <p>
              <WarningOutlined style={{ color: "#8B0000" }} /> Набір фільтрів
              змінюється в залежності від обраної категорії
            </p>
          </div>
        ),
      },
      {
        target: () => refLeftColumnSearch.current!,
        placement: "right",
        title: <div>Фільтр швидкого пошуку категорії товару</div>,
        description: (
          <div>
            Введіть назву категорії товарів і натисніть іконку пошуку - дерево
            категорій розвернеться до знайдених категорій
          </div>
        ),
      },
      {
        target: () => refHeaderFiltered.current!,
        placement: "bottom",
        title: <div>В цій панелі відображаються застосовані фільтри</div>,
        description: (
          <div>
            <ExclamationCircleOutlined style={{ color: "#006400" }} /> Ви можете
            швидко скинути деякі фільтри
          </div>
        ),
      },
      {
        target: () => refHeaderSort.current!,
        placement: "left",
        title: <div>Швидке сортування за вибраним параметром</div>,
      },
      {
        target: () => refProductAddToWishlist.current!,
        placement: "bottom",
        title: <div>Додати товар до списка обраних товарів</div>,
      },
      {
        target: () => refProductAddToCart.current!,
        placement: "right",
        title: <div>Обрати потрібну кількість і додати товар до кошика</div>,
        description: (
          <div>
            <WarningOutlined style={{ color: "#8B0000" }} /> Товар додається до
            кошика вибраної точки доставки
          </div>
        ),
      },
    ],
    initOpen: false,
  });
  const dispatch = useDispatch();
  const { show } = useNavigation();
  const counterpartyId = useSelector(getCounterpartyId);
  const outletId = useSelector(getOutletId);
  const categoryFilter = useSelector(getCategoryFilter);
  const propertyFilter = useSelector(getPropertyFilter);
  const pricesFilter = useSelector(getPricesFilter);
  const categoryEntity = useSelector(getCategoryEntity);
  const sortBy = useSelector(getSortFilter);

  const [permanentFilter, setPermanentFilter] = useState<CrudFilters>([
    { field: "c", operator: "eq", value: counterpartyId },
    { field: "o", operator: "eq", value: outletId },
  ]);

  const sortOptions = [
    {
      label: "За рейтингом",
      value: "rating",
    },
    {
      label: "За назвою",
      value: "name",
    },
    {
      label: "Від дешевших до дорожчих",
      value: "price",
    },
    {
      label: "Від дорожчих до дешевших",
      value: "priceDesc",
    },
    {
      label: "Новинки",
      value: "new",
    },
  ];
  const [minMaxPrices, setMinMaxPrices] = useState<IPricesFilter>({
    minPrice: 0,
    maxPrice: 0,
  });
  const [properties, setProperties] = useState<IProperty[]>([]);

  const parseFilter = (returnValue: boolean = false) => {
    const additionalFilters: CrudFilters = [];
    if (categoryFilter) {
      additionalFilters.push({
        field: "catalogId",
        operator: "eq",
        value: categoryFilter,
      });
    }

    if (propertyFilter && propertyFilter.length > 0) {
      const filterStr = propertyFilter
        .map(
          (prop) =>
            `${prop.code}:${prop.values.map((v) => `${v.id}`).join(",")}`
        )
        .join(`|`);
      additionalFilters.push({
        field: "filters",
        operator: "eq",
        value: filterStr,
      });
    }
    if (
      (pricesFilter.maxPrice !== minMaxPrices.maxPrice &&
        pricesFilter.maxPrice !== 0) ||
      (pricesFilter.minPrice !== minMaxPrices.minPrice &&
        pricesFilter.minPrice !== 0)
    ) {
      additionalFilters.push({
        field: "minPrice",
        operator: "eq",
        value: pricesFilter.minPrice,
      });
      additionalFilters.push({
        field: "maxPrice",
        operator: "eq",
        value: pricesFilter.maxPrice,
      });
    }

    if (sortBy !== "rating") {
      additionalFilters.push({
        field: "order",
        operator: "eq",
        value: sortBy,
      });
    }

    if (returnValue) {
      return additionalFilters;
    } else {
      setFilters(additionalFilters, "replace");
    }
  };

  const [initialFilter, setInitialFilter] = useState(parseFilter(true));

  const { tableProps, setFilters, setCurrent, tableQueryResult } =
    useTable<IProduct>({
      resource: "product",
      initialPageSize: 15,
      queryOptions: {
        enabled: outletId !== undefined && counterpartyId !== undefined,
      },
      metaData: {
        resultWithPrice: true,
      },
      initialFilter: initialFilter,
      permanentFilter: permanentFilter,
    });

  const { data: propertiesData, isSuccess } = useList<IProperty>({
    resource: `${"catalog_b2b/properties"}${
      categoryFilter ? "/" + categoryFilter : ""
    }`,
    metaData: {
      all: true,
      withoutCount: true,
    },
  });

  useEffect(() => {
    if (propertiesData && propertiesData.data.length > 0 && isSuccess) {
      setProperties(propertiesData.data);
    } else if (propertiesData && isSuccess) {
      setProperties([]);
    }
  }, [propertiesData, isSuccess]);

  useEffect(() => {
    if (outletId !== undefined && counterpartyId !== undefined) {
      setPermanentFilter([
        { field: "c", operator: "eq", value: counterpartyId },
        { field: "o", operator: "eq", value: outletId },
      ]);
      tableQueryResult.refetch();
    }
  }, [outletId, counterpartyId]);

  useEffect(() => {
    if (
      minMaxPrices.maxPrice !== tableQueryResult.data?.maxPrice ||
      minMaxPrices.minPrice !== tableQueryResult.data?.minPrice
    ) {
      setMinMaxPrices({
        maxPrice: tableQueryResult.data?.maxPrice ?? 0,
        minPrice: tableQueryResult.data?.minPrice ?? 0,
      });
      if (!(pricesFilter.maxPrice !== 0 || pricesFilter.minPrice !== 0)) {
        dispatch(
          setPricesFilter({
            maxPrice: tableQueryResult.data?.maxPrice ?? 0,
            minPrice: tableQueryResult.data?.minPrice ?? 0,
          })
        );
      }
    }
  }, [tableQueryResult.data]);

  useEffect(() => {
    if (initialFilter && initialFilter.length > 0) {
      setInitialFilter(undefined);
    } else {
      parseFilter();
    }
  }, [categoryFilter, propertyFilter, pricesFilter, sortBy]);

  if (!counterpartyId || !outletId) {
    return (
      <List>
        <Empty />
      </List>
    );
  }
  return (
    <>
      <Helmet>
        <title>Список товарів | Офіс Центр</title>
      </Helmet>
      <Row gutter={[16, 16]}>
        <Col lg={5} xs={24}>
          <Card
            title={
              <div ref={refLeftColumnFilter}>
                Фільтр{" "}
                <QuestionCircleOutlined
                  style={{ fontSize: "20px", color: "#08c" }}
                  onClick={() => setOpen(true)}
                  alt="Помічник"
                  title="Запустити помічника на цій сторінці"
                />
              </div>
            }
            bodyStyle={{
              padding: "12px",
            }}
          >
            <Collapse
              bordered={false}
              className="collapse"
              defaultActiveKey={["categories"]}
            >
              <Panel header={"Категорії"} key={"categories"}>
                <CategoriesFilter
                  ref1={refLeftColumnSearch}
                  onSelect={() => setCurrent(1)}
                />
              </Panel>
            </Collapse>
            <Collapse
              bordered={false}
              className="collapse"
              defaultActiveKey={["prices"]}
            >
              <Panel header={"Ціна"} key={"prices"}>
                <PricesFilter
                  minMaxPrices={minMaxPrices}
                  onChangeFilter={() => setCurrent(1)}
                />
              </Panel>
            </Collapse>
            <PropertyFilter
              properties={properties}
              onChangeFilter={() => setCurrent(1)}
            />
          </Card>
        </Col>
        <Col lg={19} xs={24}>
          <Card>
            <Row
              style={{
                alignItems: "center",
              }}
            >
              <Col span={19} ref={refHeaderFiltered}>
                <Row wrap justify={"start"} align={"middle"} gutter={[0, 8]}>
                  {categoryFilter && categoryEntity && (
                    <TagField
                      closable
                      color={"blue"}
                      onClose={() => {
                        dispatch(setCategoryFilter(null));
                      }}
                      value={`Категорія: ${categoryEntity.name}`}
                    />
                  )}
                  {(pricesFilter.maxPrice !== minMaxPrices.maxPrice ||
                    pricesFilter.minPrice !== minMaxPrices.minPrice) &&
                  pricesFilter.maxPrice !== 0 &&
                  pricesFilter.minPrice !== 0 ? (
                    <TagField
                      closable
                      color={"red"}
                      onClose={() => {
                        dispatch(
                          setPricesFilter({
                            maxPrice: tableQueryResult.data?.maxPrice ?? 0,
                            minPrice: tableQueryResult.data?.minPrice ?? 0,
                          })
                        );
                      }}
                      value={`Ціна: ${pricesFilter.minPrice} - ${pricesFilter.maxPrice}`}
                    />
                  ) : null}
                  {propertyFilter &&
                    propertyFilter.length > 0 &&
                    propertyFilter.map((e) => {
                      const property = properties.find(
                        (s) => s.code === e.code
                      );
                      if (!property) return null;
                      return e.values.map((d) => {
                        const value = property.propertyValues.find(
                          (s) => s.id === d.id
                        );
                        if (!value) return null;
                        return (
                          <TagField
                            key={`${e.code}:${d.id}`}
                            closable
                            color={"purple"}
                            onClose={() => {
                              dispatch(
                                removeFilterProperty({
                                  code: property.code,
                                  value: value,
                                })
                              );
                            }}
                            value={`${property.name}: ${value?.value}`}
                          />
                        );
                      });
                    })}
                </Row>
              </Col>
              <Col span={5} ref={refHeaderSort}>
                <Select
                  key={"sorter"}
                  style={{
                    width: "100%",
                  }}
                  onChange={(val) => {
                    dispatch(setSorter(val as any));
                    setCurrent(1);
                  }}
                  value={sortOptions.find((e) => e.value === sortBy)}
                  options={sortOptions}
                />
              </Col>
            </Row>
          </Card>
          <List title="">
            <Table
              {...tableProps}
              size={"large"}
              rowKey="id"
              className="product-table"
              rowClassName="product-row"
              onRow={(data, i) => {
                return {
                  style: {
                    filter:
                      data.prices.length === 0 ? "grayscale(100%)" : "none",
                    backgroundColor:
                      data.prices.length === 0 ? "#fafafa" : "white",
                  },
                };
              }}
              expandable={{
                //expandRowByClick: true,
                expandedRowClassName: () => "product-tr",
                expandedRowRender: (record: IProduct) => {
                  return <ProductExpandDetails record={record} />;
                },
              }}
            >
              <Column<IProduct>
                dataIndex="vendor"
                title="Код"
                width={160}
                render={(value, record, i) => {
                  return (
                    <Row align={"middle"}>
                      <span
                        style={{
                          marginRight: "8px",
                        }}
                      >
                        {record.vendor}
                      </span>
                      <WishlistButton
                        ref1={i === 0 ? refProductAddToWishlist : undefined}
                        product={record}
                      />
                    </Row>
                  );
                }}
              />
              <Column<IProduct>
                dataIndex="media"
                width={160}
                align={"center"}
                title="Зображення"
                render={(value, record) => {
                  let media = record.media.find(
                    (e) => e.collectionName === "main"
                  );
                  if (!media && record.media.length > 0) {
                    media = record.media[0];
                  }
                  if (!media) {
                    return <></>;
                  }
                  return (
                    <ImageField
                      value={`${process.env.REACT_APP_IMAGE_PATH}${media.large}`}
                      height={64}
                      preview={{
                        mask: "Переглянути",
                      }}
                      style={{
                        objectFit: "contain",
                      }}
                    />
                  );
                }}
              />
              <Column<IProduct>
                dataIndex="name"
                title="Назва"
                render={(value, record) => (
                  <Typography.Text
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => show("products", record.id)}
                  >
                    {capitalizeFirstLetter(value)}
                  </Typography.Text>
                )}
              />{" "}
              {/* <Column
                dataIndex="balance"
                width={60}
                align={"center"}
                title="Доступна кількість"
              /> */}
              <Column<IProduct>
                dataIndex="prices"
                width={140}
                align={"center"}
                title="Ціна"
                render={(value, record) => {
                  if (record.discountPrice !== 0) {
                    return (
                      <Row>
                        <Col span={24}>
                          <del className="old-price">
                            {record.prices[0].price} <span>грн.</span>
                          </del>
                        </Col>
                        <Col span={24}>
                          <div className="price-discount">
                            {record.discountPrice} <span>грн.</span>
                          </div>
                        </Col>
                      </Row>
                    );
                  }
                  return (
                    <TextField
                      className="price"
                      value={`${record.prices[0].price} грн.`}
                    />
                  );
                }}
              />
              <Column<IProduct>
                dataIndex="action"
                key={"balance"}
                align={"center"}
                title="Дії"
                width={220}
                render={(value, record, i) => {
                  if(!record.isActual) {
                    return (
                      <TextField
                      style={{
                        color: "#fd1100",
                      }}
                      value={"Знято з продажу"}
                    />
                    );
                  }
                  if (inStock(record)) {
                    return (
                      <TextField
                        style={{
                          color: "#fd1100",
                        }}
                        value={"Нема в наявності"}
                      />
                    );
                  }
                  if (!record.balance) {
                    return (
                      <Badge.Ribbon
                        text={"Під замовлення"}
                        color="red"
                        className={"discount-ribbon"}
                      >
                        <AddProductToCart
                          key={`${record.id}`}
                          productId={record.id}
                          productName={record.name}
                          //ref1={refProductAddToCart}
                          ref1={i === 0 ? refProductAddToCart : undefined}
                        />
                      </Badge.Ribbon>
                    );
                  }
                  if (record.balance && record.balance <= 2) {
                    return (
                      <Badge.Ribbon
                        text={"Закінчується"}
                        color="yellow"
                        className={"discount-ribbon"}
                      >
                        <AddProductToCart
                          key={`${record.id}`}
                          productId={record.id}
                          productName={record.name}
                          //ref1={refProductAddToCart}
                          ref1={i === 0 ? refProductAddToCart : undefined}
                        />
                      </Badge.Ribbon>
                    );
                  }
                  if (record.discountPrice) {
                    return (
                      <Badge.Ribbon
                        text={"Знижка"}
                        color="red"
                        className={"discount-ribbon"}
                      >
                        <AddProductToCart
                          key={`${record.id}`}
                          productId={record.id}
                          productName={record.name}
                          //ref1={refProductAddToCart}
                          ref1={i === 0 ? refProductAddToCart : undefined}
                        />
                      </Badge.Ribbon>
                    );
                  }
                  return (
                    <AddProductToCart
                      key={`${record.id}`}
                      productId={record.id}
                      productName={record.name}
                      //ref1={refProductAddToCart}
                      ref1={i === 0 ? refProductAddToCart : undefined}
                    />
                  );
                }}
              />
            </Table>
          </List>
        </Col>
      </Row>
      {tourElement}
    </>
  );
};
