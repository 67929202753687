import {
  Col,
  Divider,
  Edit,
  Form,
  Input,
  Row,
  Typography,
  Spin,
  useForm,
  useSelect,
  Checkbox,
  Button,
  Select,
  SaveButton,
} from "@pankod/refine-antd";
import {
  QuestionCircleOutlined,
  WarningOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useTranslate } from "@pankod/refine-core";
import { useLocation } from "@pankod/refine-react-router-v6";
import { emailPattern, phonePattern } from "components/helper";
import { CounterpartyList, PersonalInfo } from "components/personal";
import { useGetCOParams, useIsHoldingOwner, useTour } from "hooks";
import { IPartner } from "types";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet";
import { useRef } from "react";

const { Title } = Typography;
export const EditPartnerPage = () => {
  const refPartnerMain = useRef(null);
  const refPartnerRolesCounterparty = useRef(null);
  const refPartnerRolesOutlets = useRef(null);
  const refPartnerSave = useRef(null);
  const { tourElement, setOpen } = useTour({
    steps: [
      {
        cover: (
          <img alt="profile_main.jpeg" src={`/images/tour/profile_main.jpeg`} />
        ),
        target: () => refPartnerMain.current!,
        placement: "bottom",
        title: <div>Редагування профілю користувача</div>,
        description: (
          <div>
            <p>
              Ця сторінка містить основну конактну інформацію про обліковий
              запис користувача на порталі
            </p>
            <p>
              Також тут налаштовуються права доступу до контрагентів та/або
              точок доставок
            </p>
            <p>
              <ExclamationCircleOutlined style={{ color: "#006400" }} /> Для
              того, щоб завершити роботу помічника достроково натисніть клавішу
              ESC
            </p>
          </div>
        ),
      },
      {
        target: () => refPartnerRolesCounterparty.current!,
        placement: "top",
        title: <div>Налаштування прав доступу до контрагентів</div>,
        description: (
          <div>
            <p>Ви можете надати доступ користувачу до будь-яких контрагентів</p>
            <p>
              <WarningOutlined style={{ color: "#8B0000" }} /> Якщо Ви надаєте
              користувачу доступ до контрагента, користувач буде бачити всі
              точки доставки цього контрагента
            </p>
          </div>
        ),
      },
      {
        target: () => refPartnerRolesOutlets.current!,
        placement: "top",
        title: <div>Налаштування прав доступу до точок доставки</div>,
        description: (
          <div>
            <p>
              Ви можете надати доступ користувачу до будь-яких торгових точок
            </p>
            <p>
              <WarningOutlined style={{ color: "#8B0000" }} /> Якщо Ви надали
              доступ до контрагента, користувач автоматично отримує права
              доступу до всіх точок доставки цього контрагента
            </p>
          </div>
        ),
      },
      {
        target: () => refPartnerSave.current!,
        placement: "topRight",
        title: (
          <div>Збережіть інформацію облікового запису після її редагування</div>
        ),
      },
    ],
    initOpen: false,
  });
  const t = useTranslate();
  const { pathname } = useLocation();
  const id = pathname.slice(pathname.lastIndexOf("/") + 1);

  const { counterpartyId, outletId, hasCO } = useGetCOParams();
  const isHolding = useIsHoldingOwner();

  const { formProps, queryResult, saveButtonProps, onFinish } =
    useForm<IPartner>({
      resource: "partner",
      id: id,
      action: "edit",
      onMutationSuccess(data, variables, context) {
        queryResult && queryResult.refetch();
      },
      redirect: false,
      metaData: {
        query: [
          {
            field: "c",
            operator: "eq",
            value: counterpartyId,
          },
          {
            field: "o",
            operator: "eq",
            value: outletId,
          },
        ],
      },
      queryOptions: {
        enabled: hasCO && isHolding && id !== "",
      },
    });
  const { selectProps: selectPropOutlets } = useSelect<IPartner>({
    resource: "outlet",
    optionLabel: "name",
    fetchSize: 20,
    optionValue: "id",
    metaData: {
      query: [
        {
          field: "c",
          operator: "eq",
          value: counterpartyId,
        },
        {
          field: "o",
          operator: "eq",
          value: outletId,
        },
      ],
    },
    queryOptions: {
      enabled: hasCO && isHolding && id !== "",
    },
    defaultValue: queryResult?.data?.data.rules.outlets.map((e) => e.id),
  });

  const { selectProps: selectPropClients } = useSelect<IPartner>({
    resource: "client",
    optionLabel: "name",
    fetchSize: 20,
    optionValue: "id",
    metaData: {
      query: [
        {
          field: "c",
          operator: "eq",
          value: counterpartyId,
        },
        {
          field: "o",
          operator: "eq",
          value: outletId,
        },
      ],
    },
    queryOptions: {
      enabled: hasCO && isHolding && id !== "",
    },
    defaultValue: queryResult?.data?.data.rules.counterparties.map((e) => e.id),
  });

  if (queryResult?.isLoading) {
    return (
      <Row justify={"center"} align={"middle"}>
        <Col
          style={{
            minHeight: "400px",
          }}
        >
          <Spin />
        </Col>
      </Row>
    );
  }

  if (queryResult?.isError) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>Редагування користувача | Офіс Центр</title>
      </Helmet>
      <Edit
        title={
          <div>
            {`Редагування ${queryResult?.data?.data.firstName} ${queryResult?.data?.data.name}`}{" "}
            <QuestionCircleOutlined onClick={() => setOpen(true)} />
          </div>
        }
        breadcrumb={[]}
        headerButtons={[]}
        footerButtons={
          <div ref={refPartnerSave}>
            <SaveButton {...saveButtonProps} />
          </div>
        }
      >
        <Form {...formProps}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                name={"name"}
                label={t("partner.fields.name")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"firstName"}
                label={t("partner.fields.firstName")}
                rules={[
                  {
                    required: true,
                    message: t("pages.register.errors.name"),
                  },
                  {
                    min: 3,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"email"}
                label={t("partner.fields.email")}
                rules={[
                  {
                    required: true,
                    message: t("pages.register.errors.validEmail"),
                  },
                  {
                    pattern: emailPattern,
                    message: t("pages.register.errors.validEmail"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"phone"}
                label={t("partner.fields.phone")}
                rules={[
                  {
                    required: true,
                    message: t("pages.register.errors.validPhone"),
                  },
                  {
                    pattern: phonePattern,
                    message: t("pages.register.errors.validPhone"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"newPassword"}
                label={t("partner.fields.newPassword")}
                rules={[
                  {
                    min: 8,
                    max: 32,

                    message: t("pages.register.errors.minMaxPassword"),
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder={t("partner.newPasswordPlaceholder")}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </Col>
            <Divider />
            <Col xs={24} lg={24}>
              <Title
                level={5}
                style={{
                  color: "#626262",
                }}
              >
                {t("partner.fields.counterparties")}
              </Title>

              <Form.List name={["rules", "counterparties"]}>
                {(fields, { add, remove }) => (
                  <>
                    <Row wrap>
                      {fields.map(({ key, name, ...restField }) => {
                        return (
                          <Col key={key} xs={24} lg={12}>
                            <Row
                              style={{
                                display: "flex",
                                width: "100%",
                                paddingLeft: "8px",
                                paddingRight: "8px",
                                justifyContent: "flex-end",
                                alignContent: "center",
                                alignItems: "baseline",
                              }}
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "id"]}
                                style={{ flex: 1 }}
                              >
                                <Select
                                  {...selectPropClients}
                                  placeholder={t(
                                    "partner.fields.counterparties"
                                  )}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                style={{
                                  paddingLeft: "8px",
                                  paddingRight: "8px",
                                }}
                                name={[name, "readOnly"]}
                                valuePropName="checked"
                              >
                                <Checkbox />
                              </Form.Item>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                                style={{
                                  marginLeft: "8px",
                                }}
                              />
                            </Row>
                          </Col>
                        );
                      })}
                    </Row>
                    <Form.Item>
                      <Button
                        type="dashed"
                        ref={refPartnerRolesCounterparty}
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        {`${t("partner.fields.counterparties")}`}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>{" "}
            <Divider />
            <Col xs={24} lg={24}>
              <Title
                level={5}
                style={{
                  color: "#626262",
                }}
              >
                {t("partner.fields.outlets")}
              </Title>

              <Form.List name={["rules", "outlets"]}>
                {(fields, { add, remove }) => (
                  <>
                    <Row wrap>
                      {fields.map(({ key, name, ...restField }) => {
                        return (
                          <Col key={key} xs={24} lg={12}>
                            <Row
                              style={{
                                display: "flex",
                                width: "100%",
                                paddingLeft: "8px",
                                paddingRight: "8px",
                                justifyContent: "flex-end",
                                alignContent: "center",
                                alignItems: "baseline",
                              }}
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "id"]}
                                style={{ flex: 1 }}
                              >
                                <Select
                                  {...selectPropOutlets}
                                  placeholder={t("partner.fields.outlets")}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                style={{
                                  paddingLeft: "8px",
                                  paddingRight: "8px",
                                }}
                                name={[name, "readOnly"]}
                                valuePropName="checked"
                              >
                                <Checkbox />
                              </Form.Item>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                                style={{
                                  marginLeft: "8px",
                                }}
                              />
                            </Row>
                          </Col>
                        );
                      })}
                    </Row>
                    <Form.Item>
                      <Button
                        type="dashed"
                        ref={refPartnerRolesOutlets}
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        {`${t("partner.fields.outlets")}`}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>{" "}
          </Row>
        </Form>
      </Edit>
      {tourElement}
    </>
  );
};
