import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { Key } from "react";
import { ICategory, IFilterProps, IPricesFilter, IPropertyValue } from "types";
import { AppState } from "../store";

export interface ICatalogState {
  categories: ICategory[];
  categoryFilter: Key | null;
  propertyFilter: IFilterProps[];
  pricesFilter: IPricesFilter;
  sortBy: string;
}
const initialState: ICatalogState = {
  categories: [],
  categoryFilter: null,
  propertyFilter: [],
  pricesFilter: {
    maxPrice: 0,
    minPrice: 0,
  },
  sortBy: "rating",
};
const catalogSlice = createSlice({
  initialState,
  name: "catalog",
  reducers: {
    setCategoriesState: (state, { payload }: PayloadAction<ICategory[]>) => {
      state.categories = payload;
    },
    setSorter: (state, { payload }: PayloadAction<string>) => {
      state.sortBy = payload;
    },
    setCategoryFilter: (state, { payload }: PayloadAction<Key | null>) => {
      state.propertyFilter = [];
      state.pricesFilter = {
        maxPrice: 0,
        minPrice: 0,
      };
      state.categoryFilter = payload;
    },
    setPricesFilter: (
      state,
      { payload }: PayloadAction<IPricesFilter | null>
    ) => {
      if (payload !== null) {
        state.pricesFilter = payload;
      } else {
        state.pricesFilter = {
          maxPrice: 0,
          minPrice: 0,
        };
      }
    },
    addFilterProperty: (
      state,
      {
        payload,
      }: PayloadAction<{
        code: string;
        name: string;
        value: IPropertyValue;
      }>
    ) => {
      state.pricesFilter = {
        maxPrice: 0,
        minPrice: 0,
      };
      if (state.propertyFilter) {
        let val = state.propertyFilter.find((e) => e.code === payload.code);

        if (val) {
          if (!val.values.find((e) => e.id === payload.value.id)) {
            val.values.push(payload.value);
          }
        } else {
          state.propertyFilter.push({
            code: payload.code,
            name: payload.name,
            values: [payload.value],
          });
        }
      } else {
        state.propertyFilter = [
          {
            code: payload.code,
            name: payload.name,
            values: [payload.value],
          },
        ];
      }
    },
    removeFilterProperty: (
      state,
      { payload }: PayloadAction<{ value: IPropertyValue; code: string }>
    ) => {
      state.pricesFilter = {
        maxPrice: 0,
        minPrice: 0,
      };
      if (state.propertyFilter) {
        let val = state.propertyFilter.find((e) => e.code === payload.code);
        if (val) {
          if (val.values.length === 1) {
            state.propertyFilter = _.remove(
              state.propertyFilter,
              (e) => e.code !== payload.code
            );
          } else {
            let res = _.remove(val.values, (e) => e.id !== payload.value.id);
            val.values = res;
          }
        }
      }
    },
    clearFilterProperty: (state) => {
      state.propertyFilter = [];
    },
  },
});

export default catalogSlice.reducer;

export const {
  setCategoriesState,
  setCategoryFilter,
  addFilterProperty,
  removeFilterProperty,
  clearFilterProperty,
  setPricesFilter,
  setSorter,
} = catalogSlice.actions;

export const getCategoriesState = (state: AppState) => state.catalog.categories;
export const getCategoryFilter = (state: AppState) =>
  state.catalog.categoryFilter;
export const getPropertyFilter = (state: AppState) =>
  state.catalog.propertyFilter;
export const getPricesFilter = (state: AppState) => state.catalog.pricesFilter;
export const getCategoryEntity = (state: AppState) =>
  state.catalog.categories.find((e) => e.id === state.catalog.categoryFilter);

export const getSortFilter = (state: AppState) => state.catalog.sortBy;
