import {
  Badge,
  Col,
  ImageField,
  Row,
  Table,
  TablePaginationConfig,
  notification,
  TextField,
} from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { capitalizeFirstLetter } from "components/helper";
import React, { FC, ReactNode } from "react";
import { ICartItem, IProduct } from "types";
import { AddProductToCart } from "../AddProductToCart";

const { Column } = Table;
export interface IProductsTableProps {
  products: IProduct[];
  cartItems: ICartItem[];
  totalSku?: number;
  sum?: number | string;
  pagination?: TablePaginationConfig;
  canRead?: boolean;
  cantUpdate?: boolean;
  ovverideCO?: {
    c: string | number;
    o: string | number;
  };
  footer?: ReactNode;
  onChangeCart?: (cartItems: ICartItem[]) => void;
}
export const ProductsTable: FC<IProductsTableProps> = ({
  products,
  cartItems,
  sum,
  totalSku,
  pagination,
  canRead,
  ovverideCO,
  cantUpdate,
  onChangeCart,
  footer,
}) => {
  const { show } = useNavigation();
  return (
    <Table
      dataSource={products}
      rowKey={"name"}
      pagination={pagination ?? false}
      footer={(s) => {
        return (
          <Row>
            <Col span={24}>
              <span>Всього найменувань </span>
              <span>
                <b>{totalSku}</b>,
              </span>
              <span> на сумму </span>
              <span>
                <b>{sum} грн.</b>
              </span>
            </Col>
            {footer && <Col span={24}>{footer}</Col>}
          </Row>
        );
      }}
      onRow={(data) => {
        return {
          style: {
            filter: data.prices.length === 0 ? "grayscale(100%)" : "none",
            backgroundColor: data.prices.length === 0 ? "#fafafa" : "white",
          },
        };
      }}
    >
      <Column<IProduct>
        title={"Зображення"}
        key="image"
        dataIndex={"image"}
        render={(value, record) => {
          let media = record.media.find((e) => e.collectionName === "main");
          if (!media && record.media.length > 0) {
            media = record.media[0];
          }
          if (!media) {
            return <></>;
          }
          return (
            <ImageField
              preview={false}
              value={`${process.env.REACT_APP_IMAGE_PATH}${media?.small}`}
              height={64}
            />
          );
        }}
      />
      <Column<IProduct>
        title={"Назва"}
        key="name"
        dataIndex={"name"}
        render={(value, record) => {
          return (
            <TextField
              style={{
                cursor: "pointer",
              }}
              onClick={() => show("products", record.id)}
              value={capitalizeFirstLetter(value)}
            />
          );
        }}
      />{" "}
      <Column<IProduct>
        title={"Ціна"}
        key="price"
        dataIndex={"price"}
        render={(value, record) => {
          const priceField = () => {
            if (record.discountPrice !== 0) {
              return (
                <Row>
                  <Col span={24}>
                    <del className="old-price">
                      {record.prices[0].price} <span>грн.</span>
                    </del>
                  </Col>
                  <Col span={24}>
                    <div className="price-discount">
                      {record.discountPrice} <span>грн.</span>
                    </div>
                  </Col>
                </Row>
              );
            }
            return (
              <TextField
                className="price"
                value={`${record.prices[0].price} грн.`}
              />
            );
          };
          return priceField();
        }}
      />{" "}
      <Column<IProduct>
        title={"Кількість"}
        key="quantity"
        dataIndex={"quantity"}
        render={(value, record) => {
          const cartItem = cartItems.find((s) => s.productId === record.id);
          const balance = cartItem?.quantity;
          return <TextField value={balance} />;
        }}
      />
      <Column<IProduct>
        title={"Сума"}
        key="sum"
        dataIndex={"sum"}
        render={(value, record) => {
          const cartItem = cartItems.find((s) => s.productId === record.id);
          if (!cartItem) {
            return <TextField value={0} />;
          }

          let price = 0,
            sum = 0;

          if (record.discountPrice !== 0) {
            price = record.discountPrice;
          } else {
            record.prices.forEach((s) => {
              if (price > s.price) {
                price = s.price;
              } else if (price === 0) {
                price = s.price;
              }
            });
          }
          const balance = cartItem?.quantity;
          sum += price * balance;
          if (balance === 0 || price === 0) {
            return (
              <Badge.Ribbon
                text="Товар не буде додано в замовлення"
                color={"red"}
                className="ribbon"
              >
                <TextField value={`${sum.toFixed(2)} грн.`} className="price" />
              </Badge.Ribbon>
            );
          }
          return (
            <TextField value={`${sum.toFixed(2)} грн.`} className="price" />
          );
        }}
      />
      {canRead && (
        <Column<IProduct>
          title={"Дії"}
          key="actions"
          render={(value, record) => {
            const cartItem = cartItems.find((e) => e.productId === record.id);
            if (!cartItem) return <></>;
            return (
              <AddProductToCart
                key={`${record.id}-${cartItem.quantity}`}
                productId={record.id}
                cartQuantity={cartItem.quantity}
                initialValue={cartItem.quantity}
                productName={record.name}
                onChangeBalance={(productId: number) => {
                  notification.destroy(productId);
                }}
                ovverideCO={ovverideCO}
                cantUpdate={cantUpdate ?? true}
                onChange={onChangeCart}
                liveUpdate
                canDelete
              />
            );
          }}
        />
      )}
    </Table>
  );
};
