import {
  CrudFilter,
  CrudFilters,
  CrudSort,
  CrudSorting,
  DataProvider,
} from "@pankod/refine-core";
import { IListResponse, IResponseError } from "types";
import axios, { AxiosError, AxiosInstance, isAxiosError } from "axios";
import { TOKEN_KEY } from "./authProvider";

const axiosInstance = axios.create({
  headers: {
    Accept: "application/json, text/plain, */*",
  },
  withCredentials: true,
  
});

const parseSort = (sorts: CrudSorting) => {
  if (sorts.length === 0) return "";

  return `&sort_by=${sorts
    .map((sort: CrudSort) => `${sort.field}.${sort.order}`)
    .join("|")}`;
};
const parseValue = (filter: CrudFilter): string => {
  switch (filter.operator) {
    case "ne":
    case "gte":
    case "lte":
      return `&${filter.field}=${filter.operator}:${filter.value}`;
    case "contains":
      return `&${filter.field}=like:${filter.value}`;
    case "containss":
      return `&query=${filter.value}`;
    case "eq":
      return `&${filter.field}=${filter.value}`;
  }

  return ""; // default "eq"
};

const generateFilter = (filters?: CrudFilters) => {
  if (!filters) return ""; // no filters

  return encodeURI(
    filters.map((value: CrudFilter) => parseValue(value)).join("")
  );
};

const parseResouse = (resource: string) => {
  if (resource.includes(":")) {
    let s = resource.split(":");

    return s.join("/");
  }

  return resource;
};

export const restDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): DataProvider => ({
  getList: async ({ resource, pagination, filters, sort, metaData }) => {
    const url = `${apiUrl}/${parseResouse(resource)}`;
    const current = pagination?.current || 1;
    const pageSize = pagination?.pageSize || 10;
    const sortStr = parseSort(sort ?? []);
    const token = localStorage.getItem(TOKEN_KEY);
    const strFilter = generateFilter(filters);

    let limitOffset = "",
      queryStr = "";
    if (!(metaData as any).all) {
      limitOffset = `limit=${pageSize}&offset=${(current - 1) * pageSize}`;
    }
    if ((metaData as any).query) {
      queryStr = generateFilter((metaData as any).query);
    }
    const { data, status, statusText } = await httpClient.get<IListResponse>(
      `${url}?${limitOffset}${sortStr}${strFilter}${queryStr}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    if (status === 204) {
      return {
        data: [],
        total: 0,
      };
    }
    if (status !== 200) {
      return Promise.reject({
        statusCode: status,
        message: statusText,
      });
    }
    if ((metaData as any).withoutCount) {
      return {
        data: data as any,
        total: (data as any).length,
      };
    }
    if ((metaData as any).resultWithPrice) {
      return {
        data: data.rows,
        total: data.count,
        minPrice: data.minPrice,
        maxPrice: data.maxPrice,
      };
    }

    return {
      data: data.rows,
      total: data.count,
    };
  },
  getMany: async ({ resource, ids, metaData }) => {
    const url = `${apiUrl}/${parseResouse(resource)}`;
    const token = localStorage.getItem(TOKEN_KEY);
    let queryStr = "";
    if (ids.length === 0)
      return {
        data: [],
        total: 0,
      };
    if ((metaData as any).query) {
      queryStr = generateFilter((metaData as any).query);
    }

    const { data, status, statusText } = await httpClient.get<IListResponse>(
      `${url}?ids=${ids.join(",")}${queryStr}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    if (status === 204) {
      return {
        data: [],
        total: 0,
      };
    }
    if (status !== 200) {
      return Promise.reject({
        statusCode: status,
        message: statusText,
      });
    }
    return {
      data: data.rows,
      total: data.count,
    };
  },
  getOne: async ({ resource, id, metaData }) => {
    const url = `${apiUrl}/${parseResouse(resource)}`;
    const token = localStorage.getItem(TOKEN_KEY);
    let queryStr = "";
    if ((metaData as any).query) {
      queryStr = "?" + generateFilter((metaData as any).query);
    }
    const { data, status, statusText } = await httpClient.get(
      `${url}/${id}${queryStr}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    if (status === 204) {
      return {
        data: null,
      };
    }
    if (status !== 200) {
      return Promise.reject({
        statusCode: status,
        message: statusText,
      });
    }
    return {
      data,
    };
  },
  create: async ({ resource, variables, metaData }) => {
    let additional = "";
    if (metaData?.operation) {
      additional = `/${metaData.operation}`;
    }
    const url = `${apiUrl}/${parseResouse(resource)}${additional}`;
    const token = localStorage.getItem(TOKEN_KEY);
    try {
      const { data, status, statusText } = await httpClient.post(
        url,
        variables,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 204) {
        return {
          data: null,
        };
      }
      if (status !== 200) {
        return Promise.reject({
          statusCode: status,
          message: statusText,
        });
      }
      return {
        data,
      };
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IResponseError>;
        return Promise.reject({
          message: error.response?.data.message,
          statusCode: error.status,
        });
      }

      return Promise.reject(e);
    }
  },

  createMany: async ({ resource }) => {
    const url = `${apiUrl}/${parseResouse(resource)}`;
    const token = localStorage.getItem(TOKEN_KEY);

    const { data, status, statusText } = await httpClient.get(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    if (status !== 200) {
      return Promise.reject({
        statusCode: status,
        message: statusText,
      });
    }
    return {
      data,
    };
  },
  update: async ({ resource, id, variables, metaData }) => {
    const url = `${apiUrl}/${parseResouse(resource)}/${id}`;
    const token = localStorage.getItem(TOKEN_KEY);
    let queryStr = "";
    if ((metaData as any).query) {
      queryStr = "?" + generateFilter((metaData as any).query);
    }
    const { data, status, statusText } = await httpClient.put(
      `${url}${queryStr}`,
      variables,
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    if (status !== 200) {
      return Promise.reject({
        statusCode: status,
        message: statusText,
      });
    }
    return {
      data,
    };
  },

  updateMany: async ({ resource, ids, variables }) => {
    const token = localStorage.getItem(TOKEN_KEY);
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.put(
          `${apiUrl}/${parseResouse(resource)}/${id}`,
          variables,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      })
    );

    return { data: response };
  },

  deleteOne: async ({ resource, id, metaData }) => {
    const token = localStorage.getItem(TOKEN_KEY);
    const url = `${apiUrl}/${parseResouse(resource)}/${id}`;
    let queryStr = "";
    if ((metaData as any).query) {
      queryStr = "?" + generateFilter((metaData as any).query);
    }
    const { data, status, statusText } = await httpClient.delete(
      `${url}${queryStr}`,
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    if (status !== 200) {
      return Promise.reject({
        statusCode: status,
        message: statusText,
      });
    }
    return {
      data,
    };
  },

  deleteMany: async ({ resource, ids }) => {
    const token = localStorage.getItem(TOKEN_KEY);
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.delete(
          `${apiUrl}/${parseResouse(resource)}/${id}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      })
    );
    return { data: response };
  },

  getApiUrl: () => {
    return apiUrl;
  },
  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    let requestUrl = `${url}`;

    // if (headers) {
    //   httpClient.defaults.headers = {
    //     ...httpClient.defaults.headers,
    //     ...headers,
    //   };
    // }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload);
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url, {
          data: payload,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});
