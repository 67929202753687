import { Empty, List, Row, Switch, Typography } from "@pankod/refine-antd";
import {
  QuestionCircleOutlined,
  WarningOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { OrdersList } from "components/personal";
import { useCanAccessByRole, useTour } from "hooks";
import { FC, useState, useRef } from "react";
import { Helmet } from "react-helmet";

export const OrdersPage: FC = () => {
  const refAboutThisPage = useRef(null);
  const refClickShowAll = useRef(null);
  const { tourElement, setOpen } = useTour({
    steps: [
      {
        target: () => refAboutThisPage.current!,
        placement: "bottom",
        cover: <img alt="tour.png" src={`/images/tour/orders_main.jpg`} />,
        title: <div>Список створених замовлень</div>,
        description: (
          <div>
            <p>
              На цій сторінці знаходиться повна історія замовлень всіх Ваших
              контрагентів (в розрізі точок доставки)
            </p>
            <p>
              <WarningOutlined style={{ color: "#8B0000" }} /> За замовчуванням
              відображаються замовлення вибраної точки доставки
            </p>
            <p>
              <ExclamationCircleOutlined style={{ color: "#006400" }} /> Для
              того, щоб завершити роботу помічника достроково натисніть клавішу
              ESC
            </p>
          </div>
        ),
      },
      {
        target: () => refClickShowAll.current!,
        placement: "right",
        title: (
          <div>
            Перемкніть, щоб побачити всі замовлення Ваших контрагентів та точок
            доставки
          </div>
        ),
      },
    ],
    initOpen: false,
  });
  const [showAll, setShowAll] = useState<boolean>(false);

  return (
    <>
      <Helmet>
        <title>Замовлення | Офіс Центр</title>
      </Helmet>
      <List
        title={
          <div>
            Замовлення{" "}
            <QuestionCircleOutlined
              style={{ fontSize: "20px", color: "#08c" }}
              onClick={() => setOpen(true)}
              alt="Помічник"
              title="Запустити помічника на цій сторінці"
            />
          </div>
        }
        headerButtons={[
          <Row>
            <Typography.Text
              style={{
                marginRight: "8px",
              }}
            >
              Показати всі замовлення
            </Typography.Text>
            <Switch
              ref={refClickShowAll}
              checked={showAll}
              onChange={(val) => setShowAll(val)}
            />
          </Row>,
        ]}
      >
        <OrdersList all={showAll} />
      </List>
      {tourElement}
    </>
  );
};
