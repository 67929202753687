import { Col, Form, Modal, Select } from "@pankod/refine-antd";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { setOutletId } from "store/slices/appSlice";

export interface IOutletSelectModalProps {
  open: boolean;
  outletSelectProps: any;
  selectKey?: any;
  onOk?: () => void;
  onCancel?: () => void;
}

export const OutletSelectModal: FC<IOutletSelectModalProps> = ({
  open,
  onOk,
  onCancel,
  outletSelectProps,
  selectKey,
}) => {
  const dispatch = useDispatch();

  return (
    <Modal
      open={open}
      title="Вибір точки доставки"
      onOk={onOk}
      onCancel={onCancel}
    >
      <Col>
        <Form.Item label="Виберіть точку доставки">
          <Select
            {...outletSelectProps}
            key={selectKey}
            onChange={async (value) => {
              dispatch(setOutletId(value as any));
            }}
          />
        </Form.Item>
      </Col>
    </Modal>
  );
};
