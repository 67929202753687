import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICounterparty } from "types";
import { AppState } from "../store";

export interface IAppState {
  counterpartyId?: number;
  outletId?: number;
  counterpartyData?: ICounterparty;
}
const initialState: IAppState = {};

const appSlice = createSlice({
  initialState,
  name: "app",
  reducers: {
    setCounterpartyId: (
      state,
      { payload }: PayloadAction<number | undefined>
    ) => {
      state.counterpartyId = payload;
      state.counterpartyData = undefined;
      state.outletId = undefined;
    },
    setOutletId: (state, { payload }: PayloadAction<number | undefined>) => {
      state.outletId = payload;
    },
    setCounterpartyData: (state, { payload }: PayloadAction<ICounterparty>) => {
      state.counterpartyData = payload;
    },
  },
});

export default appSlice.reducer;

export const { setCounterpartyId, setOutletId, setCounterpartyData } =
  appSlice.actions;

export const getCounterpartyId = (state: AppState) => state.app.counterpartyId;
export const getOutletId = (state: AppState) => state.app.outletId;
export const getCounterpartyData = (state: AppState) =>
  state.app.counterpartyData;
export const getOutletInfo = (state: AppState) =>
  state.app.counterpartyData?.outlets.find((e) => e.id === state.app.outletId);
