import { Col, Form, Modal, Select } from "@pankod/refine-antd";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { setCounterpartyId } from "store/slices/appSlice";

export interface ICounterpartySelectModalProps {
  open: boolean;
  counterpartySelectProps: any;
  onOk?: () => void;
  onCancel?: () => void;
}

export const CounterpartySelectModal: FC<ICounterpartySelectModalProps> = ({
  open,
  onOk,
  onCancel,
  counterpartySelectProps,
}) => {
  const dispatch = useDispatch();
  const [selectedCounterparty, setSelectedCounterparty] = useState(0);

  return (
    <Modal
      open={open}
      title="Вибір контрагента"
      onOk={() => {
        dispatch(setCounterpartyId(selectedCounterparty as any));
        onOk && onOk();
      }}
      onCancel={onCancel}
      cancelText="Закрити"
      
    >
      <Col>
        <Form.Item label="Виберіть контрагента">
          <Select
            {...counterpartySelectProps}
            onChange={async (value) => {
              setSelectedCounterparty(value as any);
            }}
          />
        </Form.Item>
      </Col>
    </Modal>
  );
};
