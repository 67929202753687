import { TOKEN_KEY } from "authProvider";
import jwtDecode from "jwt-decode";
import { IJWTData } from "types";
import { useGetTokenData } from "./useGetTokenData";

export const useIsHoldingOwner = (): boolean => {
  const tokenData = useGetTokenData();
  if (!tokenData) return false;

  return tokenData.roles.includes("holding");
};
