import { useDispatch } from "react-redux";
import { setCounterpartyId, setOutletId } from "store/slices/appSlice";
import {
  setWishlistGroup,
  setWishlistGroupItems,
} from "store/slices/wishlistSlice";

export const useClearStore = () => {
  const dispatch = useDispatch();

  const mutate = () => {
    dispatch(setCounterpartyId(undefined));
    dispatch(setOutletId(undefined));
    dispatch(setWishlistGroup([]));
    dispatch(setWishlistGroupItems([]));
  };
  const clearOutlet = () => {
    dispatch(setOutletId(undefined));
  };
  const clearCounterparty = () => {
    dispatch(setOutletId(undefined));
    dispatch(setCounterpartyId(undefined));
  };
  return { mutate, clearOutlet, clearCounterparty };
};
