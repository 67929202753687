import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCounterpartyId, getOutletId } from "store/slices/appSlice";

interface COParamsData {
  counterpartyId?: number | string;
  outletId?: number | string;
  hasCO: boolean;
}

export const useGetCOParams = (): COParamsData => {
  const c = useSelector(getCounterpartyId);
  const o = useSelector(getOutletId);

  return {
    hasCO: c !== undefined && o !== undefined,
    counterpartyId: c,
    outletId: o,
  };
};
