import {
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Select,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { FC } from "react";

export interface ICounterpartyCreateModalProps {
  createModalProps: ModalProps;
  createFormProps: FormProps<{}>;
  action?: "edit" | "create";
}

export const CounterpartyCreateModal: FC<ICounterpartyCreateModalProps> = ({
  createModalProps,
  createFormProps,
  action = "create",
}) => {
  const t = useTranslate();
  return (
    <Modal
      {...createModalProps}
      title={
        action === "create"
          ? "Створення Контрагента"
          : "Редагування Контрагента"
      }
    >
      <Form {...createFormProps} layout="vertical">
        <Form.Item
          name={"name"}
          label="Назва"
          rules={[
            {
              required: true,
              message: "Поле назва обов`язкове",
            },
            {
              min: 3,
              max: 150,
              message: "Поле назва має містити від 3 до 150 символів",
            },
          ]}
        >
          <Input />
        </Form.Item>{" "}
        <Form.Item
          name={"innEdrpou"}
          label="ІНН/ЄДРПОУ"
          rules={[
            {
              required: true,
              message: "Поле ІНН/ЄДРПОУ обов`язкове",
            },
            {
              min: 8,
              max: 12,
              message: "Поле ІНН/ЄДРПОУ має містити 8 або 12 символів",
            },
          ]}
        >
          <Input />
        </Form.Item>{" "}
        <Form.Item name={"isLegalEntity"} label="Юр/Фіз особа">
          <Select
            defaultValue={{ label: "Фізична особа", value: false }}
            options={[
              { label: "Фізична особа", value: false },
              { label: "Юридична особа", value: true },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
