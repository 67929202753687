import {
  Card,
  Carousel,
  Col,
  Empty,
  ImageField,
  Image,
  Row,
  Typography,
  Icon,
  TextField,
  Divider,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useOne } from "@pankod/refine-core";
import { useLocation } from "@pankod/refine-react-router-v6";
import { AddProductToCart } from "components/catalog";
import { capitalizeFirstLetter, inStock } from "components/helper";

import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { getCounterpartyId, getOutletId } from "store/slices/appSlice";
import { IProductFull } from "types";
import "./ProductDetailsPage.css";

export const ProductDetailsPage: FC<IResourceComponentsProps> = () => {
  const { pathname } = useLocation();
  const id = pathname.slice(pathname.lastIndexOf("/") + 1);
  const counterpartyId = useSelector(getCounterpartyId);
  const outletId = useSelector(getOutletId);
  const [product, setProduct] = useState<IProductFull>();
  const { data, isSuccess } = useOne<IProductFull>({
    resource: "product",
    id: id,
    metaData: {
      query: [
        { field: "c", operator: "eq", value: counterpartyId },
        { field: "o", operator: "eq", value: outletId },
      ],
    },
    queryOptions: {
      enabled: counterpartyId !== undefined && outletId !== undefined,
    },
  });

  useEffect(() => {
    if (data && data.data && isSuccess) {
      setProduct(data.data);
    }
  }, [data, isSuccess]);

  if (!product) {
    return (
      <Row gutter={[16, 16]} justify="center" align={"middle"}>
        <Empty description="Товар не знайдено" />
      </Row>
    );
  }
  return (
    <>
      <Helmet>
        <title>{product.name} | Офіс Центр</title>
        <meta name="description" content={product.description} />
      </Helmet>
      <Row wrap gutter={[16, 16]} align={"top"}>
        <Col lg={24} sm={24}>
          <Typography.Title level={3}>
            {capitalizeFirstLetter(product.name)}
          </Typography.Title>
        </Col>
        <Col lg={12} sm={24}>
          <Card>
            <Row justify={"center"}>
              <Image.PreviewGroup>
                <Col span={24}>
                  <Carousel
                    infinite={false}
                    arrows
                    centerMode
                    draggable
                    prevArrow={<Icon type={"left"} />}
                    nextArrow={<Icon type={"right"} />}
                  >
                    {product.media &&
                      product.media.length > 0 &&
                      product.media.map((media) => {
                        return (
                          <div key={media.id}>
                            <Row justify={"center"} align={"middle"}>
                              <ImageField
                                preview={{
                                  mask: "Переглянути",
                                }}
                                value={`${process.env.REACT_APP_IMAGE_PATH}${media.large}`}
                                height={500}
                                style={{
                                  objectFit: "contain",
                                }}
                              />
                            </Row>
                          </div>
                        );
                      })}
                  </Carousel>
                </Col>
              </Image.PreviewGroup>
            </Row>
          </Card>
        </Col>
        <Col lg={12} sm={24}>
          <Card>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                {product.discountPrice !== 0 ? (
                  <Row>
                    <Col span={24}>
                      <del className="old-price">
                        {product.prices[0].price} <span>грн.</span>
                      </del>
                    </Col>
                    <Col span={24}>
                      <div className="price-discount">
                        {product.discountPrice} <span>грн.</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <TextField
                    className="price"
                    value={`${product.prices[0].price} грн.`}
                  />
                )}
              </Col>
              {!product.isActual ? (
                <TextField
                  style={{
                    color: "#fd1100",
                  }}
                  value={"Знято з продажу"}
                />
              ) : inStock(product) ? (
                <TextField
                  style={{
                    color: "#fd1100",
                  }}
                  value={"Нема в наявності"}
                />
              ) : (
                <AddProductToCart
                  key={`${product.id}`}
                  productId={product.id}
                  productName={product.name}
                />
              )}
            </Row>
            <Divider />
            <Row>
              <div className="props">
                {" "}
                {product.properties.map((e) => {
                  return (
                    <div className="product-prop__item" key={e.id}>
                      <span>{e.productProperty.name}</span>
                      <span>{e.value}</span>
                    </div>
                  );
                })}{" "}
                <div className="product-prop__item">
                  <span>ПДВ</span>
                  <span>{product.vatRate.name}</span>
                </div>
                <div className="product-prop__item">
                  <span>Одиниця виміру</span>
                  <span>{product.units.map((e) => e.name).join(", ")}</span>
                </div>{" "}
                <div className="product-prop__item">
                  <span>Штрихкоди</span>
                  <span>
                    {product.barcodes.map((e) => e.barcode).join(", ")}
                  </span>
                </div>
              </div>
            </Row>
          </Card>
        </Col>
        <Col lg={24} sm={24}>
          <Card title="Опис">
            <div
              dangerouslySetInnerHTML={{
                __html: product.description,
              }}
            ></div>
          </Card>
        </Col>
        <Col lg={24} sm={24}></Col>
      </Row>
    </>
  );
};
