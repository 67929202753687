import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { loadState, parseCatalog } from "components/helper";
import appSlice from "./slices/appSlice";
import cartSlice from "./slices/cartSlice";
import catalogSlice from "./slices/catalogSlice";
import modalsSlice from "./slices/modalsSlice";
import wishlistSlice from "./slices/wishlistSlice";
export const store = configureStore({
  reducer: {
    app: appSlice,
    modals: modalsSlice,
    catalog: catalogSlice,
    cart: cartSlice,
    wishlist: wishlistSlice,
  },
  preloadedState: {
    app: loadState("app"),
    catalog: parseCatalog(),
  },
});

export type AppState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;

export type AppDispatch = typeof store.dispatch;
