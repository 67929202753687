import { Button, InputNumber, Row, Icons } from "@pankod/refine-antd";
import { useNotification } from "@pankod/refine-core";
import axios from "axios";
import { useCanAccessByRole } from "hooks";
import { debounce } from "lodash";
import { FC, MutableRefObject, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCounterpartyId, getOutletId } from "store/slices/appSlice";
import { setCartItems } from "store/slices/cartSlice";
import { ICartItem } from "types";
import { TOKEN_KEY } from "../../../authProvider";

const { PlusOutlined, MinusOutlined, ShoppingCartOutlined, DeleteOutlined } =
  Icons;

export interface IAddProductToCartProps {
  ref1?: MutableRefObject<any>;
  productId: number;
  productName: string;
  initialValue?: number;
  cartQuantity?: number;
  liveUpdate?: boolean;
  onAddProduct?: () => void;
  onChangeBalance?: (productId: number) => void;
  onChange?: (cartItems: ICartItem[]) => void;
  canDelete?: boolean;
  cantUpdate?: boolean;
  ovverideCO?: {
    c: string | number;
    o: string | number;
  };
}

export const AddProductToCart: FC<IAddProductToCartProps> = ({
  productId,
  initialValue,
  productName,
  cartQuantity,
  liveUpdate,
  canDelete,
  ovverideCO,
  cantUpdate,
  onAddProduct,
  onChangeBalance,
  onChange,
  ref1,
}) => {
  const token = localStorage.getItem(TOKEN_KEY);
  const dispatch = useDispatch();
  const counterpartyId = useSelector(getCounterpartyId);
  const outletId = useSelector(getOutletId);
  const { open } = useNotification();
  const canAccess = useCanAccessByRole(["buyer"]);
  const [quantity, setQuantity] = useState(initialValue ? initialValue : 1);

  const addQuantity = async () => {
    // if (max === 0) {
    //   open &&
    //     !liveUpdate &&
    //     open({
    //       message: `Товару нема в наявності, звʼяжітсья з вашим менеджером.`,
    //       type: "error",
    //     });

    //   return;
    // }

    // if (quantity >= max) {
    //   setQuantity(max);
    //   open &&
    //     !liveUpdate &&
    //     open({
    //       message: `Максимальна кількість для замовлення ${max}`,
    //       type: "error",
    //     });
    // } else {
    setQuantity((prev) => prev + 1);
    // }
  };

  const removeQuantity = async () => {
    // if (max === 0) {
    //   return;
    // }
    if (quantity === 1) {
      return;
    }
    setQuantity((prev) => prev - 1);
  };

  const onChangeQuantity = async (value: number | null) => {
    if (!value) return;
    // if (value >= max) {
    //   setQuantity(max);
    //   return;
    // }
    setQuantity(value);
  };

  useEffect(() => {
    if (liveUpdate && initialValue && initialValue !== quantity) {
      updateCartItem(quantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCartItem = useCallback(
    debounce((newQuantity) => addToCart(newQuantity), 600),
    [token, outletId, counterpartyId, canAccess]
  );

  const addToCart = async (newQuantity?: number) => {
    if (!token || !outletId || !counterpartyId || !canAccess) return;
    // if (quantity === 0) {
    //   open &&
    //     !liveUpdate &&
    //     open({
    //       message: `Товару нема в наявності, звʼяжітсья з вашим менеджером.`,
    //       type: "error",
    //     });
    //   return;
    // }

    try {
      const { data, status } = await axios.put(
        `${process.env.REACT_APP_API_URL}/b2b/cart?c=${
          ovverideCO ? ovverideCO.c : counterpartyId
        }&o=${ovverideCO ? ovverideCO.o : outletId}`,
        [
          {
            productId: productId,
            quantity: newQuantity ? newQuantity : quantity === 0 ? 1 : quantity,
          },
        ],
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        onAddProduct && onAddProduct();

        open &&
          !liveUpdate &&
          open({
            message: `${productName}`,
            description: `Додано в кошик`,

            type: "success",
          });

        if (!cantUpdate) {
          dispatch(setCartItems(data));
        }
        onChange && onChange(data);
        onChangeBalance && onChangeBalance(productId);
      }
    } catch (e) {
      //TODO: show error
    }
  };
  const removeCartItem = async () => {
    if (!canAccess) return;
    try {
      const { data, status } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/b2b/cart?c=${
          ovverideCO ? ovverideCO.c : counterpartyId
        }&o=${ovverideCO ? ovverideCO.o : outletId}`,

        {
          data: [
            {
              productId: productId,
              quantity:
                cartQuantity && quantity === 0 ? cartQuantity : quantity,
            },
          ],
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        open &&
          open({
            message: `${productName}`,
            description: `Видалено з кошика`,

            type: "success",
          });
        if (!cantUpdate) {
          dispatch(setCartItems(data));
        }
        onChangeBalance && onChangeBalance(productId);
        onChange && onChange(data);
      }
    } catch (e) {
      //TODO: show error
    }
  };
  return (
    <Row ref={ref1}>
      <Button icon={<MinusOutlined />} onClick={removeQuantity} />
      <InputNumber
        max={999999}
        min={1}
        step={1}
        value={quantity}
        onChange={onChangeQuantity}
        style={{
          width: "60px",
          marginRight: "8px",
          marginLeft: "8px",
        }}
      />
      <Button icon={<PlusOutlined />} onClick={addQuantity} />

      {!liveUpdate ? (
        <Button
          icon={<ShoppingCartOutlined />}
          danger
          disabled={!canAccess}
          style={{
            marginLeft: "8px",
          }}
          onClick={() => addToCart()}
        />
      ) : null}
      {canDelete && canAccess && (
        <Button
          icon={<DeleteOutlined />}
          danger
          style={{
            marginLeft: "8px",
          }}
          onClick={() => removeCartItem()}
        />
      )}
    </Row>
  );
};
