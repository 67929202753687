import {
  Button,
  Col,
  Row,
  Select,
  useSelect,
  notification,
} from "@pankod/refine-antd";
import { TOKEN_KEY } from "authProvider";
import axios from "axios";
import React, { FC, useState } from "react";

export interface IProductCartSelectorProps {
  c: string | number;
  o: string | number;
  onProductAdded?: () => void;
}

export const ProductCartSelector: FC<IProductCartSelectorProps> = ({
  c,
  o,
  onProductAdded,
}) => {
  const token = localStorage.getItem(TOKEN_KEY);
  const [selectedValue, setSelectedValue] = useState<number | string>();
  const [searchText, setSearchText] = useState<string>("");

  const addToCart = async () => {
    if (!token) return;

    try {
      const { data, status } = await axios.put(
        `${process.env.REACT_APP_API_URL}/b2b/cart?c=${c}&o=${o}`,
        [
          {
            productId: selectedValue,
            quantity: 1,
          },
        ],
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        onProductAdded && onProductAdded();
      }
    } catch (e) {
      //TODO: show error
    }
  };
  const { selectProps } = useSelect({
    resource: "product/search",
    optionValue: "id",
    optionLabel: "name",
    queryOptions: {
      enabled: searchText !== "",
    },
    filters: [
      {
        field: "q",
        operator: "eq",
        value: searchText,
      },
      {
        field: "c",
        operator: "eq",
        value: c,
      },
      {
        field: "o",
        operator: "eq",
        value: o,
      },
    ],
  });
  return (
    <Row gutter={[10, 10]}>
      <Col span={20}>
        <Select
          {...selectProps}
          style={{
            width: "100%",
          }}
          placeholder="Введіть назву товару"
          onSearch={(text) => setSearchText(text)}
          onSelect={(v) => {
            setSelectedValue(`${v}`);
          }}
        />
      </Col>
      <Col span={4}>
        <Button onClick={addToCart}>Додати до кошика</Button>
      </Col>
    </Row>
  );
};
