import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../store";

export interface IModalsState {
  counterpartyModal: boolean;
  outletModal: boolean;
  cartDrawer: boolean;
}
const initialState: IModalsState = {
  counterpartyModal: false,
  outletModal: false,
  cartDrawer: false,
};
const modalSlice = createSlice({
  initialState,
  name: "modals",
  reducers: {
    openCounterpartyModal: (state) => {
      state.counterpartyModal = true;
    },
    closeCounterpartyModal: (state) => {
      state.counterpartyModal = false;
    },
    openOutletModal: (state) => {
      state.outletModal = true;
    },
    closeOutletModal: (state) => {
      state.outletModal = false;
    },
    openCartDrawer: (state) => {
      state.cartDrawer = true;
    },
    closeCartDrawer: (state) => {
      state.cartDrawer = false;
    },
  },
});

export default modalSlice.reducer;

export const {
  closeCounterpartyModal,
  closeOutletModal,
  openCounterpartyModal,
  openOutletModal,
  openCartDrawer,
  closeCartDrawer,
} = modalSlice.actions;

export const hasOpenCounterparty = (state: AppState) =>
  state.modals.counterpartyModal;
export const hasOpenOutlet = (state: AppState) => state.modals.outletModal;
export const hasOpenCartDrawer = (state: AppState) => state.modals.cartDrawer;
