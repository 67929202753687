import { Col, Form, Row, Select } from "@pankod/refine-antd";
import { useList } from "@pankod/refine-core";
import React, { FC, useEffect, useState } from "react";
import { IPickupDelivery } from "types";

export interface IPickupDeliverysProps {
  onSelect?: (delivery?: IPickupDelivery) => void;
}

export const PickupDeliverys: FC<IPickupDeliverysProps> = ({ onSelect }) => {
  const [pickupDeliverys, setPickupDeliverys] = useState<IPickupDelivery[]>([]);
  const { data, isSuccess } = useList<IPickupDelivery>({
    resource: "delivery/pickup",
    metaData: {
      all: true,
      withoutCount: true,
    },
  });

  useEffect(() => {
    if (data?.data && isSuccess) {
      setPickupDeliverys(data.data);
    }
  }, [data, isSuccess]);
  return (
    <Form.Item label="Магазин" style={{ width: "100%" }}>
      <Select
        onSelect={(val) => {
          onSelect &&
            onSelect(pickupDeliverys.find((e) => e.id == (val as any)));
        }}
        placeholder="Виберіть адресу самовивозу"
        options={pickupDeliverys.map((e) => {
          return {
            value: e.id,
            label: `${e.name}, ${e.pickupSettlements.name}`,
          };
        })}
      />
    </Form.Item>
  );
};
