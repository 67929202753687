import { Collapse, Row } from "@pankod/refine-antd";
import { FC } from "react";
import { useSelector } from "react-redux";
import { getPropertyFilter } from "store/slices/catalogSlice";
import { IProperty } from "types";
import { SmartCheckboxFilter } from "../SmartCheckboxFilter";

const { Panel } = Collapse;

export interface IPropertyFilterProps {
  properties: IProperty[];
  onChangeFilter?: () => void;
}

export const PropertyFilter: FC<IPropertyFilterProps> = ({
  properties,
  onChangeFilter,
}) => {
  const propertyFilter = useSelector(getPropertyFilter);

  if (properties.length === 0) {
    return <></>;
  }
  return (
    <Collapse bordered={false} className="collapse">
      {properties.map((propepty) => {
        if (propepty.propertyValues.length < 2) {
          return null;
        }
        return (
          <Panel header={propepty.name} key={propepty.code}>
            <Row
              gutter={[0, 4]}
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                marginBottom: "10px",
              }}
            >
              <SmartCheckboxFilter
                currentFilter={propertyFilter}
                ownerCode={propepty.code}
                ownerName={propepty.name}
                propsValues={propepty.propertyValues}
                onChangeFilter={() => {
                  onChangeFilter && onChangeFilter();
                }}
              />
            </Row>
          </Panel>
        );
      })}
    </Collapse>
  );
};
