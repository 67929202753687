import { useOne } from "@pankod/refine-core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCounterpartyData,
  getCounterpartyId,
  setCounterpartyData,
} from "store/slices/appSlice";
import { AppState } from "store/store";
import { ICounterparty } from "types";

export const useGetCounterparty = (): {
  counterpartyData: ICounterparty | undefined;
  refetch: () => any;
} => {
  const dispatch = useDispatch();
  const counterpartyId = useSelector<AppState, number | undefined>(
    getCounterpartyId
  );
  const counterpartyData = useSelector<AppState, ICounterparty | undefined>(
    getCounterpartyData
  );

  const { data, refetch, isSuccess } = useOne<ICounterparty>({
    resource: "client",
    id: counterpartyId || 0,
    queryOptions: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (
      counterpartyId !== undefined &&
      (!counterpartyData || counterpartyId !== counterpartyData?.id)
    ) {
      refetch();
    }
  }, [counterpartyId]);

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setCounterpartyData(data.data));
    }
  }, [data, isSuccess]);

  return { counterpartyData: counterpartyData, refetch };
};
