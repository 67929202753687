import {
  AntdLayout,
  Button,
  Card,
  Form,
  Typography,
  Input,
  Row,
  Col,
} from "@pankod/refine-antd";
import { useRegister, useTranslate } from "@pankod/refine-core";
import { Link } from "@pankod/refine-react-router-v6";
import { emailPattern, phonePattern } from "components/helper";
import { createRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./styles.css";
import { useClearStore } from "hooks";

export interface IRegisterProps {
  email: string;
  phone: string;
  name: string;
  password: string;
  captchaToken: string;
}
const { Title, Text } = Typography;
export const RegisterPage = () => {
  const t = useTranslate();
  const [token, setToken] = useState<string | null>();
  const captchaRef = createRef<ReCAPTCHA>();
  const [form] = Form.useForm<IRegisterProps>();
  const { mutate: mutateClearStore } = useClearStore();

  const { mutate: register } = useRegister<IRegisterProps>({});

  return (
    <AntdLayout className="login-layout">
      <div className="header-bar">
        <div className="header-container">
          <img src={"/logo.svg"} alt="Логотип Офіс Центр" className="logo" />
          <div className="header-text">
            <h1>КАНЦТОВАРИ</h1>
            <p>Все для офісу, навчання та творчості</p>
          </div>
        </div>
      </div>

      <Row justify="space-around" align="middle" className="content-row">
        <Col className="col1">
          <Title level={3} className="login-title">
            Оптимальне рішення для вашого бізнесу!
          </Title>
          <p className="p1">
            Авторизуйтесь через особистий кабінет та отримайте доступ до
            широкого асортименту товарів, які задовольнять усі потреби вашої
            компанії.
          </p>
          <p className="p2">
            Ми цінуємо ваш час та зусилля, тому пропонуємо оптимальні рішення
            для ефективної роботи вашого бізнесу.
          </p>
        </Col>
        <Col className="col2">
          <div className="login-container">
            <Card className="login-card">
              <Title level={3} className="login-title">
                {t("pages.register.title")}
              </Title>
              <Form
                form={form}
                layout="vertical"
                onFinish={(values) => {
                  mutateClearStore();
                  register({
                    ...(values as IRegisterProps),
                    captchaToken: token ?? "",
                  });
                }}
              >
                <Form.Item
                  name="name"
                  label={t("pages.register.fields.name")}
                  rules={[
                    {
                      required: true,
                      message: t("pages.register.errors.name"),
                    },
                    {
                      min: 3,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>{" "}
                <Form.Item
                  name="firstName"
                  label={t("pages.register.fields.firstName")}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={t("pages.register.fields.email")}
                  rules={[
                    {
                      required: true,
                      message: t("pages.register.errors.validEmail"),
                    },
                    {
                      pattern: emailPattern,
                      message: t("pages.register.errors.validEmail"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label={t("pages.register.fields.phone")}
                  rules={[
                    {
                      required: true,
                      message: t("pages.register.errors.validPhone"),
                    },
                    {
                      pattern: phonePattern,
                      message: t("pages.register.errors.validPhone"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={t("pages.register.fields.password")}
                  rules={[
                    {
                      required: true,
                      message: t("pages.register.errors.validPassword"),
                    },
                    {
                      min: 8,
                      max: 32,

                      message: t("pages.register.errors.minMaxPassword"),
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <div className={"captcha"}>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA || ""}
                    theme={"light"}
                    onChange={(e) => setToken(e)}
                    onErrored={() => setToken(undefined)}
                    onExpired={() => setToken(undefined)}
                    size={"normal"}
                    hl={"uk"}
                    ref={captchaRef}
                  />
                </div>
                <Button type="primary" size="large" htmlType="submit" block>
                  {t("pages.register.buttons.submit")}
                </Button>
              </Form>
              <div
                style={{
                  marginTop: "8px",
                }}
              >
                <Text>
                  {t(
                    "pages.register.buttons.haveAccount",
                    "Don’t have an account?"
                  )}
                  <Link to="/login" style={{ fontWeight: "bold" }}>
                    {t("pages.register.signin", "Sign up")}
                  </Link>
                </Text>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
