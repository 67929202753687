import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  List,
  Modal,
  Row,
  Show,
  Table,
  useModalForm,
  useTable,
} from "@pankod/refine-antd";
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useNavigation, useTranslate } from "@pankod/refine-core";
import { emailPattern, phonePattern } from "components/helper";
import { CounterpartyList, PersonalInfo } from "components/personal";
import { useGetCOParams, useIsHoldingOwner, useTour } from "hooks";
import { createRef, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { IPartner } from "types";

export const PersonalPage = () => {
  const refPersonalAbout = useRef(null);
  const refPersonalSave = useRef(null);
  const refCounterpartyAbout = useRef(null);
  const refCounterpartyAdd = useRef(null);
  const refOutletAbout = useRef(null);
  const refOutletAdd = useRef(null);
  //const refPartnerAbout = useRef(null);
  const refPartnerAdd = useRef(null);
  const isHolding = useIsHoldingOwner();
  const { tourElement, setOpen } = useTour({
    steps: [
      {
        cover: (
          <img alt="profile_main.jpeg" src={`/images/tour/profile_main.jpeg`} />
        ),
        placement: "bottom",
        title: <div>Налаштування профілю</div>,
        description: (
          <div>
            <p>Сторінка містить інформацію про Ваш акаунт на B2B-порталі</p>
            <p>
              <ul>
                <li>Обліковий запис</li>
                <li>Контрагенти</li>
                <li>Точки доставки</li>
                <li>Користувачі холдингу</li>
              </ul>
            </p>
            <p>
              <ExclamationCircleOutlined style={{ color: "#006400" }} /> Для
              того, щоб завершити роботу помічника достроково натисніть клавішу
              ESC
            </p>
          </div>
        ),
      },
      {
        target: () => refPersonalAbout.current!,
        placement: "right",
        title: <div>Інформація про обліковий запис</div>,
        description: (
          <div>
            <p>
              Ви можете редагувати інформацію про користувача в будь-який момент
            </p>
            <p>
              <WarningOutlined style={{ color: "#8B0000" }} /> Адреса електроної
              пошти не редагується
            </p>
          </div>
        ),
      },
      {
        target: () => refPersonalSave.current!,
        placement: "right",
        title: (
          <div>
            <WarningOutlined style={{ color: "#8B0000" }} /> Після редагування
            не забудьте зберегти зміни
          </div>
        ),
      },

      {
        target: () => refCounterpartyAbout.current!,
        placement: "left",
        title: <div>Список контрагентів</div>,
        description: (
          <div>
            <p>
              <QuestionCircleOutlined style={{ color: "#08c" }} /> Контрагент -
              це фізична або юридична особа, на яку буде створено договір
              поставки товару, формуватись рахунки на оплату, акти звірок (за
              потребою), тощо.
            </p>
            <p>
              Ви можете додавати, видаляти або редагувати інформацію Ваших
              контрагентів
            </p>
            <p>
              <ExclamationCircleOutlined style={{ color: "#006400" }} /> При
              виборі контрагента в списку "Точки доставки" відобразяться активні
              точки доставки контрагенту
            </p>
          </div>
        ),
      },
      {
        target: () => refCounterpartyAdd.current!,
        placement: "right",
        title: <div>Ви можете створити нового контрагента</div>,
      },
      {
        target: () => refOutletAbout.current!,
        placement: "right",
        title: <div>Список точок доставки</div>,
        description: (
          <div>
            <p>
              <QuestionCircleOutlined style={{ color: "#08c" }} /> Точка
              доставки - це фактична адреса, на яку буде формуватись складська
              накладна, здійснена доставка товару, здійснений контакт
              експедитором, тощо.
            </p>
            <p>
              Ви можете додавати, видаляти або редагувати точки доставки в будь
              який час
            </p>
            <p>
              <ExclamationCircleOutlined style={{ color: "#006400" }} /> В цьому
              списку відображаються точки доставки, відфільтровані по вибраному
              контрагенту зліва
            </p>
          </div>
        ),
      },
      {
        target: () => refOutletAdd.current!,
        placement: "right",
        title: <div>Ви можете створити нову точку доставки</div>,
        description: (
          <div>
            <WarningOutlined style={{ color: "#8B0000" }} /> Точка доставки буде
            створена для контрагента, який вибраний в списку зліва
          </div>
        ),
      },
      isHolding
        ? {
            cover: (
              <img
                alt="profile_main.jpeg"
                src={`/images/tour/proflie_partners_main.jpeg`}
              />
            ),
            //target: () => refPartnerAbout.current!,
            placement: "bottom",
            title: <div>Список користувачів холдингу</div>,
            description: (
              <div>
                <p>
                  Як голова холдингу Ви можете створювати, редагувати інформацію
                  або видаляти користувачів Вашого холдингу
                </p>
                <p>
                  Як голова холдингу Ви можете надавати доступ користувачам до
                  точок доставки і контрагентів. <br />
                  Наприклад:
                  <ul>
                    <li>
                      Іванов І.І. має бачити тількі одну точку доставки з
                      десяти, на якій він є комірником
                    </li>
                    <li>
                      Бондарчук Б.Б. має бачити тількі одного контрагента з
                      трьох (зі всіма його точками доставки), тому що він є МВО
                      контрагента
                    </li>
                  </ul>
                </p>
              </div>
            ),
          }
        : null,
      isHolding
        ? {
            target: () => refPartnerAdd.current!,
            placement: "right",
            title: <div>Ви можете створити нового користувача холдингу</div>,
          }
        : null,
    ],
    initOpen: false,
  });
  const t = useTranslate();
  const { edit } = useNavigation();
  const { counterpartyId, outletId, hasCO } = useGetCOParams();
  const [token, setToken] = useState<string | null>();
  const captchaRef = createRef<ReCAPTCHA>();
  const { tableProps, tableQueryResult } = useTable<IPartner>({
    resource: "partner/holding_list",
    permanentFilter: [
      {
        field: "c",
        operator: "eq",
        value: counterpartyId,
      },
      {
        field: "o",
        operator: "eq",
        value: outletId,
      },
    ],
    queryOptions: {
      enabled: hasCO && isHolding,
    },
  });
  const { formProps, modalProps, show, onFinish } = useModalForm({
    action: "create",
    resource: "partner/utils/partners",
    redirect: false,
    onMutationSuccess(data, variables, context) {
      tableQueryResult.refetch();
    },
  });
  return (
    <>
      <Helmet>
        <title>Профіль | Офіс Центр</title>
      </Helmet>
      <Show
        title={
          <div>
            Профіль{" "}
            <QuestionCircleOutlined
              style={{ fontSize: "20px", color: "#08c" }}
              onClick={() => setOpen(true)}
              alt="Помічник"
              title="Запустити помічника на цій сторінці"
            />
          </div>
        }
        headerButtons={[]}
      >
        <Row gutter={[10, 10]}>
          <PersonalInfo ref1={refPersonalAbout} ref2={refPersonalSave} />
          <CounterpartyList
            ref1={refCounterpartyAbout}
            ref2={refCounterpartyAdd}
            ref3={refOutletAbout}
            ref4={refOutletAdd}
          />
          {hasCO && isHolding && <Divider />}
          {hasCO && isHolding && (
            <Col span={24}>
              <List
                title="Користувачі"
                headerButtons={[
                  <Button ref={refPartnerAdd} onClick={() => show()}>
                    Створити користувача
                  </Button>,
                ]}
              >
                <Table {...tableProps} rowKey={"id"}>
                  <Table.Column dataIndex={"id"} title={"ID"} />
                  <Table.Column<IPartner> dataIndex={"name"} title="І`мя" />
                  <Table.Column<IPartner>
                    dataIndex={"firstName"}
                    title="Прізвище"
                  />{" "}
                  <Table.Column<IPartner> dataIndex={"phone"} title="Телефон" />
                  <Table.Column<IPartner> dataIndex={"email"} title="Пошта" />
                  <Table.Column<IPartner>
                    dataIndex={"actions"}
                    title="Дії"
                    render={(value, record) => {
                      return (
                        <Row>
                          <Button
                            onClick={() => {
                              edit("personal", record.id);
                            }}
                          >
                            Редагувати
                          </Button>
                        </Row>
                      );
                    }}
                  />
                </Table>
              </List>
            </Col>
          )}
          <Modal {...modalProps}>
            <Form
              {...formProps}
              layout="vertical"
              onFinish={(values) => {
                //register(values as IRegisterProps);
                onFinish &&
                  onFinish({
                    data: [values],
                    captchaToken: token,
                  });
              }}
            >
              <Form.Item
                name="name"
                label={t("pages.register.fields.name")}
                rules={[
                  {
                    required: true,
                    message: t("pages.register.errors.name"),
                  },
                  {
                    min: 3,
                  },
                ]}
              >
                <Input />
              </Form.Item>{" "}
              <Form.Item
                name="firstName"
                label={t("pages.register.fields.firstName")}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label={t("pages.register.fields.email")}
                rules={[
                  {
                    required: true,
                    message: t("pages.register.errors.validEmail"),
                  },
                  {
                    pattern: emailPattern,
                    message: t("pages.register.errors.validEmail"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="phone"
                label={t("pages.register.fields.phone")}
                rules={[
                  {
                    required: true,
                    message: t("pages.register.errors.validPhone"),
                  },
                  {
                    pattern: phonePattern,
                    message: t("pages.register.errors.validPhone"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label={t("pages.register.fields.password")}
                rules={[
                  {
                    required: true,
                    message: t("pages.register.errors.validPassword"),
                  },
                  {
                    min: 8,
                    max: 32,

                    message: t("pages.register.errors.minMaxPassword"),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <div className={"captcha"}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA || ""}
                  theme={"light"}
                  onChange={(e) => setToken(e)}
                  onErrored={() => setToken(undefined)}
                  onExpired={() => setToken(undefined)}
                  size={"normal"}
                  hl={"uk"}
                  ref={captchaRef}
                />
              </div>
            </Form>
          </Modal>
        </Row>
      </Show>
      {tourElement}
    </>
  );
};
