import { Form, FormProps, Input, Modal, ModalProps } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { phonePattern } from "components/helper";
import { FC } from "react";
import { useSelector } from "react-redux";
import { getCounterpartyId } from "store/slices/appSlice";
import { AppState } from "store/store";

export interface IOutletCreateModalProps {
  createModalProps: ModalProps;
  createFormProps: FormProps<{}>;
  ownerId?: number | string;
  action?: "edit" | "create";
}

export const OutletCreateModal: FC<IOutletCreateModalProps> = ({
  createModalProps,
  createFormProps,
  ownerId,
  action = "create",
}) => {
  const t = useTranslate();
  const counterpartyId = useSelector<AppState, number | undefined>((state) =>
    getCounterpartyId(state)
  );

  return (
    <Modal
      {...createModalProps}
      title={
        action === "create"
          ? "Створення Адреси доставки"
          : "Редагування Адреси доставки"
      }
    >
      <Form
        {...createFormProps}
        layout="vertical"
        onFinish={(values: any) => {
          values.ownerId = ownerId ?? counterpartyId;

          return createFormProps?.onFinish && createFormProps?.onFinish(values);
        }}
      >
        <Form.Item
          key={`name${action}`}
          name={"name"}
          label="Назва"
          rules={[
            {
              required: true,
              message: "Поле 'назва' обов`язкове",
            },
            {
              min: 2,
              max: 150,
              message: "Поле 'назва' має містити від 2 до 150 символів",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={`address${action}`}
          name={"address"}
          label="Адреса"
          rules={[
            {
              required: true,
              message: "Поле 'адреса' обов`язкове",
            },
            {
              min: 10,
              max: 150,
              message: "Поле 'адреса' має містити від 10 до 150 символів",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={`contactPerson${action}`}
          name={"contactPerson"}
          label="ПІБ контактної особи"
          rules={[
            {
              required: true,
              message: "Поле 'ПІБ Контактної особи' обов`язкове",
            },
            {
              min: 2,
              max: 150,
              message:
                "Поле 'ПІБ Контактної особи' має містити від 2 до 150 символів",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={`phone${action}`}
          name={"phone"}
          label="Контактний номер телефону"
          rules={[
            {
              required: true,
              message: "Поле 'номеру телефону' обов`язкове",
            },
            {
              pattern: phonePattern,
              message:
                "Поле 'номеру телефону' не має містити спеціальні символи: дужки, пробіли, дефіси, тощо",
            },
            {
              len: 13,
              message:
                "Поле 'номеру телефону' має містити 13 символів, наприклад +380987654321",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
