import React from "react";
import { createRoot } from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./i18n";
import { Provider } from "react-redux";
import { store } from "store/store";
import { saveState } from "components/helper";
import { debounce } from "lodash"; 

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

store.subscribe(
  debounce(() => {
    saveState(store.getState().app, "app");
  }, 1000)
);
root.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      
      <Provider store={store}>
        <App />
      </Provider> 
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
