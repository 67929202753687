import { TOKEN_KEY } from "authProvider";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCartItems } from "store/slices/cartSlice";
import { ICartItem } from "types";
import { useGetCOParams } from "./useGetCOParams";
import { getCounterpartyId, getOutletId } from "store/slices/appSlice";

export const useFetchCart = () => {
  const counterpartyId = useSelector(getCounterpartyId);
  const outletId = useSelector(getOutletId);
  const token = localStorage.getItem(TOKEN_KEY);
  const dispatch = useDispatch();

  const fetchCart = async () => {
    if (!counterpartyId && !outletId && token !== null) return;
    try {
      const { data, status } = await axios.get<ICartItem[]>(
        `${process.env.REACT_APP_API_URL}/b2b/cart?c=${counterpartyId}&o=${outletId}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        dispatch(setCartItems(data));
      }
    } catch (e) {}
  };

  const [canFetch, setCanFetch] = useState<boolean>(false);
  useEffect(() => {
    setCanFetch(
      counterpartyId !== undefined && outletId !== undefined && token !== null
    );
  }, [counterpartyId, outletId, token]);

  return { fetchCart: fetchCart, canFetch: canFetch };
};
