import {
  Badge,
  Button,
  Card,
  notification,
  Row,
  Tooltip,
  Typography,
  Icons,
  Col,
} from "@pankod/refine-antd";
import { TOKEN_KEY } from "authProvider";
import axios, { AxiosError, isAxiosError } from "axios";
import { FC, MutableRefObject, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCounterpartyId, getOutletId } from "store/slices/appSlice";
import { IPartner, IResponseError } from "types";

const { Text, Title, Paragraph } = Typography;
const { InfoCircleOutlined } = Icons;

export interface IPersonalInfoProps {
  ref1?: MutableRefObject<any>;
  ref2?: MutableRefObject<any>;
}

export const PersonalInfo: FC<IPersonalInfoProps> = ({ ref1, ref2 }) => {
  const counterpartyId = useSelector(getCounterpartyId);
  const outletId = useSelector(getOutletId);
  const token = localStorage.getItem(TOKEN_KEY);
  const [partner, setPartner] = useState<IPartner>();

  const fetchPartner = async () => {
    if (!counterpartyId || !outletId || !token) return;
    try {
      const { data, status } = await axios.get<IPartner>(
        `${process.env.REACT_APP_API_URL}/partner?c=${counterpartyId}&o=${outletId}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        setPartner(data);
      }
    } catch (e) {}
  };

  const updatePartner = async () => {
    if (!counterpartyId || !outletId || !token) return;
    try {
      const { data, status } = await axios.post<IPartner>(
        `${process.env.REACT_APP_API_URL}/partner/change_info?c=${counterpartyId}&o=${outletId}`,
        {
          ...partner,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (status === 200) {
        setPartner(data);
        notification.success({
          message: "Інформацію оновлено",
          description: "",
        });
      }
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IResponseError>;
        notification.open({
          message: error.response?.data.message ?? "",
          type: "error",
          description: (
            <>
              {error.response?.data.errors.length !== 0
                ? error.response?.data.errors.map((e) => e.msg).join("\r\n")
                : "Помилка збереження"}
            </>
          ),
        });
      } else {
        notification.open({
          message: "Помилка збереження",
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (counterpartyId && outletId) {
      fetchPartner();
    }
  }, [counterpartyId, outletId]);
  return (
    <Col lg={12} sm={24} xl={8}>
      {partner && (
        <Card
          title="Інформація"
          ref={ref1}
          bodyStyle={{ height: "350px", overflowY: "auto" }}
          actions={[
            <Row
              justify={"end"}
              style={{
                margin: "0px 12px",
              }}
            >
              <Button ref={ref2} type="primary" onClick={updatePartner}>
                Зберегти
              </Button>
            </Row>,
          ]}
          extra={
            partner && partner.roles.map((e) => e.name).includes("buyer") ? (
              <Badge
                status="success"
                text={
                  <>
                    <Text
                      type="success"
                      style={{
                        marginRight: "6px",
                      }}
                    >
                      Повний доступ
                    </Text>
                    <Tooltip title="Вам доступні всі функції порталу">
                      <InfoCircleOutlined
                        style={{
                          color: "#1677ff",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </>
                }
              />
            ) : (
              <Badge
                status="warning"
                text={
                  <>
                    <Text
                      type="warning"
                      style={{
                        marginRight: "6px",
                      }}
                    >
                      Обмежений доступ
                    </Text>
                    <Tooltip title="Ваш обліковий запис на перевірці. Діють обмеження">
                      <InfoCircleOutlined
                        style={{
                          color: "#1677ff",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </>
                }
              />
            )
          }
        >
          <Title level={5}>Імʼя</Title>
          <Paragraph
            editable={{
              onChange: (value) => {
                if (value !== partner.name) {
                  setPartner({
                    ...partner,
                    name: value,
                  });
                }
              },
            }}
          >
            {partner?.name}
          </Paragraph>
          <Title level={5}>Прізвище</Title>
          <Paragraph
            editable={{
              onChange: (value) => {
                if (value !== partner.firstName) {
                  setPartner({
                    ...partner,
                    firstName: value,
                  });
                }
              },
            }}
          >
            {partner?.firstName}
          </Paragraph>
          <Title level={5}>Телефон</Title>
          <Paragraph
            editable={{
              onChange: (value) => {
                if (value !== partner.phone) {
                  setPartner({
                    ...partner,
                    phone: value,
                  });
                }
              },
            }}
          >
            {partner?.phone}
          </Paragraph>
          <Title level={5}>Email</Title>
          <Text>{partner.email}</Text>
        </Card>
      )}
    </Col>
  );
};
