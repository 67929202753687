import { useGetLocale, useSetLocale } from "@pankod/refine-core";
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  AntdLayout,
  Space,
  Menu,
  Button,
  Icons,
  Dropdown,
  Avatar,
  Typography,
  Popover,
  Col,
  Divider,
  Badge,
  Row,
} from "@pankod/refine-antd";
import { useTranslation } from "react-i18next";
import { useGetCounterparty, useWishlists, useTour } from "hooks";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  openCartDrawer,
  openCounterpartyModal,
} from "store/slices/modalsSlice";
import { getOutletId, setOutletId } from "store/slices/appSlice";
import { getCartItemsCount } from "store/slices/cartSlice";
import { FullSearch } from "components/other";

const { DownOutlined, ShoppingCartOutlined, HeartOutlined } = Icons;

const { Text } = Typography;

export const Header: React.FC = () => {
  const refLiveSearch = useRef(null);
  const refCounterparty = useRef(null);
  const refOutlet = useRef(null);
  const refCart = useRef(null);
  const { tourElement, setOpen } = useTour({
    steps: [
      {
        target: () => refLiveSearch.current!,
        placement: "bottom",
        title: <div>Швидкий пошук</div>,
        description: (
          <div>
            <p>
              Ви можете здійснювати пошук товару і категорій за назвою, кодом,
              або штрихкодом товару
            </p>
            <p>
              <ExclamationCircleOutlined style={{ color: "#006400" }} /> Для
              того, щоб завершити роботу помічника достроково натисніть клавішу
              ESC
            </p>
          </div>
        ),
      },
      {
        target: () => refCounterparty.current!,
        placement: "bottom",
        title: <div>Активний контрагент</div>,
        description: (
          <div>
            <p>
              Якщо у Вас декілька контрагентів, Ви можете швидко змінити його,
              натиснувши на цю кнопку
            </p>
            <p>
              <WarningOutlined style={{ color: "#8B0000" }} /> Всі поточні
              операції здійснюються в розрізі вибраного контрагенту
            </p>
            <p>
              <QuestionCircleOutlined style={{ color: "#08c" }} /> Контрагент -
              це фізична або юридична особа, на яку буде створено договір
              поставки товару, формуватись рахунки на оплату, акти звірок (за
              потребою), тощо.
            </p>
          </div>
        ),
      },
      {
        target: () => refOutlet.current!,
        placement: "bottomLeft",
        title: <div>Активна точка доставки</div>,
        description: (
          <div>
            <p>
              Якщо у Вас декілька точок доставки, Ви можете швидко змінити її,
              натиснувши на цю кнопку
            </p>
            <p>
              <WarningOutlined style={{ color: "#8B0000" }} /> Всі поточні
              операції здійснюються в розрізі вибраної точки доставки:
              формування замовлень, друк замовлень, тощо
            </p>
            <p>
              <QuestionCircleOutlined style={{ color: "#08c" }} /> Точка
              доставки - це фактична адреса, на яку буде формуватись складська
              накладна, здійснена доставка товару, здійснений контакт
              експедитором, тощо
            </p>
          </div>
        ),
      },
      {
        target: () => refCart.current!,
        placement: "bottomLeft",
        title: <div>Кошик</div>,
        description: (
          <div>
            <p>
              Ви можете мати декілька активних кошиків - окремий для кожної
              точки доставки
            </p>
            <p>
              <WarningOutlined style={{ color: "#8B0000" }} /> За замовчуванням
              в кошику відображається товар вибраної точки доставки (зліва)
            </p>
            <p>
              <ExclamationCircleOutlined style={{ color: "#006400" }} /> На
              головній сторінці B2B-порталу Ви можете продивитись кошики Ваших
              точок доставок без урахування будь-яких фільтрів
            </p>
          </div>
        ),
      },
    ],
    initOpen: false,
  });
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const dispatch = useDispatch();
  const { counterpartyData } = useGetCounterparty();
  const countCartItems = useSelector(getCartItemsCount);
  const outletId = useSelector(getOutletId);
  const currentLocale = locale();

  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      {[...(i18n.languages || [])].sort().map((lang: string) => (
        <Menu.Item
          key={lang}
          onClick={() => changeLanguage(lang)}
          icon={
            <span style={{ marginRight: 8 }}>
              <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
          }
        >
          "Українська"
        </Menu.Item>
      ))}
    </Menu>
  );

  const popoverContent = () => {
    return (
      <div>
        <Col>
          <Space>
            <Text>ІІН/ЄДРПОУ: </Text>
            <Text>{counterpartyData?.innEdrpou}</Text>
          </Space>
        </Col>
        <Col>
          <Space>
            <Text>Юр/Фіз особа: </Text>
            <Text>
              {counterpartyData?.isLegalEntity
                ? "Юридична особа"
                : "Фізична особа"}
            </Text>
          </Space>
        </Col>
        <Col>
          <Space>
            <Text>Кількість точок доставки: </Text>
            <Text>{counterpartyData?.outletsCount}</Text>
          </Space>
        </Col>
        <Divider />

        <Space>
          <Button onClick={() => dispatch(openCounterpartyModal())}>
            Змінити
          </Button>{" "}
        </Space>
      </div>
    );
  };

  const outletName = counterpartyData?.outlets.find(
    (e) => e.id === outletId
  )?.name;

  return (
    <>
      <AntdLayout.Header
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "0px 24px",
          height: "64px",
          backgroundColor: "white",
        }}
      >
        <QuestionCircleOutlined
          style={{ fontSize: "20px", color: "#08c" }}
          onClick={() => setOpen(true)}
          alt="Помічник"
          title="Запустити помічника на цій сторінці"
        />
        <Row
          style={{
            marginLeft: "12px",
            marginRight: "12px",
            flex: 1,
          }}
          ref={refLiveSearch}
        >
          <FullSearch />
        </Row>

        <Row style={{ marginLeft: "8px" }} ref={refCounterparty}>
          {counterpartyData && (
            <Popover content={popoverContent}>
              <Button type="primary" ghost>
                {counterpartyData?.name}
              </Button>
            </Popover>
          )}
        </Row>

        <Row
          style={{
            marginLeft: "8px",
          }}
          ref={refOutlet}
        >
          {counterpartyData &&
            counterpartyData.outlets.length > 0 &&
            outletName && (
              <Dropdown
                menu={{
                  items: counterpartyData.outlets.map((e) => {
                    return {
                      key: e.id,
                      label: (
                        <span onClick={() => dispatch(setOutletId(e.id))}>
                          {e.name}
                        </span>
                      ),
                    };
                  }),
                }}
              >
                <Button
                  type="link"
                  style={{
                    marginRight: "8px",
                  }}
                >
                  <Space>
                    {outletName}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            )}
        </Row>
        <Badge count={countCartItems} showZero>
          <Button
            icon={<ShoppingCartOutlined />}
            type="primary"
            ghost
            onClick={() => dispatch(openCartDrawer())}
            ref={refCart}
          >
            Кошик
          </Button>
        </Badge>

        {/* <Dropdown overlay={menu}>
        <Button type="link">
          <Space>
            <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
            {currentLocale === "ua" ? "Українська" : ""}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown> */}
      </AntdLayout.Header>
      {tourElement}
    </>
  );
};
