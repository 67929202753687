import {
  Card,
  Col,
  Typography,
  Row,
  Space,
  Spin,
  Button,
} from "@pankod/refine-antd";
import { useNavigation, useOne } from "@pankod/refine-core";
import { FC } from "react";
import { useSelector } from "react-redux";
import { getCounterpartyId, getOutletId } from "store/slices/appSlice";
import { IProduct, IProductFull } from "types";
import "./ProductExpandDetails.css";

export interface IProductExpandDetailsProps {
  record: IProduct;
}
const { Text, Title } = Typography;
export const ProductExpandDetails: FC<IProductExpandDetailsProps> = ({
  record,
}) => {
  const { show } = useNavigation();

  const counterpartyId = useSelector(getCounterpartyId);
  const outletId = useSelector(getOutletId);

  const { data, isLoading, isSuccess, isError } = useOne<IProductFull>({
    id: record.id,
    resource: "product",
    metaData: {
      query: [
        { field: "c", operator: "eq", value: counterpartyId },
        { field: "o", operator: "eq", value: outletId },
      ],
    },
  });

  if (isLoading) {
    return (
      <Row justify={"center"}>
        <Space>
          {" "}
          <Spin tip="Завантаження..." />
        </Space>
      </Row>
    );
  }
  const productData = data?.data as IProductFull;
  if (isError && !productData) {
    return <></>;
  }

  return (
    <div className="product">
      <Row>
        <Col sm={12} xs={24}>
          <div className="product-card">
            <span>Параметри</span>
            <div className="product-card__content">
              {productData.properties.map((e) => {
                return (
                  <div className="product-prop__item" key={e.id}>
                    <span>{e.productProperty.name}</span>
                    <span>{e.value}</span>
                  </div>
                );
              })}
              <div className="product-prop__item">
                <span>ПДВ</span>
                <span>{productData.vatRate.name}</span>
              </div>
              <div className="product-prop__item">
                <span>Одиниця виміру</span>
                <span>{productData.units.map((e) => e.name).join(", ")}</span>
              </div>
              <div className="product-prop__item">
                <span>Штрихкоди</span>
                <span>
                  {productData.barcodes.map((e) => e.barcode).join(", ")}
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} xs={24}>
          <div className="product-card">
            <span>Опис</span>
            <div
              className="product-card__content"
              dangerouslySetInnerHTML={{
                __html: productData.description,
              }}
            ></div>
          </div>
        </Col>
        <Col sm={24} xs={24}>
          <Row justify={"end"}>
            <Button
              onClick={() => {
                show("products", productData.id);
              }}
            >
              Детальніше
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
